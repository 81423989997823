import React from "react";

interface CardTitleProps {
  title: string;
}

export const CardTitle: React.FC<CardTitleProps> = ({ title }) => {
  return (
    <h1 className="text-3xl leading-[2.5rem] font-semibold text-black mb-5 mt-1">
      {title}
    </h1>
  );
};
