import { Button } from "./Button";
import { Modal } from "./Modal";
import { ReactComponent as SuccessCheck } from "../../assets/auth/success.svg";
import { SuccessModalProps } from "../parentalControl/types";

// When the set timer is successful
export const SuccessModal = ({
  handleModal,
  title,
  btnText,
  description
  }: SuccessModalProps) => {
  return (
      <Modal
          handleCloseModal={handleModal}
      >
          <div className="pt-6">
              <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg dark:bg-clrDarkBg">
                <SuccessCheck className="mb-6" />
                  <h2 className="mb-6 text-xl font-semibold text-black">{title}</h2>
                  <h3 className="mb-6 text-sm font-normal text-center text-clrGray dark:text-white w-[80%] mx-auto">
                      {description}
                  </h3>
              </div>

              <div className="w-4/5 mx-auto mt-8 [&>*]:tracking-wider">
                  <Button
                      type="button"
                      text={btnText}
                      handleClick={handleModal}
                      textSize="text-xs"
                      py="py-2.5"
                      fontWeight="font-semibold"
                      bgColor="bg-primary"
                  />
              </div>
          </div>
      </Modal>
  );
};