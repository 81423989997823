import { apiAdvertSlice } from './apiSlice';
import {
  AdShareSummaryResponse,
  AdSupportRequest,
  AllDevicesRequest,
  CaptionRequest,
  CaptionsResponse,
  DeviceUpdateResponse,
  GetAmountEarnedByHubseResponse,
  HotspotDataResponse,
} from './deviceTypes';

const advertSlice = apiAdvertSlice.injectEndpoints({
  endpoints: (build) => ({
    //Get Amount Earned By Hubs
    // getAmountEarnedByHubs: build.query<GetAmountEarnedByHubseResponse, void>({
    //   query: () => `/v1/networks/GetAmountEarnedByHubs`,
    // }),

    getAmountEarnedByHubs: build.query<GetAmountEarnedByHubseResponse, void>({
      query: () => `/v1/device-stats`,
    }),

    dashboardNetworks: build.query<AdShareSummaryResponse, void>({
      query: () => `/v1/networks/dashboard`,
    }),

    //Get Device Details
    getDevices: build.query<HotspotDataResponse, AllDevicesRequest>({
      query: () => `/v1/networks/GetHotspotHubs`,
      providesTags: ['Devices'],
    }),

    //Get Captions
    getCaptions: build.query<CaptionsResponse, void>({
      query: () => `/v1/captions`,
      providesTags: ['Adverts'],
    }),

    //Create Caption
    createCaption: build.mutation<
      CaptionsResponse,
      Partial<CaptionRequest>
    >({
      query: (payload) => ({
        url: `/v1/captions`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Adverts'],
    }),

    //Activate or Deactivate a caption
    activateCaption: build.mutation<
     CaptionsResponse,
     string
   >({
     query: (captionId: string) => ({
       url: `/v1/captions/activate/${captionId}`,
       method: 'PUT',
     }),
     invalidatesTags: ['Adverts'],
   }),


    //Toggle ad support
    toggleAdSupport: build.mutation<
      DeviceUpdateResponse,
      Partial<AdSupportRequest>
    >({
      query: (payload) => ({
        url: `/v1/devices/enable/bssid/${payload?.connectionId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Devices'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAmountEarnedByHubsQuery,
  useDashboardNetworksQuery,
  useToggleAdSupportMutation,
  useGetDevicesQuery,
  useGetCaptionsQuery,
  useCreateCaptionMutation,
  useActivateCaptionMutation
} = advertSlice;
