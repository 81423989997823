import { apiSlice } from "./apiSlice";
import { GenerateTokenRequest, TokenRequest, TokenResponse } from "./tokenSliceType";

interface SuccessResponse {
    success: boolean,
    data: [],
    errors: string[],
    message: string
}


const tokenSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get Tokens 
        getTokens: build.query<TokenResponse, TokenRequest>({ 
            query: ({voucherStatus, search, dataTokenUsageType, page, pageSize }) => `/v2/dataTokens/business?voucherStatus=${voucherStatus}&dataTokenUsageType=${dataTokenUsageType}&searchParam=${search}&api-version=${2}&page=${page}&pageSize=${pageSize}`,
            providesTags: ['Tokens']
        }),

         //Generate Tokens
         generateToken: build.mutation<SuccessResponse, Partial<GenerateTokenRequest>>({
            query: (payload) => ({
                url: `/v2/dataTokens`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["Tokens"]
      }),
    }),
    overrideExisting: true,
})

export const { useGetTokensQuery, useGenerateTokenMutation } = tokenSlice