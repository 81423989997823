import Transfer from './Transfer';
import TransactionOption from './TransactionOptions';
import { useAppSelector } from 'src/app/hooks';
import { EmptyCard } from 'src/components/reusables';
import { useState } from 'react';
import TransactionDetails from './details';

const TransactionHistory = () => {
  const { transactionTab } = useAppSelector((state) => state.wallet);
  const [showTransDetails, setShowTransDetails] = useState<any>();

  return (
    <div className="px-3 py-4 bg-white rounded-lg md:py-8 dark:bg-clrDarkBg">
      <div className=" md:pl-4">
        <h2 className="mb-5 text-xs font-semibold md:mb-6 md:text-sm text-clrTextGray dark:text-white">
          Transaction History
        </h2>
      </div>
      <section className="pb-6 mx-auto">
        <div className="bg-white rounded-lg md:px-4 dark:bg-clrDarkBg md:py-5">
          <TransactionOption />
          <Transfer
            showFilter={false}
            show={transactionTab === 1}
            setShowTransDetails={setShowTransDetails}
          />
          {(transactionTab === 2 ||
            transactionTab === 3 ||
            transactionTab === 4) && (
            <EmptyCard description="Make a transfer transaction to view your history" />
          )}{' '}
        </div>
      </section>
      {/* <div className="flex items-center justify-center mt-3">
        <Link
          to={'/home/wallet/transactionHistory'}
          className="text-xs font-normal text-clrYellow"
        >
          See All
        </Link>
      </div> */}

      {showTransDetails && (
        <TransactionDetails
          showTransDetails={showTransDetails}
          handleModal={() => setShowTransDetails(false)}
        />
      )}
    </div>
  );
};

export default TransactionHistory;
