interface Config {
  apiUrl: string;
  advertApiUrl: string;
  clusterUrl: string;
  appName: string;
  subject: string;
  baseUrl: string;
}

let localPlatforms = ['localhost', 'mtn.wicrypt.com'];

let platform = localPlatforms.includes(window.location.hostname)
  ? 'local'
  : 'production';

let config: Config; // Define the type of config

if (platform === 'local') {
  config = {
    apiUrl: process.env.REACT_APP_API_URL!,
    advertApiUrl: process.env.REACT_APP_API_URL_ADVERT!,
    clusterUrl: process.env.REACT_APP_CLUSTER_URL!,
    appName: 'Wicrypt',
    subject: 'Wicrypt',
    baseUrl: '',
  };
} else {
  config = {
    apiUrl: process.env.REACT_APP_API_URL!,
    advertApiUrl: process.env.REACT_APP_API_URL_ADVERT!,
    clusterUrl: process.env.REACT_APP_CLUSTER_URL!,
    appName: 'Wicrypt',
    subject: 'Wicrypt',
    baseUrl: '',
  };
}

export default config;
