import { useState } from "react";
import { ReactComponent as AddIcon } from "../../assets/icons/wallet/add.svg";
import VoucherHistory from "./VoucherHistory";
import { GenerateVoucher } from "./GenerateVoucher";

const Voucher = () => {
  const [generateVoucher, setGenerateVoucher] = useState<boolean>(false);

  return (
    <section className="w-[96%] mx-auto pb-6 mt-3 overflow-y-auto">
      <div className="grid justify-between grid-cols-1 px-3 py-4 my-4 bg-white rounded-lg dark:bg-clrDarkBg md:flex md:flex-row md:px-6 md:pt-6 md:pb-3">
        <div>
          <h3 className="text-base font-semibold text-[#29313C] dark:text-white mb-1">
            Generate Voucher
          </h3>
          <p className="mb-2 text-xs text-clrGray dark:text-clrPlaceholder">
            {" "}
            Generate a voucher code to share with users
          </p>
        </div>
        <div
          className="flex cursor-pointer justify-between items-center w-fit h-8 mt-3 px-4 gap-3 bg-clrDarkYellow rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#e3be27] hover:scale-[0.95]"
          onClick={() => setGenerateVoucher(true)}
        >
          <AddIcon />
          <p className="text-xs font-medium text-white">Generate Voucher</p>
        </div>
      </div>
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:py-8">
        <div className=" md:pl-4">
          <h2 className="mb-5 text-xs font-semibold md:mb-6 md:text-sm text-clrTextGray dark:text-white">
            Generated Vouchers
          </h2>
        </div>
        <VoucherHistory />
      </div>
      {generateVoucher && (
        <GenerateVoucher handleModal={() => setGenerateVoucher(false)} />
      )}
    </section>
  );
};

export default Voucher;
