import Add from "../../assets/icons/wallet/add.svg";
import { useState } from "react";
import TableMain from "../reusables/ReactDataTable";
import {
  ButtonOutline,
  ButtonYellow,
  EmptyCard,
  TableLoader,
} from "../reusables";
import { columnsAgentsHotspot } from "./data";
import { AssignDevice, DeleteModal } from "./AgentsModal";
import {
  useAssignHubsMutation,
  useGetAgentQuery,
  useRemoveAgentDeviceMutation,
  useRemoveAgentMutation,
} from "../../features/api/agentSlice";
import { useNavigate, useParams } from "react-router";
import { numberWithCommas } from "../../helper";
import { ScaleLoader } from "react-spinners";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { toggleAppModal } from "../../features/store/authReducerSlice";

const AgentsDetails = () => {
  const { businessUserId, businessId } = useParams();
  const [assignHub, setAssignHub] = useState<boolean>(false);
  const [errMsg, setErr] = useState<string>("");
  const [deleteStatus, toggleDelete] = useState<boolean>(false);
  const { appModal } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  //Get agent detail
  const {
    data,
    isLoading: gettingAgentDetail,
    isFetching,
  } = useGetAgentQuery(businessUserId || "");

  //Hubs mutation
  const [assign, { isLoading: assigningHub }] = useAssignHubsMutation();

  //Remove Agent
  const [removeAgent, { isLoading: removingAgent }] = useRemoveAgentMutation();

  //Remove Device from an agent
  const [deleteAgentDevice, { isLoading: deletingAgentDevice }] =
    useRemoveAgentDeviceMutation();

  const handleHubassignment = (devices: string[]) => {
    setErr("");
    let payload = {
      email: data?.data?.email as string,
      devices,
    };
    assign(payload)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          setAssignHub(false);
        } else {
          setErr(res?.message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.message);
      });
  };

  const handleRemoveAgent = () => {
    setErr("");
    let payload = {
      businessId,
      businessUserId,
    };
    removeAgent(payload)
      .unwrap()
      .then((res) => {
        setErr("");
        if (res?.success) {
          navigate("/home/agents");
        } else {
          setErr(res?.message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.message);
      });
  };

  const handleDeleteHub = () => {
    setErr("");
    let payload = {
      businessId,
      businessUserId,
      userDeviceId: appModal?.id,
    };
    deleteAgentDevice(payload)
      .unwrap()
      .then((res) => {
        setErr("");
        if (res?.success) {
          setAssignHub(false);
          dispatch(toggleAppModal({ id: "", detail: "" }));
        } else {
          setErr(res?.message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.message);
      });
  };

  return (
    <>
      {/* <div className="sticky top-[65px] md:top-[88px] z-[2]">
        <div className="relative flex justify-end p-3 bg-white border-[1px] border-solid  md:px-16 border-clrBorder2">
          <ButtonOutlineWallet
            text="Generate Report"
            type="button"
            showIcon={true}
            btnIcon={<Whitepaper className="w-3 h-3" />}
            handleClick={() => {}}
            textSize="text-x9"
            py="py-2"
            bgColor="dark:bg-[#fbf7e914]"
          />
        </div>
      </div> */}
      <section className="w-[96%] mx-auto pb-6 overflow-y-auto">
        <div className="px-3 py-4 my-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-6 md:pt-4 md:pb-3">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium md:font-semibold md:text-base text-clrTitle dark:font-medium dark:text-white">
              Agent Details
            </h3>
            <ButtonOutline
              text="REMOVE"
              type="button"
              handleClick={() => {
                toggleDelete(true);
              }}
              wBtn="w-24"
              marginX="mx-0"
              borderColor="border-primary dark:border-opacity-50"
              py="py-1"
              textSize="text-x9"
              borderWidth="border-[0.7px]"
            />
          </div>
          <div className="py-4 bg-white rounded-lg dark:bg-clrDarkBg md:pt-5 md:pb-4">
            <section className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4">
              <div>
                <h4 className="mb-2 font-medium md:font-semibold text-x10 md:text-xs text-clrGray dark:font-medium dark:text-white">
                  Name
                </h4>
                <p className="text-base md:text-lg font-semibold text-black dark:font-medium dark:text-white break-words">
                  {gettingAgentDetail ? (
                    <ScaleLoader
                      loading={gettingAgentDetail}
                      height={12}
                      width={2}
                      color={"#3d5170"}
                    />
                  ) : (
                    data?.data.name
                  )}
                </p>
              </div>
              <div>
                <h4 className="mb-2 font-semibold text-x10 md:text-xs text-clrGray dark:text-white">
                  Email
                </h4>
                <p className="text-base md:text-lg font-semibold text-black dark:font-medium dark:text-white break-words">
                  {gettingAgentDetail ? (
                    <ScaleLoader
                      loading={gettingAgentDetail}
                      height={12}
                      width={2}
                      color={"#3d5170"}
                    />
                  ) : (
                    data?.data.email
                  )}
                </p>
              </div>
            </section>
            <div className="border-solid border-b-[1px] border-[#E7E7E7] dark:border-opacity-30 my-6"></div>
            <section className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4">
              <div>
                <h4 className="mb-2 font-semibold text-x10 md:text-xs text-clrGray">
                  Total Data Resell Revenue
                </h4>
                <p className="text-base md:text-lg font-semibold text-black dark:text-clrYellow2">
                  NGN{" "}
                  {gettingAgentDetail ? (
                    <ScaleLoader
                      loading={gettingAgentDetail}
                      height={12}
                      width={2}
                      color={"#3d5170"}
                    />
                  ) : (
                    numberWithCommas(data?.data?.totalAmountGenerated || 0)
                  )}
                </p>
                {/* <p className="mt-1 font-normal text-clrGray text-x10">
                  ~12.5432WC
                </p> */}
              </div>
              {/* <div>
                <h4 className="mb-2 font-semibold text-x10 md:text-xs text-clrGray">
                  Total Voucher Generated
                </h4>
                <p className="text-2xl font-bold text-clrTextBlue dark:text-clrYellow2">
                  {gettingAgentDetail ? (
                    <ScaleLoader
                      loading={gettingAgentDetail}
                      height={12}
                      width={2}
                      color={"#3d5170"}
                    />
                  ) : (
                    data?.data?.totalVoucherGenerated
                  )}
                </p>
              </div> */}
            </section>
          </div>
        </div>
        <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-8 md:py-5">
          <div className="flex items-center justify-between gap-3">
            <div>
              <h4 className="mb-1 font-semibold text-xs md:text-sm text-[#242424] dark:text-white">
                Hotspot Hubs
              </h4>
              <p className="font-normal text-x9 text-clrGray dark:text-clrPlaceholder">
                Hubs managed by{" "}
                {gettingAgentDetail ? (
                  <ScaleLoader
                    loading={gettingAgentDetail}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : (
                  data?.data.name
                )}
              </p>
            </div>
            <button onClick={() => setAssignHub(true)}>
              <ButtonYellow
                text="Assign Hubs"
                btnIcon={Add}
                to={""}
                textSize="text-x10"
                imgHeight="15px"
                imgWidth="15px"
                btnHeight="h-7"
              />
            </button>
          </div>
          <div>
            {gettingAgentDetail || isFetching ? (
              <TableLoader />
            ) : (
              <TableMain
                defaultData={data?.data?.managedDeviceInformation || []}
                columns={columnsAgentsHotspot}
                totalPages={0}
                pageSize={data?.data?.managedDeviceInformation.length || 0}
                currentPage={0}
                setPage={() => {}}
              />
            )}
            {!gettingAgentDetail &&
              !isFetching &&
              data?.data?.managedDeviceInformation.length === 0 && (
                <EmptyCard description="No Hubs has been assigned to this agent!" />
              )}
          </div>
        </div>
        {assignHub && (
          <AssignDevice
            handleSubmit={handleHubassignment}
            isLoading={assigningHub}
            errMsg={errMsg}
            handleModal={() => {
              setAssignHub(false);
              setErr("");
            }}
          />
        )}

        {deleteStatus && (
          <DeleteModal
            title={"Remove Agent"}
            message={
              <>
                Are you sure you want to remove{" "}
                <span className="font-semibold text-black">{data?.data?.name}</span>?
              </>
            }
            handleModal={() => {
              toggleDelete(false);
              setErr("");
            }}
            handleSubmit={handleRemoveAgent}
            errMsg={errMsg}
            isLoading={removingAgent}
          />
        )}
        {appModal?.id && appModal?.id !== "routerCode" && (
          <DeleteModal
            title={"Remove Hub"}
            message={
              <>
                Are you sure you want to remove {" "}
                <span className="font-semibold text-black">{appModal?.detail}</span>?
              </>
            }
            handleModal={() => {
              dispatch(toggleAppModal({ id: "", detail: "" }));
              setErr("");
            }}
            handleSubmit={handleDeleteHub}
            errMsg={errMsg}
            isLoading={deletingAgentDevice}
          />
        )}
      </section>
    </>
  );
};

export default AgentsDetails;
