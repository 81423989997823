import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import CreateTracking from "../../assets/icons/hotspot-hubs/create-tracking.svg";
import { useRef, useState } from "react";
import {
  BusinessName,
  Duration,
  MobileFilterSearch,
  Trackdata,
} from "./OptionsStatus";
import useOutsideClick from "../../hooks/useOutsideClick";
import TableMain from "../reusables/ReactDataTable";
import { columnsTracker, defaultDataTracker } from "./ReactTableDataList";
import { ButtonYellow, EmptyCard } from "../reusables";

const DeviceTracker = () => {
  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const [isDurationOpen, setIsDurationOpen] = useState(false);
  const [isTrackDataOpen, setIsTrackDataOpen] = useState(false);

  const modalRefBusiness = useRef<HTMLElement>(null);
  const modalRefDuration = useRef<HTMLElement>(null);
  const modalRefTrackdata = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsBusinessOpen(false);
    setIsDurationOpen(false);
    setIsTrackDataOpen(false);
  };

  useOutsideClick(modalRefBusiness, handleCloseModal);
  useOutsideClick(modalRefDuration, handleCloseModal);
  useOutsideClick(modalRefTrackdata, handleCloseModal);

  return (
    <section className="relative w-[96%] mx-auto py-6 overflow-y-auto">
      <div className="py-4 px-3 bg-white rounded-lg md:py-8 md:px-3.5">
        <div className="md:px-4">
          <h3 className="text-sm font-semibold text-[#6b6b6b] mb-8">
            Track your hub's data status
          </h3>
          <div className="flex flex-wrap items-end gap-4 mb-8">
            <div className="flex w-full gap-3 md:w-auto">
              <MobileFilterSearch>
                <div className="relative mb-4">
                  <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                    Business
                  </h4>
                  <button
                    onClick={() => setIsBusinessOpen(!isBusinessOpen)}
                    className="flex items-center justify-between relative h-8 px-4 w-full md:w-40 border-[1px] border-solid border-clrBorder rounded-3xl"
                  >
                    <p className="text-[10px] font-normal text-clrPlaceholder">
                      All
                    </p>
                    <img
                      src={ArrowDown}
                      alt="more options"
                      className=""
                      width={20}
                      height={20}
                    />
                  </button>
                  {isBusinessOpen && (
                    <BusinessName modalRef={modalRefBusiness} />
                  )}
                </div>
                <div className="relative">
                  <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                    Duration
                  </h4>
                  <button
                    onClick={() => setIsDurationOpen(!isDurationOpen)}
                    className="flex items-center justify-between relative h-8 px-4 w-full md:w-40 border-[1px] border-solid border-clrBorder rounded-3xl"
                  >
                    <p className="text-[10px] font-normal text-clrPlaceholder">
                      All
                    </p>
                    <img
                      src={ArrowDown}
                      alt="more options"
                      className=""
                      width={20}
                      height={20}
                    />
                  </button>
                  {isDurationOpen && <Duration modalRef={modalRefDuration} />}
                </div>
              </MobileFilterSearch>
              <div className="flex-1 md:flex-auto ">
                <label
                  htmlFor=""
                  className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel"
                >
                  Search
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Hub Name"
                  className="w-full md:w-fit text-xs font-medium text-clrGray border-[1px] border-solid border-clrBorder rounded-3xl h-8 pb-1 px-4 placeholder:text-clrPlaceholder placeholder:text-[10px] bg-inherit"
                />
              </div>
            </div>

            <button
              onClick={() => {
                setIsTrackDataOpen(!isTrackDataOpen);
              }}
            >
              <ButtonYellow
                text="Create Tracker for Hub"
                btnIcon={CreateTracking}
                to={""}
              />
            </button>
          </div>
        </div>

        <div>
          <TableMain
            defaultData={defaultDataTracker}
            columns={columnsTracker}
            totalPages={0}
            pageSize={defaultDataTracker.length || 0}
            currentPage={0}
            setPage={() => {}}
          />
        </div>
        <EmptyCard description="No hub found" />
      </div>
      {isTrackDataOpen && (
        <Trackdata
          modalRef={modalRefTrackdata}
          handleTrackdata={() => {
            setIsTrackDataOpen(false);
          }}
        />
      )}
    </section>
  );
};

export default DeviceTracker;
