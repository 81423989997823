import ScaleLoader from 'react-spinners/ScaleLoader';
import CopyIcon from "../../assets/icons/copy.svg";

interface LinkCodeDisplayProps {
  linkCode: string | undefined;
  gettingLinkCode: boolean;
  copiedText: string;
  handleCopyClick: () => void;
  textSize?: string;
}

const LinkCodeDisplay = ({ linkCode, gettingLinkCode, copiedText, handleCopyClick, textSize = "text-lg md:text-xl" }: LinkCodeDisplayProps) => {
  return (
    <p className="relative flex justify-center gap-2">
      <span className={`${textSize} font-semibold text-black dark:text-white`}>
        {gettingLinkCode 
          ? <ScaleLoader loading={gettingLinkCode} height={12} width={2} color={'#1D1D1D'} />
          : linkCode}
      </span>
      <span className="relative self-center">
        <img
          src={CopyIcon}
          alt="Copy this Code"
          width={16}
          height={16}
          className="cursor-pointer"
          onClick={handleCopyClick}
        />
        {linkCode === copiedText && (
          <p className="absolute p-1 px-2 mt-4 text-white border-solid rounded -right-12 top-1 bg-primary text-x10">
            Copied
          </p>
        )}
      </span>
    </p>
  );
};

export default LinkCodeDisplay;
