import { useState } from "react";
import { useNavigate } from "react-router";
import { useDeleteAccountMutation } from "../../features/api/authSlice";
import { Button } from "../reusables/Button";
import { ErrorMsg } from "../reusables/ErrorMsg";
import { Modal } from "../reusables/Modal";

export const DeleteAccount = ({ handleModal }: { handleModal: () => void }) => {
    const navigate = useNavigate();
    const [deleteUserAccount, {isLoading}] = useDeleteAccountMutation();
    const [errMsg, setErr] = useState<string>("");

    const handleDelete = () =>{
        deleteUserAccount()
        .unwrap()
        .then((res) => {
          if (res?.success) {
            navigate("/")
          } else {
            setErr(res?.message);
          }
        })
        .catch((err) => {
          setErr(err?.data?.message);
        });
    }

  
    return (
      <Modal
        close={true}
        title={"Delete Account"}
        description={""}
        handleCloseModal={handleModal}
      >
        <div>
          <div className="px-4 py-8 bg-white rounded dark:bg-clrDarkBg">
            <h3 className="mb-8 text-xs font-semibold text-center text-clrTextBlue dark:font-medium dark:text-white w-[50%] mx-auto">
              Are you sure you want to delete your wicrypt account?
            </h3>
            
            <div className="px-3 py-2 bg-[#25346A1A] dark:bg-clrDarkBg2 rounded-lg">
              <p className="font-normal text-center text-x8 text-clrGray dark:text-clrPlaceholder">
                Note: If you decde to delete your account, this will be permanent. 
              </p>
            </div>
            <ErrorMsg errMsg={errMsg} />
          </div>
          <div className="md:w-[80%] mt-6 mx-auto ">
            <Button
              type="button"
              text={"Delete Account"}
              handleClick={handleDelete}
              loading={isLoading}
            />
          </div>
        </div>
      </Modal>
    );
  };