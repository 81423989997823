import { useMemo, useState } from "react";
import { ReactComponent as SampleImg } from "./sample.svg";
import { IHubFormQuestion } from "./FormContent";
import { renderResponse } from "./FormTypes";
import { processArray } from "src/helper";
import { FormImage } from "src/features/api/dynamicFormSliceTypes";

export const PreviewFormExample = ({ formQuestions, imageUrl, formBg, header, description }: { formQuestions: IHubFormQuestion[], imageUrl: string[] | FormImage[], formBg?: string, header?: string, description?: string }) => {

  let [formList, setFormList] = useState<number>(0);
  let [sliderIndex, setSliderIndex] = useState<number>(0);
  const formLists = useMemo(() => {
    return processArray(formQuestions)
  }, [formQuestions])

  return (
    <div className={`flex bg-white border border-solid border-[#B1B1B1] rounded-lg h-[269px] py-1`}>
      <div className="w-auto p-3 overflow-y-auto">
        {formLists.length > 1 &&
          <div className="grid place-content-end ml-7">
            <div className="flex items-center justify-center w-auto h-auto border-2 border-solid rounded-full border-clrYellow2 bg-clrYellow2 bg-opacity-10">
              <p className="text-sm font-normal text-primary" >{formList + 1}/{formLists.length}</p>
            </div>
          </div>}

        <div className="mt-4">
          <p className="text-xs font-bold text-black"> {header || "Welcome to the MTN Hub"}</p>
          <p className="text-x8"> {description || "Enter your account email fill in the form"}</p>
        </div>
        <div>
          {
            formLists[formList]?.map((question: IHubFormQuestion) => {
              return (
                renderResponse(
                  question?.type as string,
                  question?.options,
                  question?.href?.name,
                  question?.label,
                )
              )
            })
          }
        </div>
        <div>
          <div className="flex gap-2 justify-space-between">
            {formList !== 0 &&
              <button
                type="button"
                onClick={() => setFormList(formList - 1)}
                className="flex items-center justify-center w-full gap-2 px-10 py-2 mt-5 text-base text-white rounded-full cursor-pointer btn bg-primary">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M10.5317 7.70646L12.9978 7.48836C13.5513 7.48836 14 7.94145 14 8.50029C14 9.05913 13.5513 9.51222 12.9978 9.51222L10.5317 9.29412C10.0975 9.29412 9.74551 8.9387 9.74551 8.50029C9.74551 8.06115 10.0975 7.70646 10.5317 7.70646Z" fill="white" />
                  <path d="M2.25015 7.74656C2.28869 7.70764 2.43269 7.54314 2.56796 7.40655C3.35704 6.55104 5.41738 5.1521 6.49518 4.72398C6.65882 4.65568 7.07263 4.51028 7.29445 4.5C7.50608 4.5 7.70826 4.5492 7.90099 4.64614C8.14171 4.78199 8.33371 4.99642 8.43989 5.24904C8.50752 5.42381 8.6137 5.94887 8.6137 5.95842C8.71916 6.53194 8.77661 7.46457 8.77661 8.49559C8.77661 9.47668 8.71916 10.3711 8.63261 10.9542C8.62316 10.9645 8.51698 11.6158 8.40134 11.8391C8.18971 12.2474 7.7759 12.5 7.33299 12.5H7.29445C7.00572 12.4905 6.39919 12.2371 6.39919 12.2283C5.37883 11.8002 3.36722 10.4688 2.55851 9.5839C2.55851 9.5839 2.33014 9.35625 2.23124 9.21452C2.07706 9.01037 1.99997 8.75776 1.99997 8.50514C1.99997 8.22315 2.08651 7.96099 2.25015 7.74656Z" fill="white" />
                </svg>

                Previous
              </button>}
            {formLists.length > (formList + 1) &&
              <button
                type="button"
                onClick={() => setFormList(formList + 1)}
                className="flex items-center justify-center w-full gap-2 px-10 py-2 mt-5 text-base text-white rounded-full cursor-pointer btn bg-primary" >
                Next
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M5.46832 9.29354L3.00217 9.51164C2.44872 9.51164 2 9.05855 2 8.49971C2 7.94087 2.44872 7.48778 3.00217 7.48778L5.46832 7.70588C5.9025 7.70588 6.25449 8.0613 6.25449 8.49971C6.25449 8.93885 5.9025 9.29354 5.46832 9.29354Z" fill="white" />
                  <path d="M13.7503 9.25344C13.7118 9.29236 13.5678 9.45686 13.4325 9.59345C12.6434 10.449 10.5831 11.8479 9.5053 12.276C9.34167 12.3443 8.92786 12.4897 8.70604 12.5C8.49441 12.5 8.29223 12.4508 8.0995 12.3539C7.85878 12.218 7.66678 12.0036 7.5606 11.751C7.49296 11.5762 7.38678 11.0511 7.38678 11.0416C7.28133 10.4681 7.22388 9.53543 7.22388 8.50441C7.22388 7.52332 7.28133 6.62888 7.36787 6.0458C7.37733 6.03552 7.48351 5.38416 7.59915 5.16091C7.81078 4.75262 8.22459 4.5 8.6675 4.5H8.70604C8.99476 4.50955 9.6013 4.7629 9.6013 4.77171C10.6217 5.19983 12.6333 6.53121 13.442 7.4161C13.442 7.4161 13.6703 7.64375 13.7693 7.78548C13.9234 7.98963 14.0005 8.24224 14.0005 8.49486C14.0005 8.77685 13.914 9.03901 13.7503 9.25344Z" fill="white" />
                </svg>

              </button>
            }
            {formLists.length === (formList + 1) &&
              <button
                type="button"
                disabled
                className="flex items-center justify-center w-full gap-2 px-10 py-2 mt-5 text-base text-white rounded-full cursor-pointer btn bg-primary">
                Submit
              </button>
            }
          </div>
        </div>
      </div>
      <div
        className="p-4 flex flex-col items-center justify-center md:w-96"
        style={{
          backgroundColor: formBg ? formBg : "#E5B909"
        }}
      >

        {imageUrl.length > 0 ?
          <img src={(imageUrl[sliderIndex] as FormImage)?.url as string || imageUrl[sliderIndex] as string || ""} alt="sample" className="max-w-full max-h-full" />
          :
          <SampleImg className="max-w-full h-fit" />
        }
        <div className="flex items-center gap-1 mt-4">
          {imageUrl.map((each, index) => {
            return (<button key={each?.toString()} onClick={() => setSliderIndex(index)} type="button" className={`h-2 w-2 rounded-full ${sliderIndex === index ? "bg-[#FFFFFF]" : "bg-[#C4C4C4]"}`}></button>)
          })}

        </div>
      </div>

    </div>
  )
}