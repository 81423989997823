import token from "../../assets/auth/token.svg";
import hotspothubs from "../../assets/auth/hotspot-hub.svg";
import networkfeedbackform from "../../assets/auth/network-feedback-form.svg";
import analytics from "../../assets/auth/analytics.svg"
import parentalcontrol from "../../assets/auth/parental-control.svg"

export type FrameData = {
  title: string,
  details: string,
  image: string,
}

export const AUTH_FRAME_DATA: FrameData[] = [
  {
    title: "Effortlessly Explore Hub Insights",
    details:
      "Simplifying Complex Data for Instant Insights",
    image: analytics
  },
  {
    title: "Parental Control",
    details:
      "Restrict access to the Internet and harmful sites at a go",
    image: parentalcontrol
  },
  {
    title: "Generate Tokens for Data Usage",
    details:
      "Empowering Seamless Access and Connectivity",
    image: token,
  },
  {
    title: "Hotspot Hub Performance",
    details:
      "Get quick insights into active connections, uptime, and device at a glance.",
    image: hotspothubs
  },
  {
    title: "Customize Dynamic Forms",
    details:
      "Easily collect valuable user data with customizable forms from your hub.",
    image: networkfeedbackform 
  },


  
];
