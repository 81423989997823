import { useState } from "react";
import { ReactComponent as CheckIcon } from "../../assets/subscription/check.svg";
import { ReactComponent as InfoIcon } from "../../assets/subscription/info-check.svg";

interface IFeature {
  feature: string;
  description: string;
  more?: string[];
}
const Features = ({feature, description, more }: IFeature) => {
  // State to track tooltip visibility
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  // Function to handle the tooltip visibility
  const handleTooltipToggle = () => {
    setTooltipVisible((prevState) => !prevState);
  };
  return (
    <div className="flex gap-2">
      <CheckIcon />
      <div className="flex flex-wrap items-center gap-2">
        <p className="text-xs font-medium text-clrGray">{feature}</p>
        <div className="flex items-center gap-2 flex-grow flex-wrap">
          {Array.isArray(more) && more.some(item => item !== '') && more.map((each, index) => (
            <span key={index} className="text-[0.57rem] font-medium text-clrGreen2 bg-clrGreen2 bg-opacity-20 py-[2.5px] px-[6px] rounded-lg">
              {each}
            </span>
          ))}

          {/* <InfoIcon /> */}
          <div
              className="relative cursor-pointer"
              onMouseEnter={handleTooltipToggle} // Show tooltip on mouse enter
              onMouseLeave={handleTooltipToggle} // Hide tooltip on mouse leave
            >
              <InfoIcon
                width={"15px"}
                height={"15px"}
                className="w-[13px] h-[13px] md:w-[15px] md:h-[15px]"
              />
              <div
                className={`tooltip relative ${
                  isTooltipVisible ? "show" : "hidden"
                }`}
              >
                <div className="absolute top-1 left-0 w-0 h-0 border-l-transparent border-solid border-r-transparent border-b-black border-l-[6px] border-r-[6px] border-b-[6px]"></div>{" "}
                {/* Add pointer */}
                <div className="font-normal text-x8 absolute bg-black text-white p-1 rounded z-[1] top-2 -left-1 w-32">
                  {/* Add tooltip content */}
                  {description}
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Features
