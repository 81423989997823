import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { useRef, useState } from "react";
import { DeviceList } from "src/features/api/clusterSliceTypes";
import useOutsideClick from "src/hooks/useOutsideClick";
import { Button } from "../reusables";


// My Clusters Column
const clusterColumn = createColumnHelper<DeviceList>();



export const myCluster = [
  clusterColumn.accessor("createdAtTimeStamp", {
    header: "Created At",
    cell: (val) => {
      const time = val.getValue();
      return <p> {moment(time).format('Do MMM YYYY | h:mm a')}</p>;
    },
  }),
  clusterColumn.accessor("listName", {
    header: "Cluster Name",
  }),
  clusterColumn.accessor("deviceCount", {
    header: "Hubs",
  }),
  // clusterColumn.accessor("deviceListId", {
  //   header: "Data Used",
  // }),
  // clusterColumn.accessor("deviceListId", {
  //   header: "",
  //   cell: (val) => {
  //     const id = val.getValue();
  //     return (
  //       <EditCluster id={id as string}  />
  //     );
  //   },
  // }),
  // clusterColumn.accessor("deviceListId", {
  //   header: "",
  // }),
];

export const EditCluster = ({ id }: { id: string }) => {
  console.log(id)
  const [isEditDetails, setIsEditDetails] = useState(false);

  const modalRefMoreResponse = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsEditDetails(false);
  };

  useOutsideClick(modalRefMoreResponse, handleCloseModal);

  return (
    <>
      <Button
        text={"Edit Details"}
        type={"button"}
        handleClick={() => setIsEditDetails(!isEditDetails)}
        textSize={"text-[0.5rem]"}
        lineHeight={"leading-[0]"}
      />
      {/* {isEditDetails && (
        <div>{id}</div>
      )} */}
    </>
  );
}