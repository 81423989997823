import { useAppSelector } from '../../../app/hooks';
import Transfer from './Transfer';
import TransactionOption from './TransactionOptions';
import { EmptyCard } from 'src/components/reusables';

const TransactionHistory = () => {
  const { transactionTab } = useAppSelector((state) => state.wallet);

  return (
    <section className="w-[96%] mx-auto pb-6 mt-3 overflow-y-auto">
      <div className="p-3 bg-white rounded-lg md:px-4 dark:bg-clrDarkBg md:py-8">
        <TransactionOption />
        <Transfer showFilter={false} show={transactionTab === 1} />
        {(transactionTab === 2 ||
          transactionTab === 3 ||
          transactionTab === 4) && (
          <EmptyCard description="Make a transfer transaction to view your history" />
        )}
      </div>
    </section>
  );
};

export default TransactionHistory;
