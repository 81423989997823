import { useState } from 'react';
// import { useAppSelector, useAppDispatch } from "../../app/hooks";
import ArrowDown from '../../assets/icons/arrow-down-Yellow.svg';
import { Button, ErrorMsg } from '../reusables';
import AddIcon from '../../assets/icons/wallet/add.svg';
import { BankAccount } from './P2PInfo';
import {
  useGetConversionRateQuery,
  // useGetSupportedP2PCurrenciesQuery,
  useGetMinimumAmountQuery,
} from '../../features/api/walletSlice';
// import { numberWithCommas } from "../../helper";
import { Currency } from '../../features/api/walletSliceTypes';
import { Bank } from './types';

interface P2PAmountProps {
  handleAmountConfirm: () => void;
  setOutBoundValue: (value: string) => void;
  outBoundValue: number;
  inBoundValue: number;
  setInboundValue: (value: string) => void;
  setSelectedBank: (bank: Bank) => void;
  selectedBank: Bank | null;
  setPhoneNumber: (value: string) => void;
  phoneNumber: string;
  type: 'WITHDRAWAL' | 'FUND';
  inboundCurrency: Currency;
  outboundCurrency: Currency;
  paymentMethod?: any;
}

const P2PAmount = ({
  handleAmountConfirm,
  setOutBoundValue,
  setInboundValue,
  setSelectedBank,
  selectedBank,
  setPhoneNumber,
  phoneNumber,
  outBoundValue,
  inboundCurrency,
  outboundCurrency,
  type,
  paymentMethod,
}: P2PAmountProps) => {
  // const dispatch = useAppDispatch();
  const [errorMsg, setError] = useState<string>('');
  // const { selectedCurrency: inboundCurrency, outboundCurrency } =
  //   useAppSelector((state) => state.wallet);

  const [isAddBank, setIsAddBank] = useState<boolean>(false);
  //const [isp2pCurrencies, setShowCurrencies] = useState<boolean>(false);

  // const { data: p2pcurrencies, isLoading: loadingCurrency } =
  //   useGetSupportedP2PCurrenciesQuery(type === "FUND" ? "Buy" : "Sell");
  const { data: minimumAmount } = useGetMinimumAmountQuery();

  const { data: rate } = useGetConversionRateQuery(
    {
      inboundCurrencyId: inboundCurrency.id,
      outBoundCurrencyId: outboundCurrency.id,
    },
    {
      skip: !inboundCurrency.id || !outboundCurrency.id,
    }
  );

  const handleBankSelection = (bank: Bank) => {
    setSelectedBank(bank);
  };

  //Function to validate p2p
  const handleNext = () => {
    let minimum = minimumAmount?.data.minimumAmount || 0;
    //TODO: Validate that phone number does not contain text
    // if (!inboundCurrency.name) {
    //   setError(`Please select currency`);
    // } else if (!outboundCurrency.name) {
    //   setError(
    //     `Please select a ${type === 'FUND' ? 'funding' : 'withdrawal'} currency`
    //   );
    // } else
    if (paymentMethod !== 'USSD' && !phoneNumber) {
      setError('Please enter a valid phone number');
    } else if (outBoundValue < minimum) {
      setError(
        `Minimum ${type === 'FUND' ? 'fund' : 'withdrawal'} amount is ${
          minimum + ' ' + outboundCurrency?.code
        }`
      );
    }
    // else if (!selectedBank?.bankName) {
    //   setError('Please select a bank for the transaction');
    // }
    else {
      setError('');
      if (type === 'FUND') {
        setInboundValue((outBoundValue / (rate?.data?.rate || 0)).toString());
      } else {
        setInboundValue((outBoundValue * (rate?.data?.rate || 0)).toString());
      }
      handleAmountConfirm();
    }
  };

  return (
    <>
      <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-8 md:py-5">
        <h2 className="text-sm md:text-base font-semibold text-[#212121] dark:text-white mb-1.5 md:mb-3">
          Amount
        </h2>
        <p className="font-normal text-x10 text-clrGray dark:text-clrPlaceholder">
          Insert the {type === 'FUND' ? 'funding' : 'withdrawal'} amount
        </p>
        <div className="flex flex-col gap-4 my-5 ">
          <p className="text-xs font-semibold text-clrGray opacity-60 dark:text-clrPlaceholder dark:font-medium">
            Enter amount to {type === 'FUND' ? 'fund' : 'withdraw'}
          </p>
          <span className="flex items-center gap-3">
            <input
              value={Number(outBoundValue || 0)}
              className="text-3xl font-bold text-center text-black bg-[#F5F5F5] outline-none  md:text-5xl dark:text-white dark:placeholder:text-white py-1 rounded-xl dark:bg-clrDarkBg"
              style={{
                maxWidth: `${outBoundValue.toString().length * 35}px`,
                minWidth: `200px`,
              }}
              type="text"
              placeholder="0"
              onChange={(e) => setOutBoundValue(e.target.value)}
            />
            <span className="  font-normal text-xl   text-primary md:pb-0">
              {outboundCurrency.code || 'NGN'}
            </span>
          </span>
        </div>

        {paymentMethod !== 'MOMO' && paymentMethod !== 'USSD' && (
          <button
            onClick={() => setIsAddBank(true)}
            className="flex gap-3 w-3/4 border-[#C4C4C4] dark:border-clrGray border-solid border-[1px] p-3.5 items-center rounded"
          >
            <img
              src={selectedBank ? selectedBank.iconUrlSvg : AddIcon}
              alt="Bank Logo"
              width={20}
              height={20}
            />
            <p
              className={`text-[10px]  mr-auto  ${
                selectedBank
                  ? 'font-semibold text-black dark:text-white'
                  : 'font-normal text-clrGray dark:text-clrPlaceholder'
              }`}
            >
              {selectedBank
                ? `${selectedBank.name} (${selectedBank.accountNumber})`
                : 'Select a bank account'}
            </p>
            <img src={ArrowDown} alt="more options" width={20} height={20} />
          </button>
        )}

        {paymentMethod !== 'USSD' && (
          <div className="mt-4 md:px-3 w-3/4">
            <label
              htmlFor=""
              className="block font-semibold dark:font-medium md:mb-2 text-x10 text-clrGray43 dark:text-clrPlaceholder"
            >
              Enter Phone Number{' '}
            </label>
            <input
              type="tel"
              name="phone"
              id="phone"
              value={phoneNumber}
              placeholder="08000000000"
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="text-x10 font-normal text-clrText   dark:!text-clrPlaceholder placeholder:!text-[#98989866] dark:placeholder:!text-clrPlaceholder dark:focus:!bg-clrDarkBg dark:!bg-clrDarkBg pb-1 w-full border-b-[0.7px] border-solid border-[#25346a99] dark:!border-clrGray focus:outline-none"
            />
          </div>
        )}
        <ErrorMsg errMsg={errorMsg} />

        <div className="mt-8 w-1/2">
          <Button text="Continue" type="button" handleClick={handleNext} />
        </div>
      </div>
      {isAddBank && (
        <BankAccount
          handleModal={() => setIsAddBank(false)}
          handleBankSelection={handleBankSelection}
        />
      )}
    </>
  );
};

export default P2PAmount;
