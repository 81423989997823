import { useEffect, useRef, useState } from 'react';
import { ReactComponent as MoreIcon } from '../../assets/hub/more.svg';
import { ReactComponent as ArrowRight } from '../../assets/hub/arrow-right.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useParams } from 'react-router-dom';
import { useGetFormDetailQuery } from 'src/features/api/dynamicFormSlice';
import { IHubFormQuestion } from './FormContent';
import { ContentOptions } from './data';
import { contentType, renderResponse } from './FormTypes';
import { ScaleLoader } from 'react-spinners';
import { ReactComponent as PreviewIcon } from '../../assets/hub/preview.svg';
import { PreviewForm } from './PreviewForm';

const Content = () => {
  const [moreOptions, setMoreOptions] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const modalRefMoreOptions = useRef<HTMLElement>(null);
  const [isPreviewForm, setIsPreviewForm] = useState(false);

  const { formId } = useParams();
  const [formContent, setFormContent] = useState<IHubFormQuestion[]>([]);

  const { data: formDetail, isLoading: gettingFormDetail } =
    useGetFormDetailQuery(formId as string);

  useEffect(() => {
    if (formDetail) {
      let form = JSON.parse(formDetail?.data?.jsonRepresentation);
      let allFormItems = [];
      for (let i = 0; i < form?.form?.fields.length; i++) {
        for (let j = 0; j < (form?.form?.fields[i]?.length || 0); j++) {
          allFormItems.push(form?.form?.fields[i][j]);
        }
      }
      setFormContent(allFormItems);
    }
  }, [formDetail]);

  const handleMoreOptions = () => {
    setMoreOptions(!moreOptions);
  };
  useOutsideClick(modalRefMoreOptions, handleMoreOptions);

  return (
    <section className="py-3 bg-clrBg w-[96%] mx-auto pb-6 mt-3 dark:bg-clrDarkBg2 overflow-y-auto">
      <div className="flex flex-col md:grid grid-cols-[max-content,1fr,max-content] md:px-6 bg-white rounded-lg">
        <div className="flex flex-col items-center justify-center py-4 md:py-0 md:mr-7">
          <h2 className="mb-1 text-sm font-semibold text-black">
            Form Content
          </h2>
        </div>
        <div className="bg-primary bg-opacity-[2%] border-x border-solid border-primary/5 px-5 py-6 flex flex-wrap gap-5">
          {gettingFormDetail ? (
            <ScaleLoader />
          ) : (
            formContent.map((content, index) => {
              return (
                <div
                  onClick={() => setCurrentQuestion(index)}
                  key={index}
                  className={`flex items-center gap-2 px-3 py-2 border border-solid rounded-lg bg-clrBg2 w-fit 
                  ${
                    index === currentQuestion
                      ? 'border-clrDarkYellow/40'
                      : 'border-transparent cursor-pointer'
                  }`}
                >
                  <p className="text-xs font-normal text-black">{index + 1}</p>
                  <div className="flex items-center justify-center w-5 h-5 rounded-full bg-primary bg-opacity-10">
                    <img
                      alt={content.label}
                      src={ContentOptions[contentType(content?.type)]?.image}
                    />
                  </div>
                  <p className="text-xs font-normal text-black">
                    {content?.label}
                  </p>
                  <button>
                    <MoreIcon />
                  </button>
                </div>
              );
            })
          )}
        </div>
        <div className="grid place-content-center md:ml-7 py-4 md:py-0">
          <div className="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 border-2 border-solid rounded-full border-primary bg-primary bg-opacity-10">
            <p className="text-xs md:text-sm font-normal text-primary">
              {currentQuestion + 1}/{formContent.length}
            </p>
          </div>
        </div>
      </div>
      <div className="relative bg-white rounded-lg px-4 py-[2.125rem] md:pl-11 md:pr-5 mt-4 min-h-[calc(100vh-345px)] after:content[' '] after:absolute after:w-1/2 after:h-[0.4375rem] after:bg-primary after:rounded-ss-lg after:top-0 after:left-0">
        <div className="flex justify-between">
          <p className="mb-8 md:mb-12 text-sm md:text-base font-semibold text-clrGray">
            Content body
          </p>
          <button
            className="flex gap-2"
            onClick={() => setIsPreviewForm(!isPreviewForm)}
          >
            <p className="mb-8 md:mb-12 text-sm md:text-base font-semibold text-primary">
              Preview
            </p>
            <PreviewIcon />
          </button>
        </div>

        <div className="flex gap-6 md:gap-12">
          <div className="flex items-center gap-1 h-fit">
            <p className="text-base md:text-lg font-semibold text-black">
              {currentQuestion + 1}
            </p>
            <ArrowRight className="w-4 h-4" />
          </div>
          <div className="grid gap-6">
            <div>
              <div>
                <p className="mb-3 text-sm md:text-base font-normal text-black">
                  {formContent[currentQuestion]?.label}
                </p>
              </div>
              <div className="">
                {formContent[currentQuestion] &&
                  renderResponse(
                    formContent[currentQuestion]?.type as string,
                    formContent[currentQuestion]?.options,
                    formContent[currentQuestion]?.href
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPreviewForm && (
        <PreviewForm
          formQuestions={formContent}
          handleCloseModal={() => setIsPreviewForm(false)}
          imageUrl={(formDetail?.data?.formImages as any) || []}
        />
      )}
    </section>
  );
};

export default Content;
