import { useParams } from "react-router-dom";
import SecondaryNav from "../reusables/SecondaryNav";

const NetworkFeedbackForm = () => {
  const { formId } = useParams();
  return (
      <SecondaryNav
        paths={[
          { path: `/home/hub-form/detail/${formId}`, title: "Form Content" },
          { path: `/home/hub-form/detail/responses/${formId}`, title: "Responses" },
        ]}
      />
  );
};

export default NetworkFeedbackForm;