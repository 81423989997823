import SecondaryNav from "../reusables/SecondaryNav";

const AdvertControl = () => {
  return (
    <SecondaryNav
      paths={[
        { path: "/home/advert-control/overview", title: "Overview" },
        { path: "/home/advert-control/share", title: "Ads Share" },
        { path: "/home/advert-control/caption", title: "Ads Caption" },
      ]}
    />
  );
};

export default AdvertControl;
