import { useMemo, useState } from "react";
import { ReactComponent as Logo2 } from "../../assets/auth/logo.svg";
import { ReactComponent as CloseIcon2 } from "../../assets/icons/hotspot-hubs/close.svg";
import { NavBarData } from "./NavBarData";
import { NavLink, useLocation } from "react-router-dom";
import RouterLogin from "../../assets/icons/router-login.svg";
import { ReactComponent as ViewAll } from "../../assets/icons/view-all.svg";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { useAppDispatch } from "../../app/hooks";
import { toggleAppModal } from "../../features/store/authReducerSlice";
import NotifyMobile from "../partials/NotifyMobile";


interface NavbarProps {
  isNavbarOpen: boolean;
  handleCloseNav: () => void;
  navRef: any
}


const NavBar = ({
  isNavbarOpen,
  handleCloseNav,
  navRef,
}: NavbarProps) => {
  const dispatch = useAppDispatch();
  const [showNavbar, setShowNavbar] = useState<number>(0);

  const location = useLocation();

  const renderNavBar = (navItem: any) => {
    return (
      <NavLink
        className={`nav-group flex items-start gap-3.5 px-2 py-3 active:bg-primary/10 dark:hover:bg-clrDarkBg2 
      hover:rounded-xx hover:bg-primary/10 dark:active:bg-clrDarkBg2 rounded-xx ${location.pathname.split("/")[2] === navItem.path
            ? " bg-primary/10 dark:bg-clrDarkBg2"
            : ""
          }`}
        key={navItem.id}
        to={navItem.to}
      >
        <div
          className={`icon-nav 
          ${location.pathname.split("/")[2] === navItem.path
              ? "icon-active "
              : ""}`
          }
        >
          {navItem.icon}
        </div>

        <div>
          <p
            className={`nav-title text-xs pb-1 font-medium  dark:text-clrPlaceholder self-center ${location.pathname.split("/")[2] === navItem.path
              ? "font-semibold text-clrDarkYellow dark:text-white"
              : "text-clrGray"
              }`}
          >
            {navItem.title}
          </p>
          {location.pathname.split("/")[2] === navItem.path ? (
            <p className="nav-desc text-[0.5rem] font-normal text-clrGray tracking-[0.4px]">
              {navItem.desc}
            </p>
          ) : (
            ""
          )}
        </div>
      </NavLink>
    )
  }

  const navBarList = useMemo(
    () =>
      showNavbar === 0
        ? NavBarData.slice(0, 8)
        : NavBarData.slice(8),
    [showNavbar, NavBarData] 
  );
  
  return (
    <section
      className={`fixed inset-0 z-[19] lg:z-auto h-screen overflow-hidden bg-[#00000033] dark:md:bg-[#00000080] lg:relative lg:block lg:w-[16.5rem] ${isNavbarOpen ? "block" : "hidden"
        }`}
      ref={navRef}
    >
      <div
        className={`grid grid-rows-[88px_1fr] lg:flex flex-col overflow-y-auto h-screen pb-8 px-6 w-[16.5rem] bg-white dark:bg-clrDarkBg z-40 inset-y-0 left-0`}
      >
        <div className="sticky top-0 z-10 flex items-center justify-between bg-white dark:bg-clrDarkBg lg:justify-center">
          <Logo2 className="w-[106.3px] h-[90px] dark-logo" />
          <button onClick={handleCloseNav} className="pt-3 lg:hidden">
            <CloseIcon2 className="w-[13px] h-[13px] dark-close" />
          </button>
        </div>
        <section className="overflow-y-auto">
          <div className="flex flex-col gap-2 pt-2">
            {navBarList.map((item) => {
              return (
                renderNavBar(item)
              );
            })}
            {showNavbar === 0 ?
              <button
                type="button"
                className="nav-view-more flex mb-3 gap-3.5 px-2 py-3 text-clrGray dark:text-clrPlaceholder 
                dark:hover:text-clrPlaceholder hover:text-clrDarkYellow hover:font-semibold hover:rounded-xx hover:bg-clrBtnNav dark:hover:bg-clrDarkBg2"
                onClick={() => setShowNavbar(1)}
              >
                <div className="icon-nav">
                  <ViewAll />
                </div>
                <p className="text-xs nav-title ">View All</p>
              </button> :
              <button
                type="button"
                className="nav-view-more flex items-center mb-3 gap-3.5 px-2 py-3 text-clrGray dark:text-clrPlaceholder 
                dark:hover:text-clrPlaceholder hover:text-clrDarkYellow hover:font-semibold hover:rounded-xx hover:bg-clrBtnNav dark:hover:bg-clrDarkBg2"
                onClick={() => setShowNavbar(0)}
              >
                <div className="icon-nav">
                  <ArrowBack />
                </div>
                <p className="text-xs nav-title ">Back to Main</p>
              </button>}
          
          </div>
          <NotifyMobile />
        </section>

        <button
          onClick={() =>
            dispatch(toggleAppModal({ id: "routerCode", detail: "routerCode" }))
          }
          id="step-11"
          className="mt-6 relative z-10 flex items-center justify-center gap-2 w-full p-3 rounded-xii bg-[#1d1d1de5] dark:bg-clrYellow2 overflow-hidden mb-3"
        >
          <img src={RouterLogin} alt="Login" width={30} height={30} />
          <p className="text-sm font-medium text-white">My Custom Code</p>
          <div className="absolute inset-0 z-[-1] w-full h-full bg-black dark:bg-clrDarkYellow rounded-lg before transform -translate-x-1/2 -rotate-12 "></div>
        </button>

        <div className="grid place-items-center border border-[#FFCB0533] border-solid bg-primary bg-opacity-10 rounded-3xl h-10 w-[90%] mx-auto mt-auto">
          <p className="font-normal text-x11 text-clrGray">Powered by Wicrypt</p>
        </div>
      </div>
    </section>
  );
};

export default NavBar;
