import { apiSlice } from "./apiSlice";
import { AgentDeviceRequest, AllAgentsRequest, AllAgentsResponse, AssignHubRequest, CreateAgentRequest, CreateAgentResponse, OneAgentResponse, RemoveAgentRequest } from "./agentSliceTypes";
import { SuccessResponse } from "./deviceTypes";


const agentSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get Agents
        getAgents: build.query<AllAgentsResponse, AllAgentsRequest>({ 
            query: ({page, pageSize, agentName}) => `/v2/businesses/users?page=${page}&pageSize=${pageSize}&agentName=${agentName}`,
            providesTags: ['Agents']
        }),

        //Get One Agents
        getAgent: build.query<OneAgentResponse, string>({ 
            query: (businessUserIduserId) => `/v2/businesses/AgentInformation?businessUserId=${businessUserIduserId}`,
            providesTags: ['Agents']
        }),

        //Create Agent
        createAgent: build.mutation<CreateAgentResponse, CreateAgentRequest>({
            query: (payload) => ({
                url: `/v2/businesses/users`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["Agents"]
        }),

        //Assign hub to an agent
        assignHubs: build.mutation<CreateAgentResponse, AssignHubRequest>({
            query: (payload) => ({
                url: `/v2/businesses/devices`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["Agents"]
        }),

        //Remove device from an agent
        removeAgentDevice: build.mutation<SuccessResponse, Partial<AgentDeviceRequest>>({
            query: (payload) => ({
                url: `/v2/businesses/devices`,
                method: 'DELETE',
                body: payload
            }),
            invalidatesTags: ["Agents"]
        }),

        //Remove agent from a business
        removeAgent: build.mutation<SuccessResponse, Partial<RemoveAgentRequest>>({
            query: (payload) => ({
                url: `/v2/businesses/users`,
                method: 'DELETE',
                body: payload
            }),
            invalidatesTags: ["Agents"]
        }),
        
    }),
    overrideExisting: true,
})

export const { useGetAgentsQuery, useGetAgentQuery, useCreateAgentMutation, useAssignHubsMutation, useRemoveAgentDeviceMutation, useRemoveAgentMutation } = agentSlice