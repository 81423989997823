
import { ReactNode} from "react";
import { sliceText } from "src/helper";
import { ReactComponent as DownloadIcon } from "../../assets/hub/download.svg";
import { ReactComponent as StarIcon } from "../../assets/hub/star.svg";
import { CheckOptions } from "./types";
import { ReactComponent as Delete } from "../../assets/delete.svg";

const renderNormalText = (label?: string) => {
    return (
        <div className="mt-6">
            {label && <label className="text-xs mb-2 text-[#6b6b6b] leading-6"> {label}</label>}
            {label ?

                <input
                    type="text"
                    disabled
                    className="block w-full px-6 py-2 text-base text-gray-800 font-normal leading-22 bg-clip-padding-box border bg-[#f5f5f5] border-[#f5f5f5] rounded-full transition duration-150 ease-in-out"
                /> :
                <input
                    type="text"
                    name="userResponse"
                    placeholder="User enters in response here"
                    disabled
                    className="relative flex items-center justify-between w-full pb-2 text-xs border-b border-solid placeholder:!text-clrGray/30 placeholder:text-xs border-[#E5B91033] focus:outline-none focus:border-primary"
                />
            }

        </div>
    )
}

const renderTextArea = (label?: string) => {
    return (
        <div className="mt-6">
            {label && <label className="text-xs mb-2 text-[#6b6b6b] leading-6"> {label}</label>}
            {label ?

                <textarea
                    rows={4}
                    cols={50}
                    name="textarea"
                    disabled
                    className="block w-full resize-none px-6 py-2 text-base text-gray-800 font-normal leading-22 bg-clip-padding-box border bg-[#f5f5f5] border-[#f5f5f5] rounded-lg transition duration-150 ease-in-out"
                /> :
                <textarea
                    name="userResponse"
                    rows={5}
                    cols={15}
                    placeholder="User enters in response here"
                    disabled
                    className="relative flex items-center justify-between w-full pb-2 text-xs border-b border-solid placeholder:!text-clrGray/30 placeholder:text-xs border-[#E5B91033] focus:outline-none focus:border-primary"
                />
            }

        </div>
    )
}

const renderRating = (label?: string) => {
    return (
        <div className="mt-6">
            {label && <label className="text-xs mb-2 text-[#6b6b6b] leading-6"> {label}</label>}
            <div className="flex gap-2 my-3">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
            </div>
        </div>

    )
}

const renderCheckboxes = (options?: CheckOptions[], label?: string) => {
    return (
        <div className="mt-6">
            {label && <label className="text-xs mb-2 text-[#6b6b6b] leading-6"> {label}</label>}
            <div className="flex flex-col gap-5">
                <div className={`grid grid-cols-1 gap-5 w-fit ${!label && "md:grid-cols-2"}`}>
                    {options?.map((option: CheckOptions, index: number) => (
                        <div key={index} className="flex gap-5">
                            <div className="flex items-center p-2 rounded-lg bg-clrBg2">
                                <div className="flex items-center justify-center w-5 h-5 rounded-full bg-primary bg-opacity-10">
                                    <p className="text-xs font-normal text-black">{String.fromCharCode(65 + index)}</p>
                                </div>
                                <input
                                    type="text"
                                    className="ml-2 text-xs font-normal text-black min-w-[7rem] bg-transparent focus:outline-none"
                                    value={option.label}
                                    disabled
                                />
                                <Delete />
                                <button className="w-4 h-4 md:ml-8 border border-clrGray"></button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const renderDropdown = (options?: CheckOptions[], label?: string, handleRemove?: (val: string) => void, width?: string) => {
    return (
        <div className="mt-6">
            {label && <label className="text-xs mb-2 text-[#6b6b6b] leading-6"> {label}</label>}
            {label ?
                <div>
                    <select
                        style={{width: width || "15rem"}}
                        className={`h-10 border-b border-solid border-clrYellow text-base outline-none bg-transparent bg-no-repeat bg-right-center pr-8`}
                        id={label} name="options">
                        {options?.map((option) => {
                            return (
                                <option value={option.text}>{option.text}</option>
                            )
                        })}
                    </select>
                </div> :
                options?.map((option) => {
                    return (
                        <div className="relative option-delete mt-3">
                            <input
                                type="text"
                                placeholder={option.text}
                                value={option.text}
                                className="relative flex items-center justify-between w-full pb-2 text-xs border-b border-solid placeholder:!text-clrGray/30 placeholder:text-xs border-[#E5B91033] focus:outline-none focus:border-primary"
                            />
                             {handleRemove && <Delete onClick={() => handleRemove && handleRemove(option.id || "")} className="h-3 absolute right-1 top-2 delete-check cursor-pointer"/>}
                        </div>
                    )
                })}
        </div>
    )
}

const renderRadio = (options?: CheckOptions[], label?: string, handleRemove?: (val: string) => void) => {

    return (
        <div className="mt-6">
            {label && <label className="text-xs mb-2 text-[#6b6b6b] leading-6"> {label}</label>}
            <div className="flex flex-col gap-5">
                <div className={`grid grid-cols-1 gap-5 w-fit ${!label && "md:grid-cols-2"}`}>
                    {options?.map((option: CheckOptions, index: number) => (
                        <div key={index} className="flex gap-5">
                            <div className="flex items-center p-2 rounded-lg bg-clrBg2 relative option-delete">
                                <div className="flex items-center justify-center w-5 h-5 rounded-full bg-primary bg-opacity-10">
                                    <p className="text-xs font-normal text-black">{String.fromCharCode(65 + index)}</p>
                                </div>
                                <input
                                    type="text"
                                    className="ml-2 text-xs font-normal text-black min-w-[7rem] bg-transparent focus:outline-none"
                                    value={option.label}
                                    disabled
                                />
                               {handleRemove && <Delete onClick={() => handleRemove && handleRemove(option.id || "")} className="h-3 absolute right-1 delete-check cursor-pointer"/>}
                                <button className="w-4 h-4 md:ml-8 border rounded-full border-clrGray"></button>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}

  

const renderFile = (fileName?: string, label?: string) => {
    return (
        <div className="mt-6">
            {label && <label className="text-xs mb-2 text-[#6b6b6b] leading-6"> {label}</label>}
            {label ?
                <div className="flex flex-col w-4/5 gap-4 ">
                    <div className="flex gap-1 px-5 py-3 bg-black rounded-full bg-opacity-20 w-fit">
                        <DownloadIcon className="w-4 h-4" />
                        <p className="text-xs font-semibold text-black">View file</p>
                    </div>
                </div> :

                <div
                    className="bg-[#C4C4C457] border border-solid w-96 h-36 border-[#C4C4C4] flex flex-col items-center justify-center rounded-lg mt-2"
                >
                    {fileName &&
                        <div className="flex flex-col w-4/5 gap-4 ">
                            <div className="flex items-center self-center gap-1 px-5 py-3 bg-black rounded-full bg-opacity-20 w-fit">
                                <DownloadIcon className="w-4 h-4" />
                                <p className="text-sm font-semibold text-black">{sliceText(fileName, 20)}</p>
                            </div>
                        </div>
                    }
                </div>}
        </div>
    )
}

// const renderPrizedItems = () =>{
//     return(
//         <div className="grid grid-cols-1 gap-5">
//         {formQuestions[selectedQuestionIndex]?.options.map((option: string | PrizedItem, index: number) => (
//           <div className="flex gap-5" key={index}>
//             <input 
//               type="text" 
//               placeholder="Name"
//               value={String(option)}
//               onChange={(e) => {
//                 setFormQuestions((prevState) => {
//                   const newState = [...prevState];
//                   newState[selectedQuestionIndex]!.options[index] = e.target.value;
//                   return newState;
//                 })
//               }}
//               className="placeholder:!text-clrGray/30 text-black text-sm border-b border-solid border-primary/20 focus:border-primary focus:outline-none pb-3 w-[200px]" 
//               />

//             <input 
//               type="text" 
//               placeholder="Price"
//               value={String(option)}
//               onChange={(e) => {
//                 setFormQuestions((prevState) => {
//                   const newState = [...prevState];
//                   newState[selectedQuestionIndex]!.options[index] = e.target.value;
//                   return newState;
//                 })
//               }} 
//               className="placeholder:!text-clrGray/30 text-black text-sm border-b border-solid border-primary/20 focus:border-primary focus:outline-none pb-3 w-[130px]" 
//               />

//             <input 
//               type="text" 
//               placeholder="Currency"
//               value={String(option)}
//               onChange={(e) => {
//                 setFormQuestions((prevState) => {
//                   const newState = [...prevState];
//                   newState[selectedQuestionIndex]!.options[index] = e.target.value;
//                   return newState;
//                 })
//               }}
//               className="placeholder:!text-clrGray/30 text-black text-sm border-b border-solid border-primary/20 focus:border-primary focus:outline-none pb-3 w-[130px]" 
//               />
//           </div>
//         ))}
//         </div>
//     )
// }

export const renderResponse = (type: string, options?: CheckOptions[], fileName?: string, label?: string, handleRemove?: (val: string) => void, width?: string): ReactNode => {
    switch (type) {
        case "text":
        case "email":
            return renderNormalText(label);
        case "textarea":
            return renderTextArea(label);
        case "rating":
            return renderRating(label);
        case "checkbox":
            return renderCheckboxes(options, label);
        case "radio":
            return renderRadio(options, label, handleRemove);
        case "dropdown":
            return renderDropdown(options, label, handleRemove, width);
        case "file":
            return renderFile(fileName, label)
        default:
            return null;
    }
}

export const contentType = (type: string) => {
    let CType: string;
    switch (type) {
        case "email":
            CType = "text";
            break;
        case "imageslider":
            CType = "file";
            break;
        default:
            CType = type;
            break;
    }
    return CType;
};
