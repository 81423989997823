import { useState } from 'react';
import ArrowDown from '../../assets/icons/arrow-down-Yellow.svg';
import { useAppSelector } from '../../app/hooks';
import AddIcon from '../../assets/icons/wallet/add.svg';
import TickSquare from '../../assets/icons/wallet/tick-square.svg';
import { useGetUsersBankAccountsQuery } from '../../features/api/walletSlice';
import { sliceText } from '../../helper';

import { Button, ErrorMsg, TableLoader } from '../reusables';
import { Link } from 'react-router-dom';
import { Modal } from '../reusables/Modal';
import { Bank, BankAccountProps, ConfirmModalProps } from './types';

//Component to list the user's bank accounts
export const BankAccount = ({
  handleModal,
  handleBankSelection,
}: BankAccountProps) => {
  const { id: userId } = useAppSelector((state) => state.auth);
  const { data: bankAccounts, isLoading: loadingAccounts } =
    useGetUsersBankAccountsQuery(userId);
  const handleSelectBank = (bank: Bank) => {
    handleBankSelection(bank);
    handleModal();
  };

  return (
    <Modal
      close={true}
      title={'Select Bank Account'}
      description={'Select preferred account for funding'}
      handleCloseModal={handleModal}
      goBack={true}
      handleGoBack={handleModal}
    >
      <>
        <Link
          to={'/home/wallet/add-bank-details'}
          className="flex items-center gap-2 mt-1"
        >
          <img
            src={AddIcon}
            alt=""
            width={28}
            height={28}
            className="w-5 h-5 md:w-7 md:h-7"
          />
          <p className="text-xs font-bold dark:text-white">Add Bank</p>
        </Link>
        <div className="py-5 space-y-2 ">
          {loadingAccounts ? (
            <TableLoader />
          ) : (
            bankAccounts?.data.banks.map((bank) => (
              <button
                className="flex items-center w-full gap-4 p-3 mb-2 bg-white rounded-lg dark:bg-clrDarkBg"
                key={bank.id}
                onClick={() => handleSelectBank(bank)}
              >
                <div className="w-[12%]">
                  <img
                    src={bank.iconUrlSvg}
                    alt="Bank Logo"
                    width={40}
                    height={40}
                  />
                </div>
                <p className="mr-auto font-semibold text-black dark:text-white text-x10">
                  {bank.name} ({bank && sliceText(bank.accountNumber, 7)})
                </p>
                <img
                  src={ArrowDown}
                  alt="more options"
                  width={20}
                  height={20}
                  className="-rotate-90"
                />
              </button>
            ))
          )}
        </div>
      </>
    </Modal>
  );
};

interface AddBankAccountProps {
  handleCloseModal: () => void;
  handleSave: () => void;
  loading: boolean;
  accountName: string;
  accountNumber: string;
  bankName: string;
  errMsg: string;
}
//Component to add a new bank account to a user
export const AddBankAccount: React.FC<AddBankAccountProps> = ({
  handleCloseModal,
  loading,
  accountName,
  accountNumber,
  bankName,
  handleSave,
  errMsg,
}) => {
  return (
    <Modal
      close={true}
      title={'Add Bank Account'}
      description={'Add the following bank account'}
      handleCloseModal={handleCloseModal}
      goBack={false}
      handleGoBack={() => {}}
    >
      <>
        <div className="flex flex-col gap-6 px-2 bg-white rounded dark:bg-clrDarkBg dark:border-clrGray py-7 md:px-5 md:py-12">
          <div>
            <label
              htmlFor=""
              className="block mb-2 text-[10px] font-medium text-clrGray dark:text-clrPlaceholder"
            >
              Account Name
            </label>
            <p className="text-clrTextBlue dark:text-white placeholder:text-lg text-lg font-semibold dark:font-medium border-b-[1px] border-solid border-clrBorder pb-2 w-full focus:outline-none">
              {accountName}
            </p>
          </div>
          <div className="flex flex-wrap items-center justify-between md:flex-nowrap gap-y-6">
            <div className="mr-auto">
              <label
                htmlFor=""
                className="block mb-2 text-[10px] font-medium text-clrGray dark:text-clrPlaceholder"
              >
                Account Number
              </label>
              <p className="w-full text-lg font-semibold dark:font-medium text-clrTextBlue dark:text-white placeholder:text-lg focus:outline-none">
                {accountNumber}
              </p>
            </div>
            <div>
              <label
                htmlFor=""
                className="block mb-2 text-[10px] font-medium text-clrGray dark:text-clrPlaceholder"
              >
                Bank Name
              </label>
              <p className="w-full text-lg font-semibold dark:font-medium text-clrTextBlue dark:text-white placeholder:text-lg focus:outline-none">
                {bankName}
              </p>
            </div>
          </div>
        </div>
        <ErrorMsg errMsg={errMsg} />
        <div className="mx-auto mt-12 md:w-4/5">
          <Button
            text="Add Account"
            loading={loading}
            type="button"
            handleClick={handleSave}
          />
        </div>
      </>
    </Modal>
  );
};

export const ConfirmModal = ({
  handleModal,
  handleContinue,
}: // orderId,
ConfirmModalProps) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [errorMsg, setError] = useState<string>('');
  // const { id: userId } = useAppSelector((state) => state.auth);

  const handleCheckboxClick = () => {
    setIsCheckboxChecked(true);
  };

  const handleConfirmClick = () => {
    handleContinue();
    // let data = { orderId: orderId as string, userId };
    setError('');
  };

  return (
    <Modal
      close={true}
      title={''}
      description={''}
      handleCloseModal={handleModal}
      goBack={false}
      handleGoBack={() => {}}
    >
      <>
        <div className="flex flex-col items-center justify-center p-8 mt-4 bg-white dark:bg-clrDarkBg">
          <div className="flex items-center justify-center bg-[#1A1A1A33] w-12 h-12 rounded-[50%]">
            <img src={TickSquare} alt="" width={30} height={30} />
          </div>
          <h1 className="my-6 text-base font-semibold text-[#1D1D1D] dark:text-white dark:font-medium">
            Confirm Transfer
          </h1>
          <p className="mb-5 font-normal text-center text-x10 text-clrGray dark:text-clrPlaceholder">
            Please, make sure you have completed the transfer to the specified
            account before you confirm transaction.
          </p>

          <label className="material-checkbox text-[10px] font-normal text-clrGray dark:text-clrPlaceholder">
            <input
              type="checkbox"
              checked={isCheckboxChecked}
              onChange={handleCheckboxClick}
            />
            <span className="checkmark"></span>I have made the Transfer
          </label>
        </div>
        <ErrorMsg errMsg={errorMsg} />
        <div className="md:w-[80%] mt-8 mx-auto ">
          <Button
            text="Confirm"
            type="button"
            //loading={isLoading}
            handleClick={handleConfirmClick}
            disabled={!isCheckboxChecked}
          />
        </div>
        <p className="text-[8px] font-normal text-clrGray text-center opacity-40 mt-4">
          Powered by MoMo
        </p>
      </>
    </Modal>
  );
};
