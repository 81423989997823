import { Modal } from "../reusables/Modal";
import AuthCode from "react-auth-code-input";
import { Link } from "react-router-dom";
import { Button } from "../reusables";

//Get User Pin here
const EnterPin = ({
    handleModal,
    handleContinue,
    errMsg,
    handleChange,
}: ConfirmModalProps) => {
    return (
        <Modal
            close={true}
            title={"Enter Pin"}
            description={"Enter your pin to proceed"}
            handleCloseModal={handleModal}
            goBack={false}
        >
            <>
                <div className="flex flex-col items-center justify-center w-full p-4 bg-white rounded dark:bg-clrDarkBg">
                    <div className="mb-4 text-center">
                        <label
                            htmlFor="pin"
                            className="block mb-4 font-bold leading-3 text-x10 text-clrTextBlue dark:text-white md:text-sm"
                        >
                            Enter Pin
                        </label>
                        <div className="mb-4">
                            <AuthCode
                                inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary border-transparent border-solid border rounded-md bg-primary/10"
                                containerClassName="flex justify-center gap-3"
                                ariaLabel="verify-otp"
                                isPassword={true}
                                autoFocus={true}
                                length={4}
                                allowedCharacters="numeric"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <Link
                        to={"/reset"}
                        className="mt-5 font-normal underline text-x10 text-clrTextBlue dark:text-clrPlaceholder"
                    >
                        Forgot Pin?
                    </Link>
                </div>
                <div className="md:w-[80%] mt-8 mx-auto ">
                    <p className="mb-2 font-medium text-center text-x10 text-clrRed">
                        {errMsg}
                    </p>
                    <Button text="Submit" type="button" handleClick={handleContinue} />
                </div>
            </>
        </Modal>
    );
};

export default EnterPin