import DeviceList from "./DeviceList";

const Subscription = () => {
    return (
        <section className="bg-clrBg w-[96%] mx-auto mt-3 mb-6 dark:bg-clrDarkBg2 overflow-y-auto">
            <div className=" bg-white rounded-lg py-[2.125rem] px-5 md:pl-11 md:pr-5">
                <DeviceList />
            </div>
        </section>
    )
}

export default Subscription