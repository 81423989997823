import React, { useEffect, useState } from "react";
import { AUTH_FRAME_DATA, FrameData } from "./AuthFrameData";

import {ReactComponent as Arrow }from "../../assets/icons/arrow-right-yellow.svg"

const AuthFrame: React.FC = () => {
  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  const handleClick = (direction: 'prev' | 'next') => {
    setCarouselIndex((prevIndex) => {
      let newIndex = direction === 'prev' ? prevIndex - 1 : prevIndex + 1;
      if (newIndex < 0) {
        newIndex = AUTH_FRAME_DATA.length - 1;
      }
      if (newIndex >= AUTH_FRAME_DATA.length) {
        newIndex = 0;
      }
      return newIndex;
    });
  };

  useEffect(() => {
    
    const interval = setInterval(() => {
      handleClick('next');
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="hidden relative lg:flex justify-center items-center overflow-hidden basis-[55.7%] ">
      <div className="flex md:flex-col lg:flex-col justify-center items-center gap-8 relative z-10">
        <div className="flex justify-between items-center gap-4 next-btn">
          <Arrow className={`rotate-180 ${carouselIndex !== 0 ? 'yellow' : 'grey'}`} onClick={() => handleClick('prev')} />
          
          <div className="flex justify-center items-center w-[500px] h-[500px] border border-solid border-primary/10 bg-primary/[.02] rounded-full">
            <img
              src={(AUTH_FRAME_DATA[carouselIndex] as FrameData).image}
              alt="Frame"
              width={507}
              height={308}
            />
          </div>
          
          <Arrow className={`${carouselIndex !== AUTH_FRAME_DATA.length - 1 ? 'yellow' : 'grey'}`} onClick={() => handleClick('next')} /> 
        </div>
      
        <div>
          <div className="text-center mb-4" >
            <h2 className="text-2xl font-semibold text-black my-2">
              {(AUTH_FRAME_DATA[carouselIndex] as FrameData).title}
            </h2>
            <p className="text-sm font-normal text-black w-4/5 mx-auto min-h-11">
              {(AUTH_FRAME_DATA[carouselIndex] as FrameData).details}
            </p>
          </div>
          {/* <div className="flex justify-center items-center gap-2 mb-4">
            <div
              className={`${
                carouselIndex === 0 ? "bg-[#E5B910]" : ""
              } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4]`}
            ></div>
            <div
              className={`${
                carouselIndex === 1 ? "bg-[#E5B910]" : ""
              } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4]`}
            ></div>
            <div
              className={`${
                carouselIndex === 2 ? "bg-[#E5B910]" : ""
              } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4]`}
            ></div>
            <div
              className={`${
                carouselIndex === 3 ? "bg-[#E5B910]" : ""
              } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4]`}
            ></div>
            <div
              className={`${
                carouselIndex === 4 ? "bg-[#E5B910]" : ""
              } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4]`}
            ></div>
          </div> */}
          <div className="flex justify-center items-center gap-2 mb-4">
            {AUTH_FRAME_DATA.map((_, index) => (
              <div
                key={index}
                className={`${
                  carouselIndex === index ? "bg-primary" : ""
                } w-[20px] h-[6px] rounded-lg bg-[#C4C4C4] cursor-pointer`}
                onClick={() => setCarouselIndex(index)}
              ></div>
            ))}
          </div>
          
        </div>
        
      </div>
      <div className="absolute bottom-2 right-8 text-[#C8D5FF] text-xs">
        <ul className="flex md:justify-between gap-x-3 flex-wrap">
          <li>
            <a href="https://wicrypt.com/help/"> Help Center</a>
          </li>
          <li>
            <a href="https://wicrypt.com/terms-condition.html">
              Terms & Conditions
            </a>
          </li>
          <li>
            <a href="https://wicrypt.com/privacy-policy.html">Privacy Policy</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AuthFrame;
