import { createColumnHelper } from "@tanstack/react-table";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-yellow.svg";
import { numberWithCommas } from "../../helper";
import { toggleAppModal } from "../../features/store/authReducerSlice";
import { useAppDispatch } from "../../app/hooks";
import { filesize } from "filesize";


type UserAgent = {
  email: string,
  id: string,
  name: string,
  phoneNumber: null,
  rate: number,
  userName: string,
  verificationStatus: string
}

type BusinessAgent = {
  apiKey: string;
  bonusVoucherDataAmountInMB: null;
  bonusVoucherValidityInHours: null;
  canAgentGenerateVoucher: boolean;
  canCreateSubsidiary: boolean;
  defaultRemittanceAllowance: number;
  id: string;
  logoUrl: string;
  name: string;
  officeAddress: string;
  userId: string;
  voucherGenerationTransactionFee: number;
  voucherValidityInHours: null;
}
//Agents
type Agents = {
  businessUserId: string;
  user: UserAgent;
  totalDevicesManaged: number;
  totalVoucherGenerated: number;
  totalAmountGenerated: number;
  business: BusinessAgent;
};

const columnHelper = createColumnHelper<Agents>();

export const columnsAgents = [
  columnHelper.accessor("businessUserId", {
    header: "#",
    cell: (row) => {
      return <p>{Number(row?.row.id) + 1}</p>;
    },
  }),
  columnHelper.accessor("user", {
    header: "Name",
    cell: (val) => {
      const user = val.getValue();
      return <p>{user?.name}</p>;
    }
  }),
  columnHelper.accessor("user", {
    header: "Email",
    cell: (val) => {
      const user = val.getValue();
      return <p>{user?.email}</p>;
    }
  }),

  columnHelper.accessor("totalDevicesManaged", {
    header: "Hub Assigned",
  }),
  // columnHelper.accessor("totalVoucherGenerated", {
  //   header: "Total Voucher Generated",
  // }),
  columnHelper.accessor("totalAmountGenerated", {
    header: "Total Resell Revenue",
    cell: (val) => {
      const totalAmount = val.getValue();
      return <p> NGN {numberWithCommas(totalAmount)}</p>;
    }
  }),

  columnHelper.accessor("businessUserId", {
    header: "",
    cell: (val) => {
      const businessUserId = val.getValue();
      return (
        <Link to={`/home/agents/${businessUserId}/${val?.row?.original?.user?.name}/${val?.row?.original?.business?.id}`}>
          <button className="flex gap-2 items-center px-2 py-1 hover:bg-[#e0dcdc] dark:hover:bg-clrDarkBg2 hover:rounded transition duration-500 ease-in-out">
            View Details
            <ArrowRight className="w-4 h-4" />
          </button>
        </Link>
      )
    }
  }),
];



//Agents details: Hotspot Hubs
type AgentsHotspot = {
  deviceName: string;
  dataSoldInBytes: number;
  rate: number;
  amountGenerated: number;
  id: string;
};

const columnAgentsHotspot = createColumnHelper<AgentsHotspot>();

export const CellRenderer = ({ id, name }: { id: string, name: string }) => {
  const dispatch = useAppDispatch();

  return (
    <button onClick={() => dispatch(toggleAppModal({ id: id as string, detail: name as string }))}
      className=" hover:bg-[#e0dcdc] hover:rounded transition duration-500 ease-in-out">
      <Delete className="w-4 h-4" />
    </button>
  );
};


export const columnsAgentsHotspot = [

  columnAgentsHotspot.accessor("deviceName", {
    header: "Name",
  }),
  columnAgentsHotspot.accessor("dataSoldInBytes", {
    header: "Data Sold",
    cell: (val) => {
      const dataSold = val.getValue();
      return filesize(dataSold);
    },
  }),

  columnAgentsHotspot.accessor("rate", {
    header: "Billing Rate",
    cell: (val) => {
      const billingRate = val.getValue();
      return numberWithCommas(billingRate);
    },
  }),
  columnAgentsHotspot.accessor("amountGenerated", {
    header: "Data Resell Revenue",
    cell: (val) => {
      const amtGenerated = val.getValue();
      return numberWithCommas(amtGenerated);
    },
  }),

  columnAgentsHotspot.accessor("deviceName", {
    header: "",
    cell: (info) => {
      return (<CellRenderer id={info?.row?.original?.id as string} name={info?.row?.original?.deviceName as string} />)
    },
  }),

];
