import { useEffect } from 'react';
import { useTwofaSetupMutation } from 'src/features/api/authSlice';
import { copyToClipboard, sliceText } from 'src/helper';
import { ButtonAuth } from '../reusables';
import { ReactComponent as CopyIcon } from 'src/assets/icons/copy.svg';
import { useNavigate } from 'react-router-dom';

const TwoFa = () => {
  const navigate = useNavigate();

  const [setup2FA, { data: twoFAdata, isLoading }] = useTwofaSetupMutation();

  useEffect(() => {
    setup2FA();
  }, []);

  return (
    <section className="w-[96%] mx-auto md:pb-6 my-3 overflow-y-auto bg-white md:mt-6 rounded-lg">
      <div className="flex flex-col items-center w-full h-full px-4 mx-auto mt-5 mb-12 md:mt-10 md:mb-8">
        <div className="text-center">
          <h2 className="text-lg md:text-xl font-semibold text-black md:mb-[10px]">Authenticator</h2>
          <div className="mb-9">
            <p className="text-clrGray font-medium text-xs md:text-sm lg:w-[80%] mx-auto">
              Link an authenticator to your account for more security{' '}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center p-1 w-28 h-28 QRcode-bg">
          <div className="relative z-40">
            <img src={twoFAdata?.data?.qrCodeUri || ''} alt="QRCODE" width={150} height={150} />
          </div>
        </div>
        <div className="my-6 ">
          <p className="text-clrGray md:w-[40%] mx-auto text-xs md:text-sm">
            Download{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share"
            >
              <span className="text-primary pointer">
                {' '}
                Google Authenticator
              </span>
            </a>
            , add an account, copy and use the key, or scan the QR code
            to set up.
          </p>
        </div>
        <div className="flex items-center justify-between w-full px-4 py-2 mb-4 cursor-pointer md:gap-12 rounded-xl bg-clrBg md:w-auto">
          <p className="text-sm font-semibold text-black">
            {sliceText(twoFAdata?.data?.secret || '', 20)}
          </p>
          <CopyIcon
            className="h-5"
            onClick={() => copyToClipboard(twoFAdata?.data?.secret || '')}
          />
        </div>
        <div className="mt-6 lg:w-[30%] max-w-[19rem] mx-auto">
          <ButtonAuth
            type="submit"
            loading={isLoading}
            handleClick={() => navigate('/home/profile/security/2fa-code')}
            text="Set Code "
          />
        </div>
      </div>
    </section>
  );
};

export default TwoFa;
