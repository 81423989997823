import { useRef, useState } from "react";
import { MobileFilterSearch } from "./OptionsStatus";
import useOutsideClick from "../../hooks/useOutsideClick";
import TableMain from "../reusables/ReactDataTable";
import { managedHubsColumn } from "./ReactTableDataList";
import { EmptyCard, Filter, TableLoader } from "../reusables";
import { useGetDevicesQuery } from "src/features/api/deviceSlice";

const ManagedHubs = () => {
  const [filterStatus, setFilterStatus] = useState<string>('All');
  const [option, toggleOption] = useState<string>("");
  const [searchValue, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);

   // New function to handle filter status
   const handleFilterStatus = (status: string) => {
    if (status === 'All') {
      setFilterStatus(''); // Set to empty string if "All" is selected
    } else {
      setFilterStatus(status); // Otherwise, set to the selected status
    }
  };

  const { data: managedDate, isLoading, isFetching } = useGetDevicesQuery({
    deviceName: searchValue,
    deviceStatus: filterStatus,
    page: page,
    pageSize: 30,
  });

  return (
    <section className="w-[96%] mx-auto mt-3.5 md:mt-0 mb-6 overflow-y-auto">
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-5">
        <div className="flex flex-wrap items-end gap-4 mb-8 md:px-3">
          <div className="flex w-full gap-3 md:w-auto">
          <MobileFilterSearch>
              <Filter
                options={['All', 'Online', 'Offline', 'Blacklisted']}
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={'STATUS'}
                selectFilterOption={handleFilterStatus}
                selectedOption={filterStatus}
                modalRef={modalRef}
                label={'Status'}
              />
            </MobileFilterSearch>
            <div className="flex-1 md:flex-auto ">
              <label
                htmlFor=""
                className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
              >
                Search
              </label>
              <input
                type="text"
                name=""
                id=""
                value={searchValue}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Hub Name"
                className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
              />
            </div>
          </div>
        </div>
        
        <div>
          {isLoading || isFetching ? (
            <TableLoader />
          ) : (
            <TableMain 
              defaultData={managedDate?.data?.records || []} 
              columns={managedHubsColumn} 
              showPagingation={true}
              totalPages={managedDate?.data?.totalPages || 0}
              pageSize={managedDate?.data?.pageSize || 0}
              currentPage={managedDate?.data?.currentPage || 0}
              setPage={setPage}

            />
          )}
        </div>
        {!isLoading && !isFetching && !managedDate?.data?.records?.length && (
            <EmptyCard description="No result found" />
        )}
      </div>
    </section>
  );
};

export default ManagedHubs;
