import { Step } from 'react-joyride';

const JoyrideSteps = () => {
  const steps: Step[] = [
    {
      content: (
        <p className="text-left text-white text-x10">
          Use this code to link your hub to your account.
        </p>
      ),
      disableBeacon: true,
      placement: "bottom",
      target: "#step-1",
      title: (
        <span className="text-sm text-left px-[10px] text-white">Link Code</span>
      ),
    },
    {
      content: (
        <p className="text-left text-white text-x10">
          Monitor your registered hubs, view important statistics, and track detailed performance.
        </p>
      ),
      disableBeacon: true,
      placement: "bottom",
      target: "#step-2",
      title: <span className="text-sm text-left px-[10px] text-white">Manage hubs</span>,
    },
    {
      content: (
        <p className="text-left text-white text-x10">
          Generate a random token for users who want to access your internet. This allows you to set a data or time limit
        </p>
      ),
      disableBeacon: true,
      placement: "top",
      target: "#step-3",
      title: <span className="text-sm text-left px-[10px] text-white">Generate Data Token</span>,
      
    },
    {
      content: (
        <p className="text-left text-white text-x10">
          Easily create forms, collect real-time data, and display it on the hub splash screen.
        </p>
      ),
      disableBeacon: true,
      placement: "right",
      target: "#step-4",
      title: <span className="text-sm text-left px-[10px] text-white">Create Hub Form</span>,
    },
    {
      content: (
        <p className="text-left text-white text-x10">
         This feature helps you restrict access to harmful and unwanted platforms on your hub.
        </p>
      ),
      disableBeacon: true,
      placement: "right",
      target: "#step-5",
      title: <span className="text-sm text-left px-[10px] text-white">Parental Control</span>,
    },
    {
      content: (
        <p className="text-left text-white text-x10">
          This provides a step-by-step guide for setting up a new hub.
        </p>
      ),
      disableBeacon: true,
      placement: "right",
      target: "#step-6",
      title: <span className="text-sm text-left px-[10px] text-white">Set up a Hub</span>,
    },
    {
      content: (
        <p className="text-left text-white text-x10">
          Write a caption you want your viewers to see when viewing your ads 
        </p>
      ),
      disableBeacon: true,
      placement: "right",
      target: "#step-7",
      title: <span className="text-sm text-left px-[10px] text-white">Advert Caption</span>,
    },
    {
      content: (
        <p className="text-left text-white text-x10">
          Experience seamless fund transfers into your account and unlock a range of services hassle-free.
        </p>
      ),
      disableBeacon: true,
      placement: "right",
      target: "#step-8",
      title: <span className="text-sm text-left px-[10px] text-white">Fund my account</span>,
    },
    {
      content: (
        <p className="text-left text-white text-x10">
          Easily organize your hubs by creating groups and assigning them to your preferred clusters.
        </p>
      ),
      disableBeacon: true,
      placement: "right",
      target: "#step-9",
      title: <span className="text-sm text-left px-[10px] text-white">Manage Clusters</span>,
    },
    {
      content: (
        <p className="text-left text-white text-x10">
          Gain instant insights into your account statistics at a glance.
        </p>
      ),
      disableBeacon: true,
      placement: "right",
      target: "#step-10",
      title: <span className="text-sm text-left px-[10px] text-white">Overview</span>,
    },
    {
      content: (
        <p className="text-left text-white text-x10">
          Access the internet securely with our time-based generated login code, ensuring reliable passcode protection.
        </p>
      ),
      disableBeacon: true,
      placement: "top",
      target: "#step-11",
      title: <span className="text-sm text-left px-[10px] text-white">Router Login Code</span>,
    },
  ];

  return steps;
};

export default JoyrideSteps;
