import { createColumnHelper } from '@tanstack/react-table';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-yellow.svg";
// import {ReactComponent as Others} from "../../assets/analytics/others.svg";
import { Button } from "../reusables";
import { CellRendererAnalytics } from "./common";

type Analytics = {
  hubName: string;
  id: string;
  totalConnectedDevices: number;
  totalDataUsed: string;
  totalDataUsedInBytes: number;
  totalSessions: number;
  averageSpeed: string;
  status: string;
};

const columnHelper = createColumnHelper<Analytics>();

export const columnsAnalytics = [
  columnHelper.accessor('hubName', {
    header: 'Hub Name'
  }),
  columnHelper.accessor('averageSpeed', {
    header: 'Average Speed'
  }),
  columnHelper.accessor('totalConnectedDevices', {
    header: 'Connected Devices'
  }),

  columnHelper.accessor('totalSessions', {
    header: 'Sessions'
  }),

  columnHelper.accessor('totalDataUsed', {
    header: 'Data Used'
  }),

  columnHelper.accessor('status', {
    header: 'Status',
    cell: (val) => {
      let status = val.getValue()
      return (
        <div className={`${status.toLowerCase()} text-[10px] rounded-xl p-1 text-center  transition duration-500 ease-in-out`}>
         {status}
        </div>
      );
    },
  }),

  columnHelper.accessor('id', {
    header: '',
    cell: ({row}) => {
      const id = row.original.id;
      const title = row?.original.hubName
      return (
        <Link to={`/home/analytics/connections/${id}/${title}`}>
          <button className="bg-primary text-[#fff] text-[10px] px-3 rounded-xl p-1 text-center  transition duration-500 ease-in-out">
            Analyze
          </button>
        </Link>
      );
    },
  }),
];

// type Session = {
//   amountReceivedByHost: number;
//   amountSpentByClient: number;
//   clientEmail: string;
//   clientMacAddress: string;
//   dataUsedInBytes: number;
//   deviceManagerEmail: string;
//   deviceManagerName: string;
//   deviceName: string;
//   sessionDurationInMinutes: number;
//   sessionStartDateTimestamp: number;
//   id: string;

//   duration: string;
//   dataUsed: string;
//   isWhitelisted: boolean;
//   connectionMedium: string;
//   connectedUser: {
    
//   }
// };

type Session = {
  id: string
  duration: string
  durationInSeconds: number
  dataUsed: string
  dataUsedInBytes: number
  connectionMedium: string
  isWhitelisted: boolean
  isRestricted: boolean
  connectedUser: ConnectedUser
}

interface ConnectedUser {
  id: string
  macAddress: string
  phoneNumber: string
  user: User
  connectedClientType: string
}

interface User {
  id: string
  email: string
  name: string
}

const ConnectionDetailSection = ({id, connectedUser}: {id: string; connectedUser: string}) => {
  const {analyticId, analyticName } = useParams();
  return (
    <Link to={`/home/analytics/connections/${analyticId}/${analyticName}/${id}/${connectedUser}`}>
      <Button
        text={"View More"}
        type={"button"}
        handleClick={() => {}}
        textSize={"text-[0.5rem]"}
        lineHeight={"leading-[0]"}
        wBtn="md:w-[70%]"
      />
    </Link>
  )
}

const columnSession = createColumnHelper<Session>();

export const connectionsColumn = [
  columnSession.accessor('connectedUser', {
    header: 'Connected User',
    cell: ({row}) => {
      const userName = row.original.connectedUser.user.name || ""
      return <p> {userName}</p>;
    },
  }),

  columnSession.accessor('duration', {
    header: 'Duration',
  }),
  columnSession.accessor('dataUsed', {
    header: 'Data Used',
  }),
  columnSession.accessor('connectionMedium', {
    header: 'Token',
  }),
  columnSession.accessor('isWhitelisted', {
    header: 'Whitelisted',
    cell: (val) => {
      return <p> {val ? "Yes" : "No"}</p>;
    },
  }),
  columnSession.accessor('id', {
    header: '',
    cell: (val) => {
      const connectedUserId = val?.row?.original?.connectedUser.id || "";
      const connectedUser = val?.row?.original?.connectedUser.user.name || "";
      return (
        <ConnectionDetailSection id={connectedUserId} connectedUser={connectedUser}  />
      );
    },
  }),
  columnSession.accessor('id', {
    header: '',
    cell: (info) => {
      const macAddress = info?.row?.original?.connectedUser.macAddress || '';
      const clientAlias = info?.row?.original?.connectedUser.user.name || '';
      const isWhitelisted = info?.row?.original?.isWhitelisted || false;
      const isRestricted = info?.row?.original?.isRestricted || false;
      return (
        <CellRendererAnalytics
          id={info?.row?.original?.id}
          macAddress={macAddress}
          clientAlias={clientAlias}
          isWhitelisted={isWhitelisted}
          isRestricted={isRestricted}
          isDisconnected={false}
        />
      );
    },
  }),
];

export const dataChart: {
  date: string;
  value: number;
}[] = [
  // January 2023
  { date: '2023-01-01', value: 10 },
  { date: '2023-01-02', value: 20 },
  { date: '2023-01-03', value: 30 },

  // February 2023
  { date: '2023-02-01', value: 15 },
  { date: '2023-02-02', value: 25 },
  { date: '2023-02-03', value: 35 },

  // March 2023
  { date: '2023-03-01', value: 20 },
  { date: '2023-03-02', value: 30 },
  { date: '2023-03-03', value: 40 },

  // April 2023
  { date: '2023-04-01', value: 25 },
  { date: '2023-04-02', value: 35 },
  { date: '2023-04-03', value: 45 },

  // May 2023
  { date: '2023-05-01', value: 30 },
  { date: '2023-05-02', value: 40 },
  { date: '2023-05-03', value: 50 },

  // June 2023
  { date: '2023-06-01', value: 35 },
  { date: '2023-06-02', value: 45 },
  { date: '2023-06-03', value: 55 },

  // July 2023
  { date: '2023-07-01', value: 40 },
  { date: '2023-07-02', value: 50 },
  { date: '2023-07-03', value: 60 },

  // August 2023
  { date: '2023-08-01', value: 45 },
  { date: '2023-08-02', value: 55 },
  { date: '2023-08-03', value: 65 },

  // September 2023
  { date: '2023-09-01', value: 50 },
  { date: '2023-09-02', value: 60 },
  { date: '2023-09-03', value: 70 },

  // October 2023
  { date: '2023-10-01', value: 55 },
  { date: '2023-10-02', value: 65 },
  { date: '2023-10-03', value: 75 },

  // November 2023
  { date: '2023-11-01', value: 60 },
  { date: '2023-11-02', value: 70 },
  { date: '2023-11-03', value: 80 },

  // December 2023
  { date: '2023-12-01', value: 65 },
  { date: '2023-12-02', value: 75 },
  { date: '2023-12-03', value: 85 },
  
];


// Traffic analytics

type TrafficAnalytics = {
  category: {id: string, name: string},
  platforms: string,
  visits: string,
  percentageOfTotalVisits: string,
  id: string,
  platformCount: string,
}


const columnTraffic = createColumnHelper<TrafficAnalytics>();


const ViewMoreCategory = ({category, trafficCategory }: {category: string; trafficCategory: string}) => {
  const { analyticId } = useParams();

  return (
    <Link to={`/home/analytics/traffic/${analyticId}/${category}/${trafficCategory}`}>
      <ArrowRight className="w-4 h-4" />
    </Link>
  )
}

export const trafficColumn = [
  columnTraffic.accessor('category', {
    header: 'Category',
    cell: (val) => {
      const category = val.getValue();
      return (
        <div className="flex items-center gap-1">
          <p>{category?.name}</p>
        </div>
      );
    },
  }),

  columnTraffic.accessor('platformCount', {
    header: 'Platforms',
  }),
  columnTraffic.accessor('visits', {
    header: 'Visits',
  }),
  columnTraffic.accessor('percentageOfTotalVisits', {
    header: 'Visit ratio',
  }),
  columnTraffic.accessor('id', {
    header: '',
    cell: (val) => {
      const category = val?.row?.original?.category?.id;
      const trafficCategory = val?.row?.original?.category?.name;
      return (
        <ViewMoreCategory category={category} trafficCategory={trafficCategory} />
      );
    },
  }),
];

// Traffic details analytics

type TrafficDetailsAnalytics = {
  platform: string,
  visits: string,
  percentageOfTotalVisits: string,
}


const columnTrafficDetails = createColumnHelper<TrafficDetailsAnalytics>();

export const trafficDetailsColumn = [

  columnTrafficDetails.accessor('platform', {
    header: 'Platforms',
  }),
  columnTrafficDetails.accessor('visits', {
    header: 'Visits',
  }),
  columnTrafficDetails.accessor('percentageOfTotalVisits', {
    header: 'Visit ratio',
  }),
  // columnTrafficDetails.accessor('timeSpent', {
  //   header: 'Time spent',
  // }),
];
