import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonOutlineWallet } from '../reusables';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ReactComponent as FundIcon } from '../../assets/icons/wallet/wallet-download.svg';
import { ReactComponent as WithdrawIcon } from '../../assets/icons/wallet/wallet-upload.svg';
import { ReactComponent as ClosedEye } from '../../assets/auth/eye-closed.svg';
import { ReactComponent as OpenEye } from '../../assets/auth/eye-open.svg';
import {
  useGetBalancesQuery,
  useLazyGetSupportedChannelsQuery,
} from '../../features/api/walletSlice';
import { numberWithCommas } from '../../helper';
import { ScaleLoader } from 'react-spinners';
import {
  ChooseCurrency,
  // ChooseWalletMethod,
  ChoosePreferredMethod,
} from './Others';

import { selectCurrency } from '../../features/store/walletReducerSlice';
import { Currency } from '../../features/api/walletSliceTypes';
// import TransferFlow from "./TransferFlow";
import PreviewHistory from './transactionHistory/PreviewHistory';
import useOutsideClick from '../../hooks/useOutsideClick';
import { fundingMethods, withdrawalMethods } from './data';

interface OptionProps {
  title: string;
  description: string;
  type: 'FUNDING' | 'WITHDRAWAL' | '';
  methods: any;
}

const Wallet = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Set Transfer steps
  // const [step, setStep] = useState<number>(0);

  //toggle view or hide for the balance
  const [isView, setView] = useState<boolean>(false);
  // const [isBalanceView, setBalanceView] = useState<boolean>(false);

  //Set transaction option
  const [isOption, setOption] = useState<OptionProps>({
    title: '',
    description: '',
    type: '',
    methods: [],
  });

  //Control the swap modal
  const [swapStep, setSwapStep] = useState<number>(0);

  const { userId } = useAppSelector((state) => state.auth);
  // const { selectedCurrency: currency } = useAppSelector(
  //   (state) => state.wallet
  // );

  //Get user's total balance
  const { data: balances, isLoading: loadingBalance } =
    useGetBalancesQuery(userId);

  //Get supported channels
  const [trigger, { data: supportedChannels, isLoading: loadingChannels }] =
    useLazyGetSupportedChannelsQuery();

  const [isChooseMoreFund, setIsChooseMoreFund] = useState(false);
  const [isChooseWalletMethod, setIsChooseWalletMethod] = useState(false);
  const [isWalletAddress, setIsWalletAddress] = useState(false);

  const modalRef = useRef<HTMLElement>(null);

  console.log(isChooseWalletMethod, isWalletAddress, swapStep);

  // function to close all modals
  const handleCloseModal = () => {
    setOption({ title: '', description: '', type: '', methods: [] });
    setSwapStep(0);
    // setIsChooseMoreFund(false);
    // setIsWalletAddress(false);
    // setIsChooseWalletMethod(false);
  };

  const handleP2P = () => {
    navigate('/home/wallet/peer-to-peer');
  };

  const handleOpenStake = () => {
    navigate('/home/wallet/stake');
  };

  // const handleWithdrawP2P = () => {
  //   navigate("/home/wallet/withdraw-P2P");
  // };

  const handleSelected = (
    type: 'FUNDING' | 'WITHDRAWAL' | 'STAKE' | 'SWAP' | '',
    currency: Currency
  ) => {
    dispatch(selectCurrency(currency));
    switch (type) {
      case 'FUNDING':
        trigger({ category: 'Funding', currencyId: currency?.id });
        setIsChooseMoreFund(true);
        return;
      case 'WITHDRAWAL':
        trigger({ category: 'Withdrawal', currencyId: currency?.id });
        setIsChooseWalletMethod(true);
        return;
      case 'SWAP':
        setSwapStep(1);
        return;
      case 'STAKE':
        handleOpenStake();
        return;
      default:
        break;
    }
  };

  useOutsideClick(modalRef, handleCloseModal);

  return (
    <section className="w-[96%] mx-auto pb-6 mt-3 overflow-y-auto">
      <div className="flex flex-wrap gap-2 mb-2 md:flex-nowrap">
        <div className="flex flex-col justify-between w-full p-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-8 md:py-6 h-44 md:h-52">
          <div className="flex items-end justify-between">
            <div>
              <div className="flex items-center gap-2 mb-1">
                <h3 className="text-xs font-semibold md:text-sm text-clrGray dark:text-clrPlaceholder">
                  Total value
                </h3>
                {isView ? (
                  <OpenEye
                    className="cursor-pointer"
                    height="15px"
                    width="15px"
                    onClick={() => setView(!isView)}
                  />
                ) : (
                  <ClosedEye
                    className="cursor-pointer"
                    height="15px"
                    width="15px"
                    onClick={() => setView(!isView)}
                  />
                )}
              </div>
              <p className="text-4xl font-bold dark:text-white">
                {'NGN '}
                {loadingBalance ? (
                  <ScaleLoader
                    loading={loadingBalance}
                    height={12}
                    width={2}
                    color={'#3d5170'}
                  />
                ) : !isView ? (
                  '****'
                ) : (
                  numberWithCommas(
                    balances ? balances?.data.usdWorthOfAllBalances : 0,
                    2
                  )
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap gap-3 md:self-end">
            <ButtonOutlineWallet
              type="button"
              text="Fund"
              handleClick={() =>
                setOption({
                  title: 'Fund Wallet',
                  description: 'Select preferred currency to fund',
                  type: 'FUNDING',
                  methods: fundingMethods,
                })
              }
              showIcon={true}
              btnIcon={<FundIcon />}
              bgColor="bg-clrYellow2"
              textColor="text-white"
              textSize="text-xs"
            />
            <ButtonOutlineWallet
              type="button"
              text="Withdraw"
              handleClick={() =>
                setOption({
                  title: 'Withdraw to Wallet',
                  description: 'Select preferred withdraw currency',
                  type: 'WITHDRAWAL',
                  methods: withdrawalMethods,
                })
              }
              showIcon={true}
              btnIcon={<WithdrawIcon />}
              textSize="text-xs"
            />
          </div>
        </div>
      </div>
      <PreviewHistory />
      {/* handles choosing currrency for all transaction types */}
      {isOption.title && (
        <ChooseCurrency
          handleModal={handleCloseModal}
          handleSelectedCurrency={handleSelected}
          title={isOption.title}
          description={isOption.description}
          type={isOption.type}
          methods={isOption.methods}
        />
      )}
      {/* handles currency funding method */}
      {isChooseMoreFund && (
        <ChoosePreferredMethod
          handleCloseModal={handleCloseModal}
          handleModal={() => setIsChooseMoreFund(false)}
          handleCryptoFunding={() => setIsWalletAddress(true)}
          handleP2P={handleP2P}
          channels={supportedChannels?.data.channels || []}
          isLoading={loadingChannels}
          title="Fund Wallet"
          description="Select preferred method of funding"
        />
      )}
      {/* handles showing options for withdrawal */}
      {/* {isChooseWalletMethod && (
        <ChooseWalletMethod
          handleModal={() => setIsChooseWalletMethod(false)}
          handleCloseModal={handleCloseModal}
          handleTransfer={() => {
            setStep(1);
            handleCloseModal();
          }}
          channels={supportedChannels?.data.channels || []}
          handleWithdrawP2P={handleWithdrawP2P}
          handleCrypto={handleCrypto}
          title="Withdraw Wallet"
          isLoading={loadingChannels}
          description="Select preferred method of withdraw"
        />
      )} */}
      {/* handle withdrawal by transfer   */}
      {/* {step !== 0 && <TransferFlow step={step} setStep={setStep} />} */}
    </section>
  );
};

export default Wallet;
