import { Button } from "../reusables";
import emptystate from "../../assets/control/empty-state.svg";
import { useNavigate } from "react-router-dom";

const NoParentalControl = () => {
  const navigate = useNavigate();
  
  return (
      <div className="flex flex-col justify-center items-center min-h-[calc(100vh-250px)]">
        <img src={emptystate} alt="No active parental control" width={200} height={200} />
        <div className="grid gap-4 mt-4 mb-20 text-center w-4/5 md:w-[450px]">
          <h2 className="text-xl font-semibold text-black">Parental Control Subscription not available</h2>
          <p className="text-base font-normal text-clrGray w-4/5 mx-auto">You do not have an active subscription for this service , click the link below to subscribe</p>
        </div>
        <Button
            handleClick={() => navigate("/home/subscription")}
            text="Activate Parental Control"
            type="button"
            wBtn="!w-fit"
            fontWeight="font-normal"
            py="py-2.5"
            px="px-6"
            textSize="text-base"
            loading={false}
        />
      </div>
  )
}

export default NoParentalControl
