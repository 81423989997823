import React from "react";
import { ButtonLoader } from "./Loader";
import { Link, To } from "react-router-dom";

interface BtnProps {
  text: string;
  type: "button" | "submit" | "reset" | undefined;
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disabled?: boolean;
  testId?: string;
  bgColor?: string;
  textColor?: string;
  active?: boolean;
  btnIcon?: React.ReactNode;
  showIcon?: boolean;
  imgHeight?: string;
  imgWidth?: string;
  wBtn?: string;
  btnIconEnd?: string;
  showIconEnd?: boolean;
  py?: string;
  fontWeight?: string;
  textSize?: string;
  id?: string;
  marginX?: string;
  borderColor?: string;
  borderWidth?: string;
  lineHeight?: string;
  px?: string;
}

export const ButtonAuth = (props: BtnProps) => {
  const {
    text,
    type,
    handleClick,
    disabled = false,
    testId,
    loading = false,
    wBtn = "w-full",
    fontWeight = "font-semibold",
    py = "py-2 md:py-2.5",
    textSize = "text-xs",
    id,
  } = props;

  return (
    <button
      onClick={handleClick}
      id={id}
      type={type}
      disabled={disabled}
      data-testid={testId}
      className={`flex justify-center items-center disabled:opacity-40 border-none ${py} bg-primary rounded-full px-4 w-full ${wBtn} mx-auto transition-all duration-300 hover:scale-105 focus:outline-none whitespace-nowrap `}
    >
      <p className={`${textSize} ${fontWeight} text-white whitespace-nowrap`}>
        {loading ? <ButtonLoader loading={loading} /> : text}
      </p>
    </button>
  );
};
export const Button = (props: BtnProps) => {
  const {
    text,
    type,
    handleClick,
    disabled = false,
    testId,
    loading = false,
    wBtn = "w-full",
    fontWeight = "font-semibold",
    py = "py-2 md:py-2.5",
    textSize = "text-xs",
    id,
    lineHeight,
    px = "px-4",
    bgColor = "bg-primary"
  } = props;

  return (
    <button
      onClick={handleClick}
      id={id}
      type={type}
      disabled={disabled}
      data-testid={testId}
      className={`flex justify-center items-center disabled:opacity-40 border-none ${py} ${bgColor} dark:bg-white rounded-full ${px} w-full ${wBtn} mx-auto transition-all duration-300 hover:scale-105 focus:outline-none whitespace-nowrap `}
    >
      <p
        className={`${textSize} ${fontWeight} text-white dark:text-clrTextBlue whitespace-nowrap ${lineHeight}`}
      >
        {loading ? <ButtonLoader loading={loading} /> : text}
      </p>
    </button>
  );
};

export const ButtonOutline = (props: BtnProps) => {
  const {
    text,
    type,
    disabled = false,
    wBtn = "w-full",
    marginX = "mx-auto",
    textColor = "text-primary",
    borderColor = "border-primary",
    py = "py-2 md:py-2.5",
    textSize = "text-xs",
    borderWidth = "border-2",
    fontWeight = "font-normal",
    handleClick,
  } = props;

  return (
    <button
      onClick={handleClick}
      type={type}
      disabled={disabled}
      className={`flex justify-center ${borderWidth} border-solid ${borderColor} bg-transparent rounded-full ${py} ${wBtn} ${marginX} transition-all duration-500 ease-in-out ${textColor} active:bg-primary active:text-white hover:text-white hover:bg-primary hover:font-normal hover:scale-105 focus:outline-none whitespace-nowrap`}
    >
      <p className={`${textSize} ${fontWeight} whitespace-nowrap`}>{text}</p>
    </button>
  );
};

export const ButtonGold = (props: BtnProps) => {
  const { text, textSize = "text-xs", type, handleClick } = props;

  return (
    <button
      onClick={handleClick}
      type={type}
      className="flex justify-center border border-solid border-[#cfa80959] bg-[#cfa80917] rounded-full py-1 px-4 w-fit transition-all duration-300 text-[#CFA809] active:bg-primary active:text-white hover:text-white hover:bg-primary hover:scale-105 focus:outline-none whitespace-nowrap"
    >
      <p className={`${textSize} font-normal whitespace-nowrap`}>{text}</p>
    </button>
  );
};

interface BtnYellowProps {
  text: string;
  disabled?: boolean;
  to: To;
  btnIcon?: string;
  textSize?: string;
  imgWidth?: string;
  imgHeight?: string;
  btnHeight?: string;
}

export const ButtonYellow = (props: BtnYellowProps) => {
  const {
    text,
    to,
    btnIcon,
    textSize = "text-xs",
    imgWidth = "18px",
    imgHeight = "18px",
    btnHeight = "h-8",
  } = props;

  return (
    <Link
      to={to}
      className={`flex justify-between items-center w-fit ${btnHeight} mt-3 px-4 gap-3 bg-primary rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#e3be27] hover:scale-[0.95]`}
    >
      <img
        src={btnIcon}
        alt="Buy Hub"
        width={18}
        height={18}
        className={`${imgHeight} ${imgWidth}`}
      />
      <p className={`${textSize} font-medium text-white`}>{text}</p>
    </Link>
  );
};

export const ButtonOutlineWallet = (props: BtnProps) => {
  const {
    text,
    type,
    disabled = false,
    handleClick,
    bgColor,
    textColor = "text-primary",
    active = false,
    showIcon = false,
    btnIcon,
    imgWidth = "15px",
    imgHeight = "15px",
    wBtn,
    showIconEnd = false,
    btnIconEnd,
    py = "py-[0.1875rem]",
    textSize = "text-x10",
    fontWeight = "font-semibold",
    id,
    loading,
  } = props;

  const buttonClassNames = `wallet-btn flex justify-center items-center ${wBtn} gap-2 px-4 transition-all duration-300 border-[1px] border-solid border-primary rounded-full ${bgColor} ${py} ${textColor} ${
    active ? "bg-primary text-white" : ""
  } hover:bg-primary hover:text-white hover:scale-[0.97] focus:outline-none whitespace-nowrap`;

  return (
    <button
      onClick={handleClick}
      type={type}
      disabled={disabled}
      className={buttonClassNames}
      id={id}
    >
      {showIcon && (
        <div className="wallet-icons">
          {/* <img src={btnIcon as string} alt="Button" width={imgWidth} height={imgHeight} /> */}
          {btnIcon}
        </div>
      )}
      {loading ? (
        <ButtonLoader loading={loading} />
      ) : (
        <p className={`${textSize} ${fontWeight} whitespace-nowrap`}>{text}</p>
      )}

      {showIconEnd && (
        <img
          src={btnIconEnd}
          alt="Button"
          width={imgWidth}
          height={imgHeight}
          className="ml-auto rotate-180"
        />
      )}
    </button>
  );
};

interface SeeTourButtonProps {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SeeTourButton = ({handleClick}: SeeTourButtonProps) => {
  return (
    <button className="flex gap-1.5 items-center p-2 rounded-lg transition duration-300 ease-in-out hover:bg-primary hover:bg-opacity-10" onClick={handleClick}>
      <div className="grid w-5 h-5 rounded-full bg-primary bg-opacity-40 place-content-center">
        <p className="text-x10 text-primary">!</p>
      </div>
      <p className="font-medium text-clrDarkYellow text-x10 md:text-xs">See tour</p>
    </button>
  )
}