import { useGetResponseDetailQuery } from "src/features/api/dynamicFormSlice";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { TableLoader } from "../reusables";
import { SubscriptionDetailProps } from "../hubForm/types";


interface ResponsePayload {
    [key: string]: string;
}

export const SubscriptionDetail = ({ modalRef, handleCloseModal, id }: SubscriptionDetailProps) => {

   const { data: responseDetail, isLoading: gettingResponseDetail } = useGetResponseDetailQuery(id as string);
    const renderResponse = () => {
      const response: ResponsePayload = responseDetail?.data?.responsePayload || {}; // Added missing closing parenthesis
  
      let responses = []
  
      for (const key in response) {
        // Check if the property belongs to the object itself and not its prototype chain
        if (response.hasOwnProperty(key)) {
          responses.push(
            <div className="flex gap-2">
              <div>
                <p className="mb-3 text-xs font-normal text-clrGray">{key}</p>
                <p className="text-xs font-semibold text-black">{response[key]}</p>
              </div>
            </div>
          )
        }
      }
      return responses
    };
  
  
    return (
      <div>
        <section
          className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
          ref={modalRef}
        ></section>
        <div className="fixed top-0 right-0 z-30 h-screen px-10 py-12 bg-white min-w-fit">
          <div className="flex items-center gap-2 mb-6">
            <button className="btn-reset" onClick={handleCloseModal}>
              <ArrowBack className="w-5 h-5 dark-arrow" />
            </button>
            <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
                Subscription Details
            </h3>
          </div>
          <div className="grid gap-11">
            {gettingResponseDetail ? <TableLoader /> : renderResponse()}
          </div>
  
        </div>
      </div>
    );
  };