import React, { useState } from 'react';
import { Card, CardTitle, ButtonAuth, ErrorMsg } from '../../reusables';
import AuthFrame from '../../reusables/AuthFrame';
import AuthCode from 'react-auth-code-input';
import { useGenerateEmailOTPMutation } from '../../../features/api/authSlice';

interface VerifyProps {
  email: string;
  handleBack: () => void;
  handleNext: (title?: string, value?: string) => void;
}

//Component to verify that user has received authentication otp
const Verify = ({ email, handleNext, handleBack }: VerifyProps) => {
  const [otpCode, setCode] = useState('');
  const [isError, setError] = useState('');

  const [generateEmailOTP, { isLoading }] = useGenerateEmailOTPMutation();

  //function to save the otp code sent
  const handleChange = (res: string) => {
    if (isError) {
      setError('');
    }
    setCode(res);
  };

  const handleResendOTP = () => {
    generateEmailOTP(email);
  };

  const handleVerify = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (otpCode.length === 6) {
      // Redirect to create pin page
      handleNext('otp', otpCode);
    } else {
      setError('OTP must be 6 digits long');
    }
  };

  return (
    <div className="flex">
      <Card>
        <div className="flex items-center flex-col mt-2 w-[300px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
          <div className="text-center md:text-left">
            <CardTitle title="Verify Email" />
            <p className="text-clrText font-medium text-[0.625rem] md:text-sm mb-12">
              Please enter the six digit code we sent to:{' '}
              <span className="text-primary">{email}</span>
            </p>
          </div>
          <div className="text-center">
            <label
              htmlFor="verify-otp"
              className="block text-[0.625rem] leading-3 text-black md:text-sm font-semibold mb-4"
            >
              Enter code
            </label>
            <div className="mb-4">
              <AuthCode
                inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary border-transparent border-solid border rounded-md bg-primary/10"
                containerClassName="flex justify-center gap-3"
                ariaLabel="verify-otp"
                isPassword={true}
                autoFocus={true}
                length={6}
                allowedCharacters="numeric"
                onChange={handleChange}
              />
              <ErrorMsg errMsg={isError} />
            </div>
          </div>
          <div className="mt-4 mb-10">
            <button
              className="text-xs font-normal cursor-pointer text-primary"
              onClick={handleResendOTP}
              disabled={isLoading}
            >
              {isLoading ? 'Resending...' : 'Resend PIN'}
            </button>
          </div>
          <div className="mt-6 mb-8 w-[80%]">
            <ButtonAuth
              type="submit"
              handleClick={handleVerify}
              text="Submit"
            />
          </div>

          <div className="mt-4 mb-10 cursor-pointer">
            <div onClick={handleBack}> ← Back </div>
          </div>
        </div>
      </Card>
      <AuthFrame />
    </div>
  );
};

export default Verify;
