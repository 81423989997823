import { useState } from "react";
import { useGetDevicesQuery } from "src/features/api/deviceSlice";
import { CheckboxState, MoreResponseModalProps } from "../hubForm/types";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
import { Button, ButtonOutline, ErrorMsg, TableLoader } from "../reusables";
import { EmptyHubCluster } from "../hubForm/hubModals";
import { useAddClusterMutation } from "src/features/api/clusterSlice";
import { useAppSelector } from "src/app/hooks";

export const AddClusterModal = ({ modalRef, handleCloseModal }: MoreResponseModalProps) => {
    const [deviceStatusList, setIsCheckboxChecked] = useState<CheckboxState>({});
    const { businessId } = useAppSelector((state) => state.auth);
    const [isSelectAll, setSelectApp] = useState<boolean>(true);
    const [deviceToSearch, setDeviceSearch] = useState<string>("");
    const [clusterName, setClusterName] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [section, setSection] = useState<number>(0);

    const { data, isLoading, isFetching } = useGetDevicesQuery({
        deviceName: deviceToSearch,
        deviceStatus: "All",
        page: 1,
        pageSize: 30,
    });

    const [trigger, { isLoading: addingCluster }] = useAddClusterMutation()

    const handleCheckboxChange = (id: string) => {
        setIsCheckboxChecked(prevState => ({
            ...prevState,
            [id]: !prevState[id] // Toggle the checkbox value
        }));
    };

    const handleSelectAll = () => {
        data?.data?.records.map((each) => {
            handleCheckboxChange(each.id)
        })
        setSelectApp(!isSelectAll)
    }


    const handleSave = () => {
        setErrorMsg("");
        const devicesWithTrueStatus = Object.entries(deviceStatusList)
            .filter(([_id, status]) => status === true)
            .map(([id]) => id);
        const clusterData = {
            payload: {
                listName: clusterName,
                deviceIds: devicesWithTrueStatus
            },
            businessId
        }
        if (devicesWithTrueStatus.length > 0) {
            trigger(clusterData)
                .unwrap()
                .then(() => {
                    handleCloseModal()
                })
                .catch((err) => {
                    setErrorMsg(err?.data?.message || "Something went wrong!")
                })

        } else {
            setErrorMsg("Select the devices you want this form applied to")
        }
    }

    return (
        <div>
            <section
                className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033]"
                ref={modalRef}
            ></section>
            {section === 0 ?
                <div className="fixed top-0 right-0 z-30 flex flex-col h-screen px-10 py-12 bg-white w-fit md:w-96">
                    <div className="flex items-center gap-2 mb-6">
                        <button className="btn-reset" onClick={handleCloseModal}>
                            <ArrowBack className="w-5 h-5 dark-arrow" />
                        </button>
                        <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
                            Add Cluster
                        </h3>
                    </div>
                    <p className="mt-2 text-xs font-normal text-clrGray mb-4">Input your cluster information</p>

                    <div>
                        <label className="text-xs" >Cluster Name</label>
                        <input
                            type="text"
                            name="" id=""
                            placeholder="My Cluster"
                            value={clusterName}
                            onChange={(e) => setClusterName(e.target.value)}
                            className="relative flex items-center justify-between w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none placeholder:!text-clrPlaceholder border-primary dark:border-clrGray focus:outline-none" />
                    </div>
                    <div className="flex justify-end mt-auto">
                        <ButtonOutline
                            type="button"
                            text={"Back"}
                            handleClick={handleCloseModal}
                            fontWeight="font-normal"
                            wBtn="md:w-[8rem]"
                        />
                        <Button
                            type="button"
                            text={"Next"}
                            disabled={clusterName === ""}
                            handleClick={() => setSection(1)}
                            fontWeight="font-normal"
                            wBtn="md:w-[8rem]"
                        />
                    </div>

                </div>
                :
                <div className="fixed top-0 right-0 z-30 flex flex-col h-screen px-10 py-12 bg-white w-fit md:w-96">
                    <div className="flex items-center gap-2 mb-6">
                        <button className="btn-reset" onClick={handleCloseModal}>
                            <ArrowBack className="w-5 h-5 dark-arrow" />
                        </button>
                        <h3 className="text-xs font-semibold md:text-sm text-clrTitle dark:text-white">
                            Add Cluster
                        </h3>
                    </div>
                    <p className="mt-2 text-xs font-normal text-clrGray">Input your cluster information</p>
                    <div className="flex flex-col gap-10 mt-5">
                        {(data?.data?.records.length || 0) > 0 &&
                            <>
                                <div className="flex items-center justify-between">
                                    <p className="text-base font-semibold text-clrGray">Select Hubs</p>
                                    <button
                                        type="button"
                                        className="flex gap-2"
                                        onClick={() => handleSelectAll()}
                                    >
                                        {isSelectAll ?
                                            <p className="text-[0.625rem] font-semibold text-primary">Select all</p> :
                                            <p className="text-[0.625rem] font-semibold text-primary">De-select all</p>
                                        }
                                    </button>
                                </div>
                                <div className="mt-1.5">
                                    <label htmlFor="" className="hidden">Search</label>
                                    <input
                                        type="text"
                                        name="" id=""
                                        placeholder="Search"
                                        value={deviceToSearch}
                                        onChange={(e) => setDeviceSearch(e.target.value)}
                                        className="relative flex items-center justify-between w-full h-8 pb-1 text-xs font-normal text-black border-b border-solid outline-none placeholder:!text-clrPlaceholder border-primary dark:border-clrGray focus:outline-none" />
                                </div>
                            </>}
                        <div className="space-y-6">
                            {isLoading && <TableLoader />}

                            {data?.data?.records.map((hubs) => {
                                return (
                                    <label
                                        key={hubs?.id}
                                        className="material-checkbox text-[10px] font-normal text-black"
                                    >
                                        {hubs?.name}
                                        <input
                                            checked={deviceStatusList[hubs?.id]}
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(hubs?.id)}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                )
                            })}
                        </div>

                        {!isLoading && !isFetching && (data?.data?.records.length || 0) === 0 &&
                            <EmptyHubCluster
                                type="hubs"
                                message="You have no hubs"
                                actionBtn="Buy a hub"
                                link="http://shop.mtn.ng/"
                            />}
                    </div>
                    <ErrorMsg errMsg={errorMsg} />
                    {(data?.data?.records.length || 0) > 0 &&
                        <div className="flex justify-between mt-auto">

                            <ButtonOutline
                                type="button"
                                text={"Back to Info"}
                                handleClick={() => setSection(0)}
                                fontWeight="font-normal"
                                wBtn="md:w-[8rem]"
                            />
                            <Button
                                type="button"
                                text={"Next"}
                                handleClick={handleSave}
                                fontWeight="font-normal"
                                wBtn="md:w-[8rem]"
                                loading={addingCluster}
                            />
                        </div>
                    }
                </div>
            }

        </div>
    );
};