import { useState } from "react";
import { Button, ErrorMsg } from "../../reusables";
import { ReactComponent as CloseIcon } from "../../../assets/icons/hotspot-hubs/close.svg";
import { handlePaste } from "src/helper";



type VerificationCodeProps = {
    modalRef: any
    handleSubmit: (val: string) => void
    buttonTitle: string
    formTitle: string
    description: string
    handleModal: () => void
    timer?: string
    errMsg: string
    loading: boolean
}

export const VerificationCodeModal = ({
    modalRef,
    handleSubmit,
    handleModal,
    buttonTitle,
    formTitle,
    description,
    timer,
    errMsg,
    loading
}: VerificationCodeProps) => {
    const [faCode, setCode] = useState<string>("");

    const handleClick = async () => {
        const clipboardText = await handlePaste();
        setCode(clipboardText || "");
      };

    return (
        <section
            className="fixed inset-0 z-50 h-screen overflow-hidden bg-[#F4F4F4] md:bg-[#00000033] dark:md:bg-[#00000080] dark:bg-clrDarkBg2 flex justify-center md:items-center"
            ref={modalRef}
            >
            <div className="bg-clrBg2 dark:bg-clrDarkBg2 py-6 z-30 rounded-xl w-11/12 md:px-4 md:w-[450px]">

                <div className="flex items-center justify-between mb-5">
                
                    <div className="">
                        <h1 className="text-lg font-semibold text-[#000000] dark:text-white">
                            Login Verification
                        </h1>
                    </div>
                    <button onClick={handleModal}>
                        <CloseIcon className="w-[13px] h-[13px] self-end dark-close" />
                    </button>
                </div>
                <div className="">

                    <div className={`px-7 py-8 bg-white rounded-xl dark:bg-clrDarkBg ${errMsg ? "mb-2" : "mb-8"}`}>
                        <div className="md:w-[270px]">
                            <label
                                htmlFor="verification-code"
                                className="block text-x10 text-clrGray md:text-x11 font-medium mb-0.5"
                            >
                                {formTitle}
                            </label>
                            <div className="relative">
                                <input
                                    className="w-full px-3 text-sm text-black rounded-lg bg-clrBg h-11 focus:outline-none"
                                    type="number" 
                                    value={faCode}
                                    onChange={(e)=> setCode(e.target.value)}
                                />
                                {timer ?
                                    <span
                                        className="absolute cursor-pointer top-[35%] right-[6%] text-xs text-primary"
                                    >
                                        {timer}
                                    </span>
                                    : <span
                                        onClick={handleClick}
                                        className="absolute cursor-pointer top-[35%] right-[6%] text-xs text-primary"
                                    >
                                        Paste
                                        </span>}
                            </div>
                            <p className="font-medium text-x10 md:text-x11 text-clrGray dark:text-clrPlaceholder" >
                                {description}
                            </p>
                        </div>
                    </div>
                    <ErrorMsg errMsg={errMsg} />
                    <div className="md:w-[80%] mt-6 mx-auto ">
                        <Button
                            type="button"
                            text={buttonTitle || ""}
                            handleClick={()=> handleSubmit(faCode)}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};