import { useEffect, useRef, useState } from 'react';
import ArrowYellow from '../../assets/icons/arrow-down-Yellow.svg';
import { ReactComponent as Phone2 } from '../../assets/icons/phone.svg';
import Profile from '../../assets/profile-pic.svg';
import CustomerSupport from '../home/CustomerSupport';
import TopRightMenu from '../home/TopRightMenu';
import { ReactComponent as Menu2 } from '../../assets/icons/menu.svg';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ReactComponent as ArrowBack } from '../../assets/icons/arrow-back.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useAppSelector } from '../../app/hooks';
import RouterLogin from '../routerLogin';
import { useLazyGetPackageQuery } from "src/features/api/subscriptionSlice";
import { PackageDetail } from "../subscription/SelectedPlan";
import { useGetBusinessProfileQuery } from 'src/features/api/authSlice';

interface NotifyProps {
  handleMenu: () => void;
}

const Notify = ({ handleMenu }: NotifyProps) => {
  const [showSupport, setShowSupport] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const {
    agentName,
    businessUserId,
    businessId,
    sessionId,
    tokenId,
    planId,
    billingType,
    deviceName,
    deviceId,
    formId,
    formName,
    analyticName,
    analyticId,
    category,
    categoryId,
    trafficId,
    trafficCategory,
    connectedUser,
    connectedUserId,
  } = useParams();

  const { appModal } = useAppSelector((state) => state.auth);

  const modalRef = useRef<HTMLElement>(null);
  const modalRefProfile = useRef<HTMLElement>(null);

  const location = useLocation();

  // Getting subscription type
  const [packageDetail, setPackageDetail] = useState<PackageDetail>();
  const [trigger] = useLazyGetPackageQuery();
  useEffect(() => {
    if (planId) {
      trigger(planId)
        .unwrap()
        .then((res) => {
          setPackageDetail(res.data);
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [planId, trigger])

  const handleCloseModal = () => {
    setShowSupport(false);
    setShowProfile(false);
  }; 

  useOutsideClick(modalRef, handleCloseModal);
  useOutsideClick(modalRefProfile, handleCloseModal);

  const titleMap: { [key: string]: string } = {
    '/home/dashboard': 'Home',
    '/home/dashboard/hub-setup': 'Hub Setup',
    '/home/dashboard/business-reg': 'Business Registration',
    '/home/hotspot-hubs': 'Hotspot Hubs',
    '/home/wallet': 'Wallet',
    '/home/hotspot-hubs/my-hubs': 'Hotspot Hubs',
    '/home/hotspot-hubs/live-session': 'Hotspot Hubs',
    '/home/hotspot-hubs/hubs-report': 'Hotspot Hubs',
    '/home/hotspot-hubs/managed-hubs': 'Hotspot Hubs',
    '/home/hotspot-hubs/device-tracker': 'Track Data',
    '/home/hotspot-hubs/session-history': 'Session History',
    '/home/wallet/transactionHistory': 'Transaction History',
    '/home/wallet/peer-to-peer': 'Peer to Peer(P2P)',
    '/home/wallet/bank-transfer': 'Fund Balance',
    '/home/wallet/ussd': 'Fund Balance',
    '/home/wallet/withdrawal-bank-transfer': 'Withdraw Balance',
    '/home/wallet/user': 'Withdraw Balance',
    '/home/wallet/withdraw-P2P': 'Peer to Peer(P2P)',
    '/home/wallet/add-bank-details': 'Add Bank Details',
    '/home/wallet/stake': 'Stake',
    '/home/wallet/explorer': 'My Explorer',
    '/home/explorer/overview': 'My Explorer',
    '/home/explorer/explorer-devices': 'My Explorer',
    '/home/explorer/claim-history': 'My Explorer',
    '/home/explorer/hotspot-hubs': 'My Explorer',
    '/home/explorer/devices-name/:tokenId/statistics': 'Hub Name',
    '/home/explorer/devices-name/:tokenId/transactions': 'Hub Name',
    '/home/profile/info': 'Profile',
    '/home/profile/edit-contact': 'Edit Contact Number',
    '/home/profile/verifyID': 'Verify Identity',
    '/home/profile/security/2fa': '2 Factor Authenticator',
    '/home/profile/security/2fa-code': '2 Factor Authenticator',
    '/home/profile/security/reset-password': 'Login Pin',
    '/home/profile/security': 'Profile',
    '/home/token': 'Token',
    '/home/vouchers': 'Voucher',
    '/home/agents': 'Agents',
    '/home/analytics': 'Analytics',
    '/home/cluster': 'My Cluster',
    '/home/referrals': 'Referrals',
    '/home/parental-control': 'Parental Control',
    '/home/parental-control/:deviceId/:deviceName': 'Device Name',
    '/home/parental-control/:deviceId/:deviceName/:category/:categoryId': 'Category',
    '/home/advert-control/overview': 'Advert Control',
    '/home/advert-control/share': 'Advert Control',
    '/home/advert-control/caption': 'Advert Control',
    '/home/subscription': 'Subscription',
    '/home/hub-form': 'Hub Form',
    '/home/hub-form/form-content/:formName/:?formId': 'Network Feedback Form',
    '/home/hub-settings': 'Network Feedback Form',
    '/home/hub-form/form-template': 'Hub Templates',
  };

  const defaultTitle = 'Dashboard';
  //const title = titleMap[location.pathname] || defaultTitle;

  // Regular expression to match dynamic route parameters
  const agentRoutePattern = /^\/home\/agents\/[\w-/%]+$/;
  const analyticRoutePattern = /^\/home\/analytics\/[\w-/%]+$/;
  const sessionRoutePattern = /^\/home\/explorer\/session-name\/[\w-/%]+$/;
  const boostRoutePattern = /^\/home\/booster\/history\/[\w-/%]+$/;
  const subscriptionRoutePattern = /^\/home\/subscription\/[\w-/%]+\/[\w-/%]+$/;
  const categoryRoutePattern = /^\/home\/parental-control\/[\w-/%]+\/[\w-.%]+\/[\w-.%]+\/[\w-/%]+$/;
  const trafficCategoryRoutePattern = /^\/home\/analytics\/traffic\/[\w-.%]+\/[\w-.%]+\/[\w-/%]+$/;
  const connectionSessionRoutePattern = /^\/home\/analytics\/connections\/[\w-.%]+\/[\w-.%]+\/[\w-/%]+\/[\w-/%]+$/;

  // const statisticsRoutePattern =
  //   /^\/home\/explorer\/devices-name\/[\w-/%]+\/(?:statistics|transactions)$/;

  // Function to get the title based on the route
  const getTitleFromRoute = (route: string): string => {
    if (titleMap[route]) {
      return titleMap[route] as string;
    } else if (agentRoutePattern.test(route)) {
      return agentName as string; // Or any other dynamic title you want for the matched pattern
    } else if (trafficCategoryRoutePattern.test(route)) {
      return trafficCategory as string;
    } else if (connectionSessionRoutePattern.test(route)) {
      return connectedUser as string;
    } else if (analyticRoutePattern.test(route)) {
      // let analyticName = new URLSearchParams(window.location.search).get(
      //   'analyticName'
      // );
      return analyticName as string;
    } else if (sessionRoutePattern.test(route)) {
      return sessionId?.slice(0, 4) + '...' + sessionId?.slice(-4);
    } else if (boostRoutePattern.test(route)) {
      return 'Boost Reward';
    } else if (categoryRoutePattern.test(route)) {
      return category ? category : "Category";
    } else if (deviceId && deviceName) {
      return deviceName;
    } else if (formName) {
      return formName;
    } else if (subscriptionRoutePattern.test(route)) {
      return packageDetail?.name ? `${packageDetail?.name} Plan` : "Subscription Plan";
    }
    return defaultTitle;
  };

  let title = getTitleFromRoute(location.pathname);

  const isSpecificPath =
    location.pathname === '/home/hotspot-hubs/session-history' ||
    location.pathname === '/home/hotspot-hubs/device-tracker' ||
    location.pathname === '/home/wallet/transactionHistory' ||
    location.pathname === '/home/wallet/peer-to-peer' ||
    location.pathname === '/home/wallet/withdraw-P2P' ||
    location.pathname === '/home/wallet/crypto-withdraw' ||
    location.pathname === '/home/wallet/add-bank-details' ||
    location.pathname === '/home/wallet/bank-transfer' ||
    location.pathname === '/home/wallet/ussd' ||
    location.pathname === '/home/wallet/withdrawal-bank-transfer' ||
    location.pathname === '/home/wallet/user' ||
    location.pathname === '/home/wallet/stake' ||
    location.pathname === '/home/hotspot-hubs/session-history' ||
    location.pathname === '/home/hotspot-hubs/device-tracker' ||
    location.pathname === '/home/wallet/transactionHistory' ||
    location.pathname === '/home/wallet/peer-to-peer' ||
    location.pathname === '/home/wallet/add-bank-details' ||
    decodeURI(location.pathname) ===
      `/home/explorer/session-name/${sessionId}` ||
    decodeURI(location.pathname) ===
      `/home/explorer/devices-name/${tokenId}/statistics` ||
    decodeURI(location.pathname) ===
      `/home/explorer/devices-name/${tokenId}/transactions` ||
    location.pathname === '/home/booster/booster-faq' ||
    location.pathname === '/home/profile/verifyID' ||
    location.pathname === '/home/profile/edit-contact' ||
    location.pathname === '/home/profile/security/2fa-code' ||
    location.pathname === '/home/profile/security/2fa' ||
    location.pathname === '/home/profile/security/reset-password' ||
    location.pathname === '/home/dashboard/business-reg' ||
    decodeURI(location.pathname) ===
      `/home/agents/${businessUserId}/${agentName}/${businessId}` ||
    decodeURI(location.pathname) === `/home/analytics/connections` ||
    location.pathname === '/home/subscription-plan' ||
    location.pathname === `/home/hub-form/detail/${formId}` ||
    location.pathname === `/home/hub-form/detail/responses/${formId}` ||
    location.pathname === '/home/hub-setting' ||
    location.pathname === '/home/profile/verifyID' ||
    location.pathname === '/home/dashboard/business-reg' ||
    decodeURI(location.pathname) ===
      `/home/agents/${businessUserId}/${agentName}/${businessId}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/connections/${analyticId}/${analyticName}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/uptime/${analyticId}/${analyticName}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/data-usage/${analyticId}/${analyticName}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/speed/${analyticId}/${analyticName}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/traffic/${analyticId}/${analyticName}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/traffic/${trafficId}/${analyticName}/${trafficCategory}` ||
    decodeURI(location.pathname) ===
      `/home/analytics/connections/${analyticId}/${analyticName}/${connectedUserId}/${connectedUser}` ||
    location.pathname === `/home/subscription/${planId}/${billingType}` ||
    location.pathname === `/home/hub-form/detail/${formId}` ||
    decodeURI(location.pathname) === `/home/hub-form/form-content/${formName}` ||
    decodeURI(location.pathname) === `/home/hub-form/form-content/${formName}/${formId}` ||
    location.pathname === `/home/hub-form/detail/responses/${formId}` ||
    location.pathname === `/home/parental-control/${deviceId}/${deviceName}` ||
    decodeURI(location.pathname) === `/home/parental-control/${deviceId}/${deviceName}/${category}/${categoryId}` ||
    location.pathname === '/home/hub-setting' ||
    location.pathname === '/home/hub-form/form-template' ||
    location.pathname === '/home/dashboard/hub-setup';

  const navigate = useNavigate();

  const {
    data: businessProfile,
  } = useGetBusinessProfileQuery();


  return (
    <div className="flex items-center p-5 bg-white dark:bg-clrDarkBg md:px-10 md:py-6">
      <div className="flex gap-4 mr-auto">
        <button onClick={handleMenu} className="block lg:hidden">
          <Menu2 className="dark-menu" />
        </button>
        <div className="flex items-center gap-2">
          {isSpecificPath && (
            <button className="btn-reset" onClick={() => navigate(-1)}>
              <ArrowBack className="w-5 h-5 dark-arrow" />
            </button>
          )}
          <h1 className="text-sm font-semibold md:text-base text-clrTitle dark:text-white">
            {title}
          </h1>
        </div>
      </div>
      <div className="items-center hidden gap-4 md:flex">
        <div className="relative">
          <button
            className="flex items-center gap-2 px-2 py-1 rounded-lg h-fit bg-clrBtnGray dark:bg-clrDarkBg2"
            onClick={() => setShowSupport(!showSupport)}
          >
            {/* <img src={Phone} alt="Call" width={15} height={15} /> */}
            <Phone2 className="w-[0.9375rem] h-[0.9375rem] dark-phone" />
            <p className="text-[0.625rem] font-normal text-[#515151] dark:text-clrGray tracking-[0.01em]">
              Support
            </p>
            <img src={ArrowYellow} alt="Arrow down" width={20} height={20} />
          </button>
          {showSupport && <CustomerSupport modalRef={modalRef} />}
        </div>
        <img
          src={businessProfile ? businessProfile?.data?.profileUrl : Profile}
          alt="Profile"
          width={40}
          height={40}
          className="w-10 rounded-full pic-aspect"
        />
        <div className="relative">
          <button
            onClick={() => setShowProfile(!showProfile)}
            className="mt-[5px]"
          >
            <img src={ArrowYellow} alt="Arrow down" width={20} height={20} />
          </button>
          {showProfile && <TopRightMenu modalRef={modalRefProfile} />}
        </div>
      </div>
      {appModal?.id === 'routerCode' && <RouterLogin />}
    </div>
  );
};

export default Notify;
