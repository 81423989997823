import { createColumnHelper } from "@tanstack/react-table";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useRemoveBlacklistedAddressMutation } from "src/features/api/parentalControlSlice";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import { ReactComponent as Delete2 } from "../../assets/delete2.svg";
import { ChooseModal } from "../reusables/Modal";
import { SuccessModal } from "../reusables/SuccessModal";

//Parental Control Seup
type parentalControl = {
    platform: string;
    address: string;
    id: string;
    addressType: string;
};

const RenderDelete = ({id, platform} : {id: string, platform: string} ) => {
  const [isDelete, toggleDelete] = useState<boolean>(false);
  const { deviceId } = useParams();
  const [errMsg, setErr] = useState<string>("");
  const [trigger, {isLoading}] = useRemoveBlacklistedAddressMutation();
  const [successStatus, setSuccessStatus] = useState<boolean>(false);

  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, ()=> toggleDelete(!isDelete));

  const handleDelete = () =>{
    let data = {
      deviceId: deviceId as string, 
      blacklistAddressId: id
    }
      trigger(data)
      .unwrap()
      .then((res)=>{
        console.log(res, 'i am res')
        setSuccessStatus(true)
      })
      .catch((err)=> {
        setErr(err?.data?.message)
        console.log(err, "i am the err")
      })
  }

  return(
    <div>
      <div onClick={()=> toggleDelete(!isDelete)}  className="flex items-center align-center gap-2 cursor-pointer">
        <Delete className="h-5"/>
        <p> Remove</p>
      </div>
      {isDelete && (

          <ChooseModal
            title={`Remove ${platform}`}
            description={`You are about to remove this platform from the restriction list`}
            handleButton={handleDelete}
            icon={<Delete2 />} 
            handleModal={() => toggleDelete(!isDelete)}   
            isLoading={isLoading}    
            buttonTitle="Remove"
            btnColor="bg-[#DB5F5F]"
            errMsg={errMsg}
          />
        
        )}
         {successStatus && (
                    <SuccessModal
                        title={"Address Blacklisted"}
                        btnText="Proceed"
                        description={`You just blacklisted the platform `}
                        handleModal={() => setSuccessStatus(false)}
                    />
                )}
    </div>
    
  )
}

// Control History
const parentalControlColumns = createColumnHelper<parentalControl>();

export const parentalControlColumn = [
  parentalControlColumns.accessor("platform", {
    header: "Platform",
  }),
  parentalControlColumns.accessor("address", {
    header: "URL Address",
  }),
  parentalControlColumns.accessor('id', {
    header: 'Actions',
    cell: (info) => RenderDelete(
                    {
                      id: info?.row?.original?.id, 
                      platform: info?.row?.original?.platform
                    })
  }),
];


