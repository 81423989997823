import { copyToClipboard } from "../../helper";
import { useState } from "react";
import { Button, ButtonOutline } from "../reusables";
import { ReactComponent as CopyLink } from "../../assets/dashboard/copy-link.svg";
import { ReactComponent as LinkHub } from "../../assets/dashboard/link-hub.svg";
import { ReactComponent as CompleteIcon } from "../../assets/dashboard/complete.svg";
import { ReactComponent as ArrowUp } from "../../assets/dashboard/arrow-up.svg";
import { useNavigate } from "react-router-dom";
import { useLinkCodeQuery } from "src/features/api/homeSlice";
import LinkCodeDisplay from "./LinkCode";
import { handleCopyClick as copyLinkCode } from "../../helper"

const HubSetup = () => {
  const navigate = useNavigate();
  const [copiedText, setCopiedText] = useState<string>('');

  const [step, setStep] = useState<number>(1);
  const [highestStep, setHighestStep] = useState<number>(1);
  const {data: linkCode, isLoading: gettingLinkCode} = useLinkCodeQuery();

  const handleFinishClick = () => {
    navigate("/home/dashboard");
  };

  const handleProceedClick = () => {
    const nextStep = 2;
    setStep(nextStep);
    setHighestStep(Math.max(highestStep, nextStep));
  };

  const handleNextClick = () => {
    const nextStep = step + 1;
    setStep(nextStep);
    setHighestStep(Math.max(highestStep, nextStep));
  };

  const handlePreviousClick = () => {
    const prevStep = step - 1;
    setStep(prevStep);
  };

  const handleCopyClick = () => {
    copyLinkCode(linkCode?.data?.linkCode, copyToClipboard, setCopiedText);
  };

  return (
    <section>
      <div className=" bg-clrBg w-[96%] mx-auto mt-3 dark:bg-clrDarkBg2 overflow-y-auto">
        <div className="p-4 sm:p-6 pb-8 my-3 bg-white dark:bg-clrDarkBg rounded-xl">
          <div className="flex items-center justify-between mb-8">
            <div className="">
              <p className="text-base font-semibold text-primary md:text-lg">Hello there,</p>
              <p className="text-clrGray text-xs">Complete your hub setup in just a few simple steps.</p>
            </div>
          </div>

          <section> 
            <p className="mb-2 text-sm font-semibold text-clrGray"> Setup Actions</p>
            <div className="flex flex-col justify-center w-full mx-auto gap-11 mt-11 sm:px-4 xl:px-14">
              <div className="grid grid-cols-layout gap-y-6 justify-items-center sm:flex items-center justify-center w-full mx-auto gap-2 sm:gap-4 md:gap-5 xl:gap-9">
                <div className="grid gap-3 place-items-center">
                  <div className="grid place-content-center border border-solid border-primary h-11 w-11 sm:h-20 sm:w-20 rounded-full bg-[#FDF8E8]">
                    <CopyLink className={`w-6 h-6 sm:w-10 sm:h-10 ${step >= 1 ? "set-fill" : ""}`}/>
                  </div>
                  <p className="text-x10 text-clrGray md:text-sm">Copy Link Code</p>
                </div>
                
                <div className={`border border-solid h-[3px] w-14 md:w-[100px] rounded ${step >= 2 ? "border-primary" : "border-[#C4C4C499]"}`}></div>

                <div className="grid gap-3 place-items-center">
                  <div className={`grid place-content-center border border-solid h-11 w-11 sm:h-20 sm:w-20 rounded-full ${step >= 2 ? 'bg-[#FDF8E8] border-primary' : 'border-[#C4C4C499] bg-transparent bg-opacity-100'}`}>
                    <LinkHub className={`w-6 h-6 sm:w-10 sm:h-10 ${step >= 2 ? "set-fill" : ""}`}/>
                  </div>
                  <p className="text-x10 text-clrGray md:text-sm">Link your Hub</p>
                </div>

                <div className={`hidden sm:block border border-solid h-[3px] w-14 md:w-[100px] rounded ${step >= 3 ? "border-primary" : "border-[#C4C4C499]"}`}></div>

                <div className="grid gap-3 place-items-center col-start-2 md:col-span-1">
                  <div className={`grid place-content-center border border-solid h-11 w-11 sm:h-20 sm:w-20 rounded-full ${step >= 3 ? 'bg-[#FDF8E8] border-primary' : 'border-[#C4C4C499] bg-transparent bg-opacity-100'}`}>
                    <CompleteIcon className={`w-6 h-6 sm:w-10 sm:h-10 ${step >= 3 ? "set-fill" : ""}`}/>
                  </div>
                  <p className="text-x10 text-clrGray md:text-sm">Complete </p>
                </div>
              </div>
              <div className="bg-[#FFCB0505] dark:bg-clrDarkBg border border-solid border-[#FFCB051A] rounded-lg w-full sm:w-4/5 xl:w-[700px] mx-auto">
                {step === 1 && <div className="flex flex-col gap-2 py-2">
                  <p className="text-x10 md:text-xs font-normal text-clrGray text-center dark:text-clrPlaceholder whitespace-break-spaces">
                    Copy the link below then proceed
                  </p>
                  <LinkCodeDisplay
                    linkCode={linkCode?.data?.linkCode}
                    gettingLinkCode={gettingLinkCode}
                    copiedText={copiedText}
                    handleCopyClick={handleCopyClick}
                    textSize={"text-sm"}
                  />
                  
                </div>}
                {step === 2 && <div className="flex flex-col items-center gap-2 py-2">
                  <p className="text-x10 md:text-xs font-normal text-clrGray text-center dark:text-clrPlaceholder whitespace-break-spaces">
                    Connect your hub by pasting the link code.
                  </p>
                  <button className="flex gap-1.5 items-center">
                    <a target="_blank"  rel="noreferrer" href="http://192.168.0.1:8080/#/myhub" className="flex gap-2 font-normal text-x10 text-primary md:text-xs underline underline-offset-2">Click here
                    <div className="grid w-4 h-4 rounded-full bg-primary bg-opacity-40 place-content-center">
                      <ArrowUp className="w-2 h-2" />
                    </div>
                    </a>
                  </button>
                </div>}
                
                {step === 3 && <div className="flex flex-col items-center gap-2 py-2">
                  <p className="text-x10 md:text-xs font-normal text-clrGray text-center dark:text-clrPlaceholder whitespace-break-spaces">
                    Now you are set. <br /> Proceed to your dashboard and enjoy our services
                  </p>              
                </div>}
              </div>
              <div className="flex justify-center gap-3">
                  {step === 1 && (
                    <Button
                      handleClick={handleProceedClick}
                      text="Proceed"
                      type="button"
                      fontWeight="font-normal"
                      wBtn="!w-fit !mx-0"
                      textSize="text-xs"
                      px="px-5"
                    />
                  )}
                  {step !== 1 && (
                    <ButtonOutline
                      type="button"
                      text={"Previous"}
                      handleClick={handlePreviousClick}
                      wBtn="!w-fit !mx-0 !px-5"
                      fontWeight="!font-normal"
                      borderWidth="border"
                    />
                  )}
                  {(step === 2) && (
                    <Button
                      handleClick={handleNextClick}
                      text={"Next"}
                      type="button"
                      fontWeight="font-normal"
                      wBtn="!w-fit !mx-0"
                      textSize="text-xs"
                      px="px-5"
                    />
                  )}
                   {step === 3 && (
                      <Button
                        handleClick={handleFinishClick}
                        text={"Finish"}
                        type="button"
                        fontWeight="font-normal"
                        wBtn="!w-fit !mx-0"
                        textSize="text-xs"
                        px="px-5"
                      />
                    )}
              </div>
            </div>
          </section>
          
        </div>
      </div>
    </section>

  );
};

export default HubSetup;
