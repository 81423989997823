import { useState } from 'react';
import { Button, EmptyCard, TableLoader } from '../reusables';
import {
  useGetDevicesQuery,
  useToggleAdSupportMutation,
} from 'src/features/api/advertSlice';
import { ChooseModal } from '../reusables/Modal';
import { ReactComponent as OnlineIcon } from '../../assets/icons/hotspot-hubs/hubs-with-ads.svg';
import { ReactComponent as OnlineRedIcon } from '../../assets/icons/hotspot-hubs/online-with-bg-red.svg';

interface HubsProps {
  id: number;
  business_id: number;
  network_id: number;
  mac_address: string;
  bss_id: string;
  name: string;
  country_id: number;
  is_enabled: boolean;
  created_at: string;
  updated_at: string;
  business: any;
  network: any;
  country: any;
  device_stats: any[];
  transactions: any[];
}

const Share = () => {

  // const [hubName, saveHubName] = useState('');
  const [btnLoading, setBtnLoading] = useState('');
  const [deactivate, setDeactivate] = useState(false);
  const [activate, setActivate] = useState(false);
  const [errMsg, setErr] = useState<string>("");
  const [selectedDevice, setSelectedDevice] = useState<HubsProps | null>(null);

  const [page] = useState<number>(1);

  const [toggleAdSupport, { isLoading: toggleAdSupportLoading }] =
    useToggleAdSupportMutation();

  const { data, isLoading: gettingAllHubs } = useGetDevicesQuery({
    // deviceName: hubName,
    deviceStatus: '',
    page: page,
    pageSize: 30,
  });

  const hubs: any = data?.data || [];

  const handleToggle = async (device: HubsProps) => {
    setBtnLoading(device?.bss_id);
    console.log(device?.bss_id)
    const payload = {
      // hubId: device?.id,
      // isAdSupportEnabled: !device?.isAdsEnabled,
      connectionId: device?.bss_id,
    };
    try {
      const response = await toggleAdSupport(payload).unwrap();
      if (response?.success) {
        setActivate(false);
        setDeactivate(false);
      }
    } catch (error: any) {
        setErr( error?.data?.message ? error?.data?.message : "Something went wrong");
    }
  };

  const openActivateModal = (device: HubsProps) => {
    setSelectedDevice(device);
    if(device.is_enabled) {
      setDeactivate(true);
    } else {
      setActivate(true);
    }
  };

  return (
    <section className="w-[96%] mx-auto mt-3.5 md:mt-0 mb-6 h-full">
      <div className="px-4 pt-2.5 pb-20 mb-2 bg-white dark:bg-clrDarkBg rounded-lg md:px-10">
        <div>
          {/* <div className="flex flex-wrap w-full gap-x-3 gap-y-7 md:w-auto mt-7">
            <div className="flex-1 md:flex-auto ">
              <label
                htmlFor="hubname"
                className="hidden mb-2 font-semibold md:block text-x10 md:text-x11 text-clrGray dark:text-clrPlaceholder"
              >
                Hub Name
              </label>
              <input
                type="text"
                name="hubname"
                id=""
                placeholder="Search Name"
                value={hubName}
                onChange={(e) => saveHubName(e.target.value)}
                className="w-full md:w-52 text-xs font-medium text-clrGray border-[1px] border-solid border-clrPlaceholder dark:border-clrGray rounded-3xl h-7 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
              />
            </div>
          </div> */}
          {gettingAllHubs ? (
            <TableLoader />
          ) : (
            <div className="mt-4">
              <h3 className="text-xs font-semibold text-black md:text-sm dark:text-clrPlaceholder">
                Advert Share{' '}
              </h3>
              <p className="text-x10 text-clrGray dark:text-clrPlaceholder">
                Enable or disable ad sharing on available hubs{' '}
              </p>

              <aside className="grid grid-cols-1 gap-3 pb-10 mb-5 bg-white rounded-lg mt-7 dark:bg-clrDarkBg md:grid-cols-3 md:gap-5">
                {hubs?.map((device: HubsProps, index: number) => {
                  return (
                    <Hubs
                      key={index}
                      device={device as any}
                      handleToggle={() => openActivateModal(device)}
                      toggleAdSupportLoading={toggleAdSupportLoading}
                      btnLoading={btnLoading}
                    />
                  );
                })}
              </aside>
              {!gettingAllHubs && !hubs?.length && (
                <EmptyCard description={'No Device Found'} />
              )}
            </div>
          )}
        </div>
      </div>
      {deactivate && selectedDevice && (
        <ChooseModal
          title={`Deactivate Ads Share`}
          description={`Adverts will be deactivated on this hub and you will be unable to earn the ads revenue`} 
          handleButton={() => handleToggle(selectedDevice)}
          icon={<OnlineRedIcon />}
          handleModal={() => {
            setDeactivate(!deactivate);
            setErr("");
          }}   
          isLoading={toggleAdSupportLoading}    
          buttonTitle="Deactivate"
          btnColor="bg-clrRed3"
          errMsg={errMsg}
          close
        />
      )}

      {activate && selectedDevice && (
        <ChooseModal
          title={`Ads Share Activated`}
          description={`Your hub will show adverts and will be eligible for the ads revenue`} 
          handleButton={() => handleToggle(selectedDevice)}
          icon={<OnlineIcon />} 
          handleModal={() => {
            setActivate(!activate); 
            setErr("");
          }}    
          isLoading={toggleAdSupportLoading}    
          buttonTitle="Proceed"
          errMsg={errMsg}
          close
        />
      )}
    </section>
  );
};

const Hubs = ({
  device,
  handleToggle,
  toggleAdSupportLoading,
  btnLoading,
}: {
  device: HubsProps;
  handleToggle: any;
  toggleAdSupportLoading: boolean;
  btnLoading: string;
}) => {
  return (
    <div className="grid grid-cols-3 gap-3 md:gap-4 bg-clrBg border border-solid border-[#C4C4C466] dark:bg-clrDarkBg2 rounded-lg p-2 md:p-4 shadow-wxl">
      {/* <div className='bg-primary/5 border border-solid border-[#6B6B6B1A] rounded'> */}
        <img
          src={"https://res.cloudinary.com/chukwuemekanweke/image/upload/v1714485796/wicrypt_device_purchase/MTN5GRouter.jpg"}
          alt={device?.name}
          className="object-cover rounded "
        />
      {/* </div> */}
      <div className="flex flex-col justify-between flex-1 h-full col-span-2">
        <div className="flex items-center justify-between pt-1 lg:pt-1.5">
          <p className="font-medium md:font-semibold text-x10 md:text-xs text-clrTextGray dark:text-white">
            {device.name?.length > 25
              ? device.name?.slice(0, 25) + '...'
              : device.name}
          </p>
        </div>
        <div className="justify-items-end md:w-[80%] mt-3 pb-1 lg:pb-1.5">
          {device.is_enabled ? (
            <Button
              text="Deactivate Ads Share"
              handleClick={() => handleToggle(device)}
              type="button"
              wBtn="w-fit"
              fontWeight="font-normal"
              py="py-1"
              textSize="text-x8"
              disabled={
                btnLoading === device?.bss_id && toggleAdSupportLoading
              }
              loading={
                btnLoading === device?.bss_id && toggleAdSupportLoading
              }
              bgColor="bg-clrRed3"
            />
          ) : (
            <Button
              handleClick={() => handleToggle(device)}
              text="Activate Ads Share"
              type="button"
              fontWeight="font-normal"
              py="py-1"
              wBtn="w-fit"
              textSize="text-x8"
              disabled={
                btnLoading === device?.bss_id && toggleAdSupportLoading
              }
              loading={
                btnLoading === device?.bss_id && toggleAdSupportLoading
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Share;
