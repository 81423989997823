import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { logOut } from "../../features/store/authReducerSlice";

const LogOut = () => {
    const dispatch = useAppDispatch();
    useEffect(()=>{
        dispatch(logOut());
    }, [])
  return <div />;
};

export default LogOut;
