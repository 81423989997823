import { useRef, useState } from 'react';
import ArrowRightYellow from '../../../assets/icons/arrow-right-yellow.svg';
import ArrowRightGray from '../../../assets/icons/wallet/arrow-right-gray.svg';
import { useNavigate } from 'react-router-dom';
import SubscriptionEnterPin from 'src/components/reusables/EnterPin';
import SubscriptionVerifyEmail from 'src/components/reusables/VerifyPin';
import { Button, ButtonOutlineWallet } from 'src/components/reusables';
import useOutsideClick from 'src/hooks/useOutsideClick';
import P2PAmount from '../P2PAmount';
import { useInitiateOrderMutation } from 'src/features/api/walletSlice';
import { useAppSelector } from 'src/app/hooks';
import { WithdrawVerifyEmail } from '../WithdrawInfo';
import AccountInfo from './AccountInfo';
import Appeal from '../Appeal';
import { ReactComponent as MessageIcon } from '../../../assets/icons/wallet/message.svg';
import ArrowDown from '../../../assets/icons/arrow-down-Yellow.svg';

const USSDAmount = () => {
  const [step, setStep] = useState<number>(1);
  const [isEnterPin, setIsEnterPin] = useState(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);

  const modalRefEnterPin = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsEnterPin(false);
    setIsVerifyEmail(false);
  };

  useOutsideClick(modalRefEnterPin, handleCloseModal);

  // const navigate = useNavigate();
  const { userId } = useAppSelector((state) => state.auth);

  //inbound currency: This is the currecy the user receives
  //outbound currency: This is the currency the user sends
  const {
    selectedCurrency: outboundCurrency,
    outboundCurrency: inboundCurrency,
  } = useAppSelector((state) => state.wallet);
  //Amount the user sends
  const [inBoundValue, setInboundValue] = useState<string>('');
  //Amount the user receiives
  const [outBoundValue, setOutBoundValue] = useState<string>('');

  //Conditionally render withdrawal OTP
  const [withdrawalOTP, setWithdrawalOTP] = useState<boolean>(false);

  const [otp, setOtp] = useState<string>('');

  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [errMsg, setErr] = useState<string>('');

  const [initateOrder, { isLoading: initiatigOrder }] =
    useInitiateOrderMutation();
  // const pendingOrder = {}

  const handleAmountConfirm = () => {
    setStep(2);
  };

  const handleOtp = (res: string) => {
    setOtp(res);
  };

  const handleSubmit = () => {
    if (otp.length !== 6) {
      setErr('Please enter the OTP that was sent to your email account');
    } else {
      setErr('');
      setWithdrawalOTP(false);
      let data = {
        payload: {
          inboundCurrencyId: inboundCurrency.id,
          outboundCurrencyId: outboundCurrency.id,
          inboundAmount: Number(inBoundValue),
          outboundAmount: Number(outBoundValue),
          bankRecordId: selectedBank?.id + '',
          phoneNumber: phoneNumber,
          otp: otp,
          pin: '',
        },
        orderType: 'Sell',
        userId: userId,
      };
      initateOrder(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            setStep(3);
          } else {
            setErr(res?.message);
          }
        })
        .catch((error) => setErr(error?.data?.message));
    }
  };
  //If user confirms, send OTP
  const handleSendOTP = () => {
    setErr('');
    setWithdrawalOTP(true);
  };

  //Function to handle cryptowithdrawal step flor
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="w-[91%] max-w-[36rem] mx-auto">
            <P2PAmount
              handleAmountConfirm={handleAmountConfirm}
              setOutBoundValue={setOutBoundValue}
              outBoundValue={Number(outBoundValue)}
              inBoundValue={Number(inBoundValue)}
              setInboundValue={setInboundValue}
              setSelectedBank={setSelectedBank}
              selectedBank={selectedBank}
              setPhoneNumber={setPhoneNumber}
              phoneNumber={phoneNumber}
              type={'FUND'}
              paymentMethod={'USSD'}
              inboundCurrency={inboundCurrency}
              outboundCurrency={outboundCurrency}
            />{' '}
          </div>
        );

      case 2:
        return (
          <div className="w-[91%] max-w-[36rem] mx-auto">
            <AccountInfo handleSuccess={() => setStep(3)} data={{} as any} />
          </div>
        );
      case 3:
        return <SuccessPlan />;
      default:
        return;
    }
  };

  return (
    <section className="bg-clrBg dark:bg-clrDarkBg2  pb-6 overflow-y-auto">
      <div className="flex items-center justify-between px-8 my-5 w-[91%] max-w-[36rem] mx-auto ">
        <div
          className="flex items-center justify-between cursor-pointer gap-x-4 min-[325px]:gap-x-8 sm:gap-x-11 md:gap-x-[5.75rem]"
          onClick={() => step > 1 && setStep(1)}
        >
          <h3
            className={`font-medium text-x10  ${
              step === 1
                ? 'text-[#1a1a1a] dark:text-white'
                : 'text-clrGray dark:text-clrPlaceholder'
            }`}
          >
            Amount
          </h3>
          <img
            src={step >= 1 ? ArrowRightYellow : ArrowRightGray}
            alt="more options"
            width={20}
            height={20}
          />
        </div>
        <div
          className="flex items-center justify-between cursor-pointer gap-x-4 min-[325px]:gap-x-8 sm:gap-x-11 md:gap-x-[5.75rem]"
          onClick={() => step > 2 && setStep(2)}
        >
          <h3
            className={`font-medium text-x10  ${
              step === 2
                ? 'text-[#1a1a1a] dark:text-white'
                : 'text-clrGray dark:text-clrPlaceholder'
            }`}
          >
            USSD Code
          </h3>
          <img
            src={step >= 2 ? ArrowRightYellow : ArrowRightGray}
            alt="more options"
            width={20}
            height={20}
          />
        </div>
        <div className="flex items-center justify-between cursor-pointer gap-x-3">
          <h3
            className={`font-medium text-x10  ${
              step === 3
                ? 'text-[#1a1a1a] dark:text-white'
                : 'text-clrGray dark:text-clrPlaceholder'
            }`}
          >
            Success
          </h3>
        </div>
      </div>

      {renderStep()}

      {isEnterPin && (
        <SubscriptionEnterPin
          handleChange={() => {}}
          handleContinue={() => {
            setIsVerifyEmail(true);
            setIsEnterPin(false);
          }}
          handleModal={handleCloseModal}
          errMsg=""
        />
      )}

      {isVerifyEmail && (
        <SubscriptionVerifyEmail
          handleChange={() => {}}
          handleContinue={() => {
            setIsVerifyEmail(false);
            setStep(2);
          }}
          handleModal={handleCloseModal}
          errMsg=""
          loading={false}
          resendOtp={() => {}}
        />
      )}

      {withdrawalOTP && (
        <WithdrawVerifyEmail
          handleModal={() => setWithdrawalOTP(false)}
          handleContinue={handleSubmit}
          handleChange={handleOtp}
          errMsg={errMsg}
          resendOtp={handleSendOTP}
          loading={initiatigOrder}
        />
      )}
    </section>
  );
};

export default USSDAmount;

const SuccessPlan = () => {
  const navigate = useNavigate();

  const [isAppeal, setIsAppeal] = useState(false);

  const AppealRef = useRef<HTMLElement>(null);

  const handleBackToWallet = () => {
    navigate('/home/wallet');
  };

  const handleCloseModal = () => {
    setIsAppeal(false);
  };

  useOutsideClick(AppealRef, handleCloseModal);

  return (
    <section className="flex flex-col bg-white rounded-xl w-[96%] md:w-[705px] mx-auto p-5 md:px-[2.625rem] md:py-10">
      <div className="bg-clrYellow2 bg-opacity-[0.02] rounded-2xl border border-clrYellow2/10 border-solid p-5 md:py-7 md:px-7">
        <div className="grid gap-2">
          <h4 className="text-xl font-semibold text-clrGreen2">Success</h4>
          <p className="text-3xl font-semibold text-[#000]">1000 NGN</p>
          <p className="text-sm font-normal text-[#525252]">
            We will confirm the receipts of Fund, and send the money to your
            balance within the next 30 minutes.{' '}
          </p>
        </div>
        <div className="mt-9">
          <h5 className="text-base font-semibold text-[#525252] mb-4">
            Transaction Details
          </h5>
          <div className="grid gap-4">
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">Date</span>
              <p className="flex flex-col text-xs font-semibold text-black">
                <span className="self-end"> 13:14:08 </span>
                <span>12-10-2021</span>
              </p>
            </p>
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">
                Currency
              </span>
              <span className="text-xs font-semibold text-black">Naira</span>
            </p>{' '}
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">Amount</span>
              <span className="text-xs font-semibold text-black">1000 NGN</span>
            </p>
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">
                Payment method
              </span>
              <span className="text-xs font-semibold text-black">USSD</span>
            </p>
            <p className="flex flex-wrap items-center justify-between">
              <span className="text-sm font-normal text-[#525252]">
                Transaction Reference
              </span>
              <span className="text-xs font-semibold text-black">
                {' '}
                176541765
              </span>
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2  items-center mt-8 mx-auto gap-4">
          <Button text="Done" type="button" handleClick={handleBackToWallet} />

          <ButtonOutlineWallet
            text="Appeal"
            type="button"
            handleClick={() => setIsAppeal(true)}
            showIcon={true}
            showIconEnd={true}
            btnIcon={<MessageIcon />}
            btnIconEnd={ArrowDown}
            imgWidth="15px"
            imgHeight="15px"
            wBtn="w-full"
            py="md:py-2"
            textColor="text-[#1D1D1D]"
            borderColor="border-[#1D1D1D]"
          />
        </div>
      </div>

      {isAppeal && (
        <Appeal
          modalRef={AppealRef}
          handleModal={handleCloseModal}
          data={{} as any}
        />
      )}
    </section>
  );
};
