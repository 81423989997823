import { useParams } from 'react-router-dom';
import SecondaryNav from '../reusables/SecondaryNav';

const AnalyticsDetails = () => {
  const { analyticName, analyticId } = useParams();
  const encodedAnalyticName = encodeURIComponent(analyticName as string);

  return (
    <SecondaryNav
      paths={[
        {
          path: `/home/analytics/connections/${analyticId}/${encodedAnalyticName}`,
          title: 'Connections',
        },
        {
          path: `/home/analytics/uptime/${analyticId}/${encodedAnalyticName}`,
          title: 'Uptime',
        },
        {
          path: `/home/analytics/data-usage/${analyticId}/${encodedAnalyticName}`,
          title: 'Data Usage',
        },
        {
          path: `/home/analytics/speed/${analyticId}/${encodedAnalyticName}`,
          title: 'Speed',
        },
        {
          path: `/home/analytics/traffic/${analyticId}/${encodedAnalyticName}`,
          title: 'Traffic Analytics',
        },
      ]}
    />
  );
};

export default AnalyticsDetails;
