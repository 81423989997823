import { Link, useParams } from "react-router-dom";
import { ReactComponent as ArrowYellow } from "../../assets/icons/arrow-down-Yellow.svg";

/**
 * Represents the props for a dashboard card component.
 */
interface ParentalControlCardProps {
    title: string; // The title of the card.
    noOfAddresses: number; // The description of the card.
    link?: string; // Optional link associated with the card.
    // deviceName: string; // Device name.
}

/**
@component
Creates a parental control card component with optional features 
@param {ParentalControlCardProps} props
* */

export const ParentalControlCategoryCard = ({
    title,
    noOfAddresses,
    link,
}: ParentalControlCardProps) => {
    const {deviceId, deviceName } = useParams();
    return (
        <div className="flex gap-2 md:gap-4 bg-white dark:bg-clrDarkBg  border border-solid border-[#C4C4C433] rounded-[7px] p-3 h-[90px] md:h-[100px]">
            <div className="flex flex-col justify-between mr-auto">
                <div className="flex flex-col gap-2 mt-1.5">
                    <p className="text-x10 md:text-xs font-normal text-[#747474] dark:text-clrPlaceholder whitespace-break-spaces">
                        {title}
                    </p>
                    <p className="text-[#747474] dark:text-clrPlaceholder whitespace-break-spaces">
                        <span className="text-sm md:text-base font-semibold">{noOfAddresses}</span>{" "}
                        <span className="text-x10 md:text-xs font-normal"> Addresses</span>
                    </p>
                </div>
            </div>
            {link && (
                <Link to={`/home/parental-control/${deviceId}/${deviceName}/${title}/${link}`} className="self-center">
                    <ArrowYellow
                        width={"20px"}
                        height={"20px"}
                        className="transform -rotate-90"
                    />
                </Link>
            )}
        </div>
    );
};
