import ArrowDown from '../../assets/icons/arrow-down-Yellow.svg';
import { TableLoader } from '../reusables';
import { useGetSupportedCurrenciesQuery } from '../../features/api/walletSlice';
import { Modal } from '../reusables/Modal';
import { useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router';
import {
  ChooseCurrencyProps,
  ChoosePreferredMethodProps,
  ChooseWithdrawalProps,
} from './types';

/**
 * ChooseCurrency component.
 * Renders a currency selection modal.
 * @param handleModal - Function to handle modal close.
 * @param title - Title of the wallet selection modal.
 * @param description - Description of the wallet selection modal.
 * @param handleSelectedCurrency - Function to handle wallet selection.
 * @param type - Type of the transaction category.
 * @returns React functional component.
 */

export const ChooseCurrency = ({
  handleModal,
  title,
  description,
  handleSelectedCurrency,
  type,
  methods,
}: ChooseCurrencyProps) => {
  const { data: currencies, isLoading: loadingCurrencies } =
    useGetSupportedCurrenciesQuery(type?.toLowerCase() as string);
  const { verificationStatus } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  return (
    <Modal
      close={true}
      title={title}
      description={description}
      handleCloseModal={handleModal}
    >
      <div className="space-y-2">
        {verificationStatus !== 'VERIFIED' ? (
          methods?.map((item: any, i: number) => (
            <button
              onClick={() => navigate(item?.link)}
              key={i}
              className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded "
            >
              <div>
                <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
                  {item?.title}
                </h3>
                <p className="font-medium text-x10 md:text-xs text-clrGray dark:text-clrPlaceholder">
                  {item?.desc}
                </p>
              </div>
              <img
                src={ArrowDown}
                alt="more options"
                width={20}
                height={20}
                className="-rotate-90"
              />
            </button>
          ))
        ) : loadingCurrencies ? (
          <TableLoader />
        ) : (
          currencies?.data?.currencies.map((each) => (
            <button
              key={each.id}
              onClick={() => handleSelectedCurrency(type, each)}
              className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded "
            >
              <div>
                <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
                  {each.code}
                </h3>
                <p className="font-medium text-x10 md:text-xs text-clrGray dark:text-clrPlaceholder">
                  {each.name}
                </p>
              </div>
              <img
                src={ArrowDown}
                alt="more options"
                width={20}
                height={20}
                className="-rotate-90"
              />
            </button>
          ))
        )}
      </div>
    </Modal>
  );
};

/**
 * ChoosePreferredMethod component.
 * Renders a modal for selecting preferred method of transaction.
 * @param handleModal - Function to handle modal close.
 * @param title - Title of the method selection modal.
 * @param description - Description of the method selection modal.
 * @param handleCryptoFunding- Function to handle crypto-funding option.
 * @param handleP2P - Fundtion to handle p2p funding.
 * @returns React functional component.
 */
export const ChoosePreferredMethod = ({
  handleModal,
  handleCloseModal,
  title,
  description,
  handleCryptoFunding,
  handleP2P,
  channels,
  isLoading,
}: ChoosePreferredMethodProps) => {
  return (
    <Modal
      close={true}
      title={title}
      description={`${description} description`}
      handleCloseModal={handleCloseModal}
      goBack={true}
      handleGoBack={handleModal}
    >
      <div className="space-y-2">
        {isLoading && <TableLoader />}
        {!isLoading &&
          channels?.map((channel) => {
            return (
              <button
                onClick={
                  channel?.name === 'Crypto' ? handleCryptoFunding : handleP2P
                }
                className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded "
              >
                <div>
                  <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
                    {channel.name}
                  </h3>
                  <p className="text-x10 md:text-xs font-medium text-clrGray dark:text-clrPlaceholder text-left w-[80%] md:w-full">
                    {channel.description}
                  </p>
                </div>

                <img
                  src={ArrowDown}
                  alt="more options"
                  width={20}
                  height={20}
                  className="-rotate-90"
                />
              </button>
            );
          })}
      </div>
    </Modal>
  );
};

/**
 * ChoosePreferredMethod for withdraw.
 * Renders a modal for selecting preferred method of transaction.
 * @param handleModal - Function to handle modal close.
 * @param title - Title of the method selection modal.
 * @param description - Description of the method selection modal.
 * @param handleCrypto- Function to handle crypto-withdrawal option.
 * @param handleWithdrawP2P - Function to handle p2p withdrawal.
 * @param handleCloseModal - Fundtion to handle close modal.
 * @param channels - List of supported channels for a particular category
 * @param isLoading - boolean to get channels
 * @returns React functional component.
 */
export const ChooseWalletMethod = ({
  handleModal,
  title,
  description,
  handleTransfer,
  handleWithdrawP2P,
  handleCrypto,
  handleCloseModal,
  channels,
  isLoading,
}: ChooseWithdrawalProps) => {
  return (
    <Modal
      close={true}
      title={title}
      description={description}
      handleCloseModal={handleCloseModal}
      goBack={true}
      handleGoBack={handleModal}
    >
      <div className="space-y-2">
        {isLoading && <TableLoader />}
        {!isLoading &&
          channels?.map((channel) => {
            return (
              <button
                onClick={
                  channel.name === 'Peer To Peer'
                    ? handleWithdrawP2P
                    : channel.name === 'Transfer to User'
                    ? handleTransfer
                    : handleCrypto
                }
                className="flex items-center justify-between w-full p-2.5 md:p-4 bg-white dark:bg-clrDarkBg rounded "
              >
                <div>
                  <h3 className="mb-2 text-xs font-semibold text-left text-black dark:text-white md:text-sm">
                    {channel.name}
                  </h3>
                  <p className="text-x10 md:text-xs font-medium text-clrGray dark:text-clrPlaceholder text-left w-[80%] md:w-full">
                    {channel.description}
                  </p>
                </div>

                <img
                  src={ArrowDown}
                  alt="more options"
                  width={20}
                  height={20}
                  className="-rotate-90"
                />
              </button>
            );
          })}
      </div>
    </Modal>
  );
};
