import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardTitle, ButtonAuth, ErrorMsg } from '../../reusables';
import AuthFrame from '../../reusables/AuthFrame';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down-Yellow.svg';
import {
  useGetSupportedCountriesQuery,
  useSignUpMutation,
} from '../../../features/api/authSlice';
import { Country } from '../../../features/api/authSliceTypes';
import { setUser } from '../../../features/store/authReducerSlice';

interface SignUpProps {
  email: string;
  handleBack: () => void;
  handleNext: (title?: string, value?: any) => void;
  firstName: string;
  lastName: string;
  confirmPin: string;
  pin: string;
  otp: string;
  phone: string;
}

//Component to verify that user has received authentication otp
const MoreInformation = ({
  email,
  handleNext,
  handleBack,
  firstName,
  lastName,
  confirmPin,
  pin,
  otp,
  phone,
}: SignUpProps) => {
  const dispatch = useDispatch();
  const [isError, setError] = useState('');
  const [search, setSearch] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [isDropdown, showDropdown] = useState<boolean>(false);

  const { data: countries } = useGetSupportedCountriesQuery({
    page: 1,
    pageSize: 195,
    search,
  });
  const [signUp, { isLoading }] = useSignUpMutation();

  //function to save the otp code sent
  const handleSubmit = () => {
    if (selectedCountry) {
      setError('');
      let data = {
        emailAddress: email,
        firstName,
        lastName,
        confirmPin: confirmPin,
        pin,
        otp,
        countryId: selectedCountry?.countryId,
        phoneNumber: phone,
      };
      signUp(data)
        .unwrap()
        .then((res) => {
          if (res?.success) {
            dispatch(setUser(res?.data));
            // navigate("/home");
            handleNext();
          } else {
            setError(res.message);
          }
        })
        .catch((error) => {
          if (error?.data?.message) {
            setError(error.data.message);
          } else {
            setError('An error occurred.');
          }
        });
    } else {
      setError('Please select your country');
    }
  };

  return (
    <div className="flex">
      <Card>
        <div className="flex items-center flex-col mt-2 w-[300px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
          <div className="text-left mb-12 mr-auto">
            <CardTitle title="More Information" />
          </div>
          <div className="w-full mb-6">
            <label
              htmlFor="country"
              className="block text-[0.625rem] leading-3 text-black md:text-sm font-semibold mb-1.5"
            >
              Country
            </label>

            <div className="relative" onClick={() => showDropdown(!isDropdown)}>
              <img
                src={selectedCountry?.logoUrl}
                alt={selectedCountry?.name}
                className="absolute inset-y-0 left-0 top-[20%] h-5 w-auto"
              />
              <input
                className="w-full pl-10 pr-4 py-2 border-b-[1px] border-primary text-sm font-medium focus:outline-none"
                onChange={(e) => setSearch(e.target.value)}
                value={selectedCountry?.name || search || ''}
              />
              <ArrowDown
                className="absolute cursor-pointer right-0 top-[20%]"
                width={20}
                height={20}
              />
            </div>

            {isDropdown && (
              <div>
                <div className="absolute z-30 w-40 p-3 pl-0 pb-4 overflow-auto bg-white rounded dark:bg-clrDarkBg max-h-40">
                  <ul className="space-y-6 text-xs font-normal text-[#6B6B6B] dark:text-clrPlaceholder">
                    {countries?.data?.records.map((country) => {
                      return (
                        <li
                          key={country.countryId}
                          onClick={() => {
                            setSelectedCountry(country);
                            showDropdown(!isDropdown);
                          }}
                          className="cursor-pointer flex items-center gap-4 "
                        >
                          <span>
                            <img src={country.logoUrl} alt={country.name} />
                          </span>
                          {country.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <ErrorMsg errMsg={isError} />
          <div className="mt-6 mb-8 w-[90%]">
            <ButtonAuth
              type="submit"
              loading={isLoading}
              handleClick={handleSubmit}
              text="Finish"
            />
          </div>
          <div className="mt-4 mb-10 cursor-pointer">
            <div onClick={handleBack}> ← Back </div>
          </div>
        </div>
      </Card>
      <AuthFrame />
    </div>
  );
};

export default MoreInformation;
