import { useEffect, useState } from "react";
import { Modal } from "../reusables/Modal";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { useAppDispatch } from "../../app/hooks";
import { copyToClipboard } from "../../helper";
import 'react-circular-progressbar/dist/styles.css';
import { toggleAppModal } from "../../features/store/authReducerSlice";
import { Button, ButtonOutline, ErrorMsg } from "../reusables";
import { useCustomCodeQuery, useSetCustomCodeMutation } from "src/features/api/homeSlice";
import { ScaleLoader } from "react-spinners";

const RouterLogin = () => {
    const dispatch = useAppDispatch();
    const [loginCode, setLoiginCode] = useState<string>("");
    const [step, setStep] = useState<number>(0);
    const [errorMsg, setError] = useState<string>("");
    const [copiedText, setCopied] = useState<string>("");

    const {data, isLoading: gettingCustomCode} = useCustomCodeQuery()
    const [trigger, {isLoading: settingCustomCode}] = useSetCustomCodeMutation()

    useEffect(()=>{
        if(data){
            setLoiginCode(data?.data?.code || "") 
        }
    }, [data])

    // Save custom code 
    const saveCustomCode = () =>{
        setError("")
        if(loginCode.length === 8){
            trigger(loginCode)
            .unwrap()
            .then((res) => {
              if (res.success) {
                setStep(0)
              }
            })
            .catch((err) => {
                if(err){
                    setError(err?.data?.message || "Something went wrong")
                }
            });
        }else{
            setError("Please enter a valid token")
        }
    }

    return (
        <Modal
            close={true}
            title={"My Login Token"}
            description={""}
            handleCloseModal={() => dispatch(toggleAppModal({ id: "", detail: "" }))}
        >
            <>
                <section>
                    <div className="flex flex-col items-center justify-center px-8 py-8 bg-white rounded dark:bg-clrDarkBg text-center">
                        {step === 0 ?
                            <p className="text-x9 md:text-x10 font-medium text-clrGray dark:text-clrPlaceholder mx-auto mb-3">
                                Copy the code below and <br />
                                use it to login on the Hub sign-in page by opening your browser
                            </p> :
                            <p className="text-x9 md:text-x10 font-medium text-clrGray dark:text-clrPlaceholder mx-auto mb-3">
                                Enter a new 8 alphanumeric login code and save.
                            </p>
                        }
                        {step === 0 ? 
                            <div className="border border-solid p-2 rounded-lg w-1/2">
                                <p className="text-x10 md:text-x10 font-medium text-clrGray dark:text-clrPlaceholder mx-auto mb-2">
                                    My Login Token
                                </p>
                                <div className="relative flex justify-center align-center gap-2">
                                    <span className="text-lg font-medium md:text-2xl dark:text-white">
                                        {gettingCustomCode ?  <ScaleLoader height={12} width={2} color={'#1d1d1d'} /> : loginCode}
                                    </span>
                                    <CopyIcon className="cursor-pointer mt-1" onClick={() => { copyToClipboard(loginCode); setCopied(loginCode) }} />

                                    {loginCode === copiedText && (
                                        <p className="absolute right-0 p-1 mt-4 text-white border-solid rounded bg-primary text-x10 ">
                                            {" "}
                                            Copied
                                        </p>
                                    )}
                                </div>
                            </div>
                        : 
                                <div>
                                   <input
                                        value={loginCode}
                                        className="text-lg md:text-2xl h-12 font-medium text-center w-[140px] text-black bg-clrBg outline-none dark:text-white dark:placeholder:text-white py-1 rounded-xl dark:bg-clrDarkBg"
                                        style={{
                                            // maxWidth: `${outBoundValue.toString().length * 35}px`,
                                            minWidth: `140px`,
                                        }}
                                        type="text"
                                        placeholder="0"
                                        maxLength={8}
                                        onChange={(e) => setLoiginCode(e.target.value)}
                                    />
                                </div>    
                                    
                        }
                        <div className={` ${errorMsg ? "" : "-mb-2"}`}>
                            <ErrorMsg errMsg={errorMsg} />
                        </div>
                    </div>
                    
                </section>
                {
                    step === 0 ?
                        <div className="w-4/5 mx-auto mt-8 [&>*]:tracking-wider">
                            <Button
                                type="button"
                                text={"Change Login Code"}
                                loading={false}
                                handleClick={() => setStep(1)}
                                textSize="text-xs"
                                py="py-2.5"
                                fontWeight="font-semibold"
                            />
                        </div>
                        :
                        <div>
                            <div className="w-4/5 mx-auto mt-8 mb-2 [&>*]:tracking-wider">
                                <Button
                                    type="button"
                                    text={"Save"}
                                    loading={settingCustomCode}
                                    handleClick={saveCustomCode}
                                    textSize="text-xs"
                                    py="py-2.5"
                                    fontWeight="font-semibold"
                                />
                            </div>
                            <div className="w-4/5 mx-auto [&>*]:tracking-wider">
                                <ButtonOutline
                                    type="button"
                                    text={"Back"}
                                    loading={false}
                                    handleClick={() => setStep(0)}
                                    textSize="text-xs"
                                    borderWidth="border"
                                    py="py-[9px]"
                                    fontWeight="font-semibold"
                                />
                            </div>
                        </div>
                }

            </>
        </Modal>

    )
}

export default RouterLogin