import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment";
import { numberWithCommas } from "../../helper";

type Voucher = {
    code: string;
    owner: {phoneNumber: string, email: string, username: string};
    voucherValue: number;
    balance: number;
    expiryDate: string;
    voucherStatus: string;
    
  };
  
  const columnVoucher = createColumnHelper<Voucher>();
  
  export const columnsVoucher = [
    columnVoucher.accessor("code", {
        header: "#",
        cell: (row) => {
          return <p>{Number(row?.row.id) + 1}</p>;
      },
    }),
    columnVoucher.accessor("code", {
      header: "Voucher Code",
    }),
    columnVoucher.accessor("owner", {
      header: "Recipient’s Email",
      cell: (val) => {
        const owner = val.getValue();
        return <p>{(owner?.username)}</p>;
      }
    }),
  
    columnVoucher.accessor("voucherValue", {
      header: "Value",
      cell: (val) => {
        const value = val.getValue();
        return <p> {numberWithCommas(value)}</p>;
        },
    }),
  
    columnVoucher.accessor("balance", {
      header: "Balance",
      cell: (val) => {
        const balance = val.getValue();
        return <p> {numberWithCommas(balance)}</p>;
        },
    }),
    
  columnVoucher.accessor("expiryDate", {
    header: "Expiry Date",
    cell: (val) => {
      const expiryDate = val.getValue();
      return (
        <p> {!expiryDate ? "N/A" : moment(expiryDate).format("MM/DD/YYYY")}</p>
      );
    },
  }),

  columnVoucher.accessor("voucherStatus", {
    header: "Status",
    cell: (info) => {
      const status = info.getValue();

      let color = "";

      if (status.toLowerCase() === "active") {
        color = "#00C087";
      } else if (status.toLowerCase() === "inactive") {
        color = "#DB5F5F";
      } else {
        color = "#E5B910";
      }

      return (
        <p
          style={{
            color,
          }}
        >
          {status}
        </p>
      );
    },
  }),
];
