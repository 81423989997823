import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardTitle, ButtonAuth, ErrorMsg } from "../../reusables";
import AuthFrame from "../../reusables/AuthFrame";
import AuthCode from "react-auth-code-input";
import {
    useVerify2faMutation
} from "../../../features/api/authSlice";
import { SignUpSuccessModal } from "./SignUpSuccessModal";


interface VerifyProps {
    email: string;
    handleBack: () => void;
    handleNext: (title?: string, value?: string) => void;
}

//Component to verify that user has received authentication otp
const TwoFaVerify = ({ email, handleBack }: VerifyProps) => {
    const [faCode, setCode] = useState<string>("");
    const [isError, setError] = useState<string>("");
    const [status, setSuccessStatus] = useState<boolean>(false);

    const navigate = useNavigate()

    const [verify2fa, { isLoading }] = useVerify2faMutation();

    //function to save the otp code sent
    const handleChange = (res: string) => {
        if (isError) {
            setError("");
        }
        setCode(res);
    };


    const handleVerify = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (faCode.length === 6) {
            let data = {
                twoFactorCode: faCode,
                authenticationMedium: "Authenticator"
            }
            verify2fa(data)
                .unwrap()
                .then((res) => {
                    if (res.success) {
                        setSuccessStatus(true);
                    } else {
                        setError(res?.message);
                    }
                })
                .catch((err) => setError(err?.data.message));
        } else {
            setError("Please enter a valid code");
        }
    };

    return (
        <div className="flex">
            <Card>
                <div className="flex items-center flex-col mt-2 w-[300px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
                    <div className="text-center md:text-left">
                        <CardTitle title="Two-Factor Authentication" />
                        <div className="mb-12">
                            <p className="text-clrText font-semibold text-[0.625rem] md:text-sm mb-2">
                                Welcome:{" "}
                                <span className="text-primary">{email}</span>
                            </p>
                            <p className="text-clrText font-semibold text-[0.625rem] md:text-sm">
                                Please enter your 6-digit authentication code from the Google Authenticator app.
                            </p>
                        </div>
                    </div>
                    <div className="text-center">
                        <div className="mb-4">
                            <AuthCode
                                inputClassName="w-10 h-10 text-center text-lg font-semibold text-primary border-transparent border-solid border rounded-md bg-primary/10"
                                containerClassName="flex justify-center gap-3"
                                ariaLabel="verify-otp"
                                isPassword={true}
                                autoFocus={true}
                                length={6}
                                allowedCharacters="numeric"
                                onChange={handleChange}
                            />
                            <ErrorMsg errMsg={isError} />
                        </div>
                    </div>
                    <div className="w-full mt-6 mb-8">
                        <ButtonAuth
                            type="submit"
                            loading={isLoading}
                            handleClick={handleVerify}
                            text="Verify"
                        />
                    </div>

                    <div className="mt-4 mb-10 cursor-pointer">
                        <div onClick={handleBack}> ← Back </div>
                    </div>
                </div>
            </Card>
            <AuthFrame />
            {status && 
            <SignUpSuccessModal
                handleButton={() => { navigate("/home")}}
                title="Use an authentication app"
                description="Starting now, each time you log into your account, you must input your password along with an authentication code generated by Google Authenticator."
                buttonTitle={"Continue"}
                handleModal={() => { }}
            />}
        </div>
    );
};

export default TwoFaVerify;
