import { Button, ButtonOutline } from "../reusables";
import { ReactComponent as Logo2 } from "../../assets/auth/logo.svg";

interface IntroducingMTNDashboardProps {
  handleGetStarted: () => void;
  handleCloseModal: () => void;
}

export const IntroducingMTNDashboard = ({handleGetStarted, handleCloseModal}: IntroducingMTNDashboardProps) => {
  return (
    <section className="fixed inset-0 z-50 h-screen overflow-hidden dark:bg-#00000080 bg-[#00000033] flex justify-center items-center">
      <div className="bg-white dark:bg-clrDarkBg2 rounded-[1.25rem] px-5 pt-14 pb-10 w-4/5 md:w-[30rem]">
       
        <div className="flex flex-col items-center justify-center px-3 pb-8 mb-14 bg-white rounded dark:bg-clrDarkBg max-w-[310px] mx-auto">
          <div className="w-24 h-24 bg-primary rounded-xl grid place-content-center">
           <Logo2 className="w-[75px] h-[34.5px] dark-logo" />
          </div>
          <div className="mt-7 text-center">
            <h2 className="text-lg font-semibold text-black md:text-lg">Thanks for choosing MTN </h2>
            <p className="font-normal text-xs md:text-sm text-clrGray dark:text-clrPlaceholder">
              Let's guide you through our features with a quick tour!
            </p>
          </div>
        </div>

        <div className="md:w-[80%] mx-auto flex flex-col gap-3 ">
          <Button
            type="button"
            text={"Start Tour"}
            handleClick={()=>{handleGetStarted();}}
            fontWeight="font-normal"
          />
          <ButtonOutline
            type="button"
            text={"Skip"}
            handleClick={handleCloseModal}
            fontWeight="font-normal"
            borderWidth="border"
          />
        </div>
      </div>
    </section>
  );
};