import { useState } from 'react';
import Verify from './Verify';
import CreatePin from './CreatePin';
import SignUpForm from './SignUpForm';
import Success from './Success';
import TwoFa from './TwoFa';
import TwoFaVerify from './TwoFaVerify';
import MoreInformation from './MoreInformation';
import TwoFaOptions from './TwoFaOptions';

const signUpDetails = {
  email: '',
  fname: '',
  lname: '',
  otp: '',
  pin: '',
  phone: '',
};

//Component to handle all the sig up steps
const SignUp = () => {
  const [form, setForm] = useState(signUpDetails);
  const [signUpStep, setStep] = useState<number>(0);

  //Function to handle the signup steps and also save any value from the children component
  const handleNext = (title?: string, value?: string) => {
    if (title) {
      setForm({ ...form, [title]: value });
    }
    setStep(signUpStep + 1);
  };

  //Function to determine which of the signupsteps to render
  const renderSignUpPage = () => {
    switch (signUpStep) {
      case 1:
        return (
          <Success
            firstName={form.fname}
            lastName={form.lname}
            handleNext={() => setStep(2)}
          />
        );
      case 2:
        return (
          <Verify
            email={form.email}
            handleBack={() => setStep(0)}
            handleNext={handleNext}
          />
        );
      case 3:
        return (
          <CreatePin
            email={form.email}
            handleBack={() => setStep(1)}
            handleNext={handleNext}
          />
        );
      case 4:
        return (
          <MoreInformation
            email={form.email}
            handleBack={() => setStep(3)}
            handleNext={handleNext}
            firstName={form.fname}
            lastName={form.lname}
            confirmPin={form.pin}
            pin={form.pin}
            otp={form.otp}
            phone={form.phone}
          />
        );
      case 5:
        return (
          <TwoFaOptions
            email={form.email}
            handleBack={() => setStep(4)}
            setStep={setStep}
          />
        );
      case 6:
        return (
          <TwoFa
            email={form.email}
            handleBack={() => setStep(5)}
            handleNext={handleNext}
          />
        );
      case 7:
        return (
          <TwoFaVerify
            email={form.email}
            handleBack={() => setStep(6)}
            handleNext={handleNext}
          />
        );
      default:
        return (
          <SignUpForm handleNext={setStep} form={form} setForm={setForm} />
        );
    }
  };
  return <>{renderSignUpPage()}</>;
};
export default SignUp;
