import { useState } from 'react';
import { useToggleCaptivePortalMutation } from 'src/features/api/deviceSlice';
import { SplashToggleProps } from './types';

export const CaptivePortalToggle = ({ value, deviceId }: SplashToggleProps) => {
  const [isLoggedIn, toggleIsLoggedIn] = useState<boolean>(value);

  // Define the API mutation hook
  const [trigger] = useToggleCaptivePortalMutation();

  // Handle toggle switch change
   const handleToggle = () => {
    
    // Optimistically update the UI
    const newIsLoggedIn = !isLoggedIn;
    toggleIsLoggedIn(newIsLoggedIn);

    const data = {
      deviceId: deviceId as string,
      payload: {
        isEnabled: newIsLoggedIn,
      },
    };

    // Trigger the API request
    trigger(data)
      .unwrap()
      .then((res) => {
        if (!res.success) {
          toggleIsLoggedIn(!newIsLoggedIn);
        }
      })
      .catch(() => {
        // Revert the change in case of error
        toggleIsLoggedIn(!newIsLoggedIn);
      });
  };
  return (
    <div>
      <div className="flex items-center gap-2">
        <p className="">{isLoggedIn ? 'Active' : 'Inactive'}</p>
        <label role='button' tabIndex={0} className="hub_switch scale-150 focus-visible:outline-1">
          <p className="sr-only">{isLoggedIn ? 'Active' : 'Inactive'}</p>
          <input
            type="checkbox"
            id="toggle-mode"
            checked={isLoggedIn}
            onChange={handleToggle}
          />
          <span className="hub_switch__toggle"></span>
        </label>
      </div>
    </div>
  )
}