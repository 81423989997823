import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import MintBag from "../../assets/icons/hotspot-hubs/bag.svg";
import ChartIcon from "../../assets/icons/hotspot-hubs/chart.svg";
import TableMain from "../reusables/ReactDataTable";
import { livesessionColumn } from "./ReactTableDataList";
import { ButtonYellow, EmptyCard, TableLoader } from "../reusables";
import {
  useGetSessionsQuery,
  useGetSessionSummaryQuery,
} from "../../features/api/deviceSlice";
import { ScaleLoader } from "react-spinners";
import { filesize } from "filesize";

const LiveSession = () => {
  const { userId, businessId} = useAppSelector((state) => state.auth);
  const [searchValue, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const { data: sessionSummary, isLoading: gettingSessionSummary } =
    useGetSessionSummaryQuery({
      isActive: true,
      searchParam: searchValue,
      deviceId: "",
      userId: userId,
      businessid: businessId,
      startDateTimeStamp: "",
      endDatetimeStamp: "",
    });

  const { data, isLoading, isFetching } = useGetSessionsQuery({
    isActive: true,
    page,
    pageSize: 30,
    startDateTimeStamp: "",
    endDatetimeStamp: "",
    deviceName: searchValue,
  });


  return (
    <section className="w-[96%] mx-auto mt-3.5 md:mt-0 mb-6 overflow-y-auto">
      <div className="px-3 py-4 mb-3 bg-white rounded-lg dark:bg-clrDarkBg md:px-6 md:pt-6 md:pb-3">
        <h3 className="text-base font-semibold text-[#29313C] dark:text-white">
          Live Session Summary
        </h3>
        <div className="grid grid-cols-2 gap-2 py-4 rounded-lg md:pt-8 md:pb-4 xl:grid-cols-4 md:gap-4">
          <div>
            <h4 className="mb-2 text-xs font-semibold md:text-sm text-clrGray dark:text-clrPlaceholder">
              Total Number of Hubs
            </h4>
            <p className="text-2xl font-semibold text-black dark:text-white">
              {gettingSessionSummary ? (
                <ScaleLoader
                  loading={isLoading}
                  height={12}
                  width={2}
                  color={"#1d1d1d"}
                />
              ) : (
                sessionSummary?.data?.numberOfDevices
              )}
            </p>
          </div>
          <div>
            <h4 className="mb-2 text-xs font-semibold md:text-sm text-clrGray dark:text-clrPlaceholder">
              Total Number of Sessions
            </h4>
            <p className="text-2xl font-semibold text-black dark:text-white">
              {gettingSessionSummary ? (
                <ScaleLoader
                  loading={isLoading}
                  height={12}
                  width={2}
                  color={"#1d1d1d"}
                />
              ) : (
                sessionSummary?.data?.numberOfSessions
              )}
            </p>
          </div>
          <div>
            <h4 className="mb-2 text-xs font-semibold md:text-sm text-clrGray dark:text-clrPlaceholder">
              Total Data Used
            </h4>
            <p className="text-2xl font-semibold text-black dark:text-white">
              {gettingSessionSummary ? (
                <ScaleLoader
                  loading={isLoading}
                  height={12}
                  width={2}
                  color={"#1d1d1d"}
                />
              ) : (
                filesize(sessionSummary?.data?.totalDataUsed || 0)
              )}
            </p>
          </div>
          <div>
            <h4 className="mb-2 text-xs font-semibold md:text-sm text-clrGray dark:text-clrPlaceholder">
              Total Resell Revenue
            </h4>
            <p className="text-2xl font-semibold text-black dark:text-white">
              {gettingSessionSummary ? (
                <ScaleLoader
                  loading={isLoading}
                  height={12}
                  width={2}
                  color={"#1d1d1d"}
                />
              ) : (
                "NGN"
              )}{" "}
              {sessionSummary?.data?.totalAmountGenerated}
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:p-6">
        <div className="flex flex-wrap items-end gap-3 mb-8">
          <div className="flex w-full gap-3 md:w-auto">
            <div className="flex-1 md:flex-auto ">
              <label
                htmlFor=""
                className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
              >
                Search
              </label>
              <input
                type="text"
                name=""
                id=""
                value={searchValue}
                onChange={(e)=> setSearch(e.target.value)}
                placeholder="Hub Name"
                className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
              />
            </div>
          </div>
          <ButtonYellow
            text="View Session History"
            btnIcon={ChartIcon}
            to={"/home/hotspot-hubs/session-history"}
          />
        </div>
        <div>
          {isLoading || isFetching? (
            <TableLoader />
          ) : (
            <TableMain
              defaultData={data?.data?.records || []}
              columns={livesessionColumn}
              totalPages={data?.data.totalPages || 0}
              pageSize={data?.data.pageSize || 0}
              currentPage = {data?.data.currentPage || 0}
              setPage={setPage}
            />
          )}
        </div>
        {!isLoading && !isFetching && data?.data.records.length === 0 && (
          <div className="flex flex-col items-center justify-center">
            <EmptyCard description="No result found" />
            <a
              href="https://shop.mtn.ng"
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-between items-center w-fit h-8 mt-3 px-4 gap-3 bg-primary rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#e3be27] hover:scale-[0.95]"
            >
              <img src={MintBag} alt="Buy Hub" width={18} height={18} />
              <p className="text-xs font-medium text-white">{"Buy Hub"}</p>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default LiveSession;
