import { useState } from 'react';
import { InfoBox, ProgressBox } from './common';
import { EmptyCard, TableLoader } from '../reusables';
import TableMain from '../reusables/ReactDataTable';
import { columnsAnalytics } from './data';
import { 
  useGetCustomerOverviewQuery, 
  useGetDeviceAnalyticsSummaryQuery, 
  useGetDeviceStatusHubCountQuery,
  useGetDeviceTokensCountQuery 
} from 'src/features/api/analyticsSlice';
import TrafficSummary from "./TrafficSummary";

const Analytics = () => {
  const [page, setPage] = useState<number>(1);
  const { data, isLoading, isFetching } = useGetDeviceAnalyticsSummaryQuery({
    page,
    pageSize: 30,
    search: '',
  });
  const {data: customerOverview} = useGetCustomerOverviewQuery();
  const{data: hubCount} = useGetDeviceStatusHubCountQuery();
  const {data: tokenCount} = useGetDeviceTokensCountQuery();

  const totalHubs = (hubCount?.data?.onlineDeviceCount || 0) + (hubCount?.data?.offlineDeviceCount || 0);
  const onlineHubs = hubCount?.data?.onlineDeviceCount || 0;
  const oflineeHubs = hubCount?.data?.offlineDeviceCount || 0;
  
  // Calculate the progress value as a percentage of online hubs
  const progressValue = totalHubs > 0 ? (onlineHubs / totalHubs) * 100 : 0;


  const totalToken = (tokenCount?.data?.timedTokensCount || 0) + (tokenCount?.data?.dataTokensCount || 0);
  const timedTokensCount = tokenCount?.data?.timedTokensCount || 0;
  const dataTokensCount = tokenCount?.data?.dataTokensCount || 0;
  
  // Calculate the progress value as a percentage of online hubs
  const tokenProgressValue = totalToken > 0 ? (timedTokensCount / totalToken) * 100 : 0;

  return (
    <section className="w-[96%] mx-auto pb-6 mt-3 overflow-y-auto">
      <div className="px-4 py-4 bg-white rounded-lg dark:bg-clrDarkBg md:py-8">
        <div className="md:px-4 md:w-auto">
          <div className="mb-3">
            <p className="font-semibold text-clrGray">General Overview</p>
          </div>
          <div className="flex flex-wrap md:flex-nowrap gap-x-16 xl:gap-x-40">
            <InfoBox label="Total Uptime" value={customerOverview?.data?.totalUptime || "0"} />
            <InfoBox label="Total Data Used" value={customerOverview?.data?.totalDataUsage || "0"} />
          </div>
          <div className="grid gap-6 mt-10 md:grid-cols-2 xl:grid-cols-2">
            <ProgressBox
              title="Hubs"
              progressValue={progressValue}
              text={{ label: 'Total', value: ((hubCount?.data?.onlineDeviceCount || 0) + (hubCount?.data?.offlineDeviceCount || 0)).toString()}}
              info={[
                { label: 'Online', value: onlineHubs.toString() || "0", flex: true },
                { label: 'Offline', value: oflineeHubs.toString() || "0", flex: true, offline: true },
              ]}
            />
            <ProgressBox
              title="Tokens"
              progressValue={tokenProgressValue}
              text={{ label: 'Available', value: totalToken.toString() || '0'}}
              info={[
                { label: 'Data Tokens', value: dataTokensCount.toString() || '0', flex: true },
                {
                  label: 'Timed Tokens',
                  value: timedTokensCount.toString() || '0',
                  flex: true,
                  offline: true,
                },
              ]}
            />
          </div>
        </div>

        <div className="mb-5 mt-11">
          <TrafficSummary />
        </div>

        {isLoading || isFetching ? (
          <TableLoader />
        ) : (
          <TableMain
            defaultData={data?.data.records || []}
            columns={columnsAnalytics}
            totalPages={data?.data?.totalPages || 0}
            pageSize={data?.data?.pageSize || 0}
            currentPage={data?.data?.currentPage|| 0}
            setPage={setPage}
          />
        )}

        {!isLoading && !isFetching && (data?.data.records.length || 0) === 0 && (
          <EmptyCard description="You don't have any analytics!" />
        )}
      </div>
    </section>
  );
};

export default Analytics;
