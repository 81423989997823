import { Card, CardTitle } from '../../reusables';
import AuthFrame from '../../reusables/AuthFrame';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down-Yellow.svg';
import { Link, useNavigate } from 'react-router-dom';

interface TwoFaOptionsProps {
  email: string;
  handleBack: () => void;
  setStep: (step: number) => void;
}

const TwoFaOptions = ({ email, handleBack, setStep }: TwoFaOptionsProps) => {
  const navigate = useNavigate();
  return (
    <div className="flex">
      <Card>
        <div className="flex items-center flex-col mt-2 min-[426px]:w-[350px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
          <div className="text-center md:text-left">
            <CardTitle title="Two-Factor Authentication" />
            <p className="text-clrText font-medium text-[0.625rem] md:text-sm mb-6">
              Welcome : <span className="text-primary">{email}</span>
            </p>
            <p className="text-clrText font-medium text-[0.625rem] md:text-sm mb-10">
              Secure your account by selecting a preferred two factor
              authentication method{' '}
            </p>
          </div>

          <div>
            <button
              onClick={() => navigate('/home')}
              className="mb-4 relative flex justify-between items-center bg-clrBg px-4 cursor-pointer w-[330px] rounded py-3"
            >
              <p className="text-base">Secure with Email </p>{' '}
              <ArrowDown width={30} height={30} />
            </button>
            <button
              onClick={() => setStep(6)}
              className="mb-4 relative flex justify-between items-center bg-clrBg px-4 cursor-pointer w-[330px] rounded py-3"
            >
              <p className="text-base">Secure with Authenticator App </p>
              <ArrowDown width={30} height={30} />
            </button>
          </div>

          <div className="mt-4 mb-10 cursor-pointer">
            <Link to={'/home'} className='text-primary tex-sm' onClick={handleBack}> skip </Link>
          </div>
        </div>
      </Card>
      <AuthFrame />
    </div>
  );
};

export default TwoFaOptions;
