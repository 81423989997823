import moment from 'moment';
import { SideModal } from 'src/components/reusables/Modal';
// import { useGetTransactionDetailsQuery } from 'src/features/api/walletSlice';
import { numberWithCommas } from 'src/helper';

const TransactionDetailRow = ({ label, value }: any) => (
  <p className="flex flex-wrap items-center justify-between border-[#EAE9ED] border-b py-6">
    <span className="text-sm font-normal text-[#525252]">{label}</span>
    <span className="text-xs font-semibold text-black">{value}</span>
  </p>
);

const TransactionDetails = ({ handleModal, showTransDetails }: any) => {
  // const data_ = useGetTransactionDetailsQuery(showTransDetails?.original?.id, {
  //   skip: !showTransDetails?.original?.id,
  // });

  // console.log(data_, 'useGetTransactionDetailsQuery');

  const { original: data } = showTransDetails || {};

  return (
    <SideModal
      goBack={true}
      close={true}
      handleCloseModal={handleModal}
      title="Transaction Details"
    >
      <div className="mt-6">
        <TransactionDetailRow
          label="Date & Time"
          value={moment(data?.transactionTimeStamp).format(
            'MM/DD/YYYY | h:mm a'
          )}
        />
        <TransactionDetailRow
          label="Transaction Type"
          value={data?.transactionType}
        />
        <TransactionDetailRow label="Reference" value={data?.reference} />
        <TransactionDetailRow
          label="Operation Type"
          value={data?.operationType}
        />
        <TransactionDetailRow
          label="Amount"
          value={`${numberWithCommas(data?.amount)} ${data?.currencyCode}`}
        />
        <TransactionDetailRow label="Status" value={data?.status} />
      </div>
    </SideModal>
  );
};

export default TransactionDetails;
