interface ErrProps {
  errMsg: string;
  textPosition?: string;
}

export const ErrorMsg = ({ errMsg, textPosition }: ErrProps) => {
  return (
    <p data-testid="error-message" className={`text-[#F04438] ${textPosition} text-left font-medium text-xs mt-2`}>
      {errMsg}
    </p>
  );
};
