import { useEffect, useRef, useState } from 'react';
import { Button } from '../../reusables';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { ConfirmModal } from '../P2PInfo';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { copyToClipboard } from '../../../helper';
import { InitiateOrderResponse } from '../../../features/api/walletSliceTypes';

interface AccountInfo {
  handleSuccess: () => void;
  data: InitiateOrderResponse | undefined;
}
const AccountInfo = ({ handleSuccess, data }: AccountInfo) => {
  const [remainingTime, setRemainingTime] = useState(30 * 60);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const ConfirmModalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsConfirmModal(false);
  };

  useOutsideClick(ConfirmModalRef, handleCloseModal);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevRemainingTime) => prevRemainingTime - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <>
      <div className="px-8 py-5 bg-white rounded dark:bg-clrDarkBg">
        <h2 className="text-sm md:text-base font-semibold dark:font-medium text-[#212121] dark:text-white mb-2">
          Account Information{' '}
        </h2>
        <p className="font-normal text-x10 text-clrGray dark:text-clrPlaceholder">
          Proceed to your bank app to complete this transfer{' '}
        </p>
        <div className="mt-10 space-y-10 bg-[#fffefa] p-5 rounded-lg border  border-[#E5B9101A]">
          <div className="grid gap-6">
            <div>
              <p className="mb-2 font-semibold text-x10 text-clrGray dark:font-medium dark:text-clrPlaceholder">
                AMOUNT
              </p>
              <p className="text-lg flex items-center font-bold text-[#212121] dark:text-white">
                {
                  data?.data?.providerPaymentMethods?.orderPaymentMethod
                    ?.accountNumber
                }
                NGN 600{' '}
                <CopyIcon
                  onClick={() => {
                    copyToClipboard(
                      data?.data?.providerPaymentMethods?.orderPaymentMethod
                        ?.accountNumber || ''
                    );
                  }}
                  className="ml-4"
                />
              </p>
            </div>
            <div>
              <p className="mb-2 font-semibold text-x10 text-clrGray dark:font-medium dark:text-clrPlaceholder">
                ACCOUNT NUMBER{' '}
              </p>
              <p className="text-lg flex items-center font-bold text-[#212121] dark:text-white">
                {
                  data?.data?.providerPaymentMethods?.orderPaymentMethod
                    ?.accountNumber
                }
                8815277371
                <CopyIcon
                  onClick={() => {
                    copyToClipboard(
                      data?.data?.providerPaymentMethods?.orderPaymentMethod
                        ?.accountNumber || ''
                    );
                  }}
                  className="ml-4"
                />
              </p>
            </div>
            <div>
              <p className="mb-2 font-semibold text-x10 text-clrGray dark:font-medium dark:text-clrPlaceholder">
                BANK NAME{' '}
              </p>
              <p className="text-lg flex items-center font-bold text-[#212121] dark:text-white">
                {
                  data?.data?.providerPaymentMethods?.orderPaymentMethod
                    ?.accountNumber
                }
                ACCESS BANK PLC
              </p>
            </div>{' '}
            <div>
              <p className="mb-2 font-semibold text-x10 text-clrGray dark:font-medium dark:text-clrPlaceholder">
                BENEFICIARY{' '}
              </p>
              <p className="text-lg flex items-center font-bold text-[#212121] dark:text-white">
                {
                  data?.data?.providerPaymentMethods?.orderPaymentMethod
                    ?.accountNumber
                }
                MTN TELECOMMS PLC
              </p>
            </div>
          </div>
        </div>
        <p className="text-[10px] mt-10 mb-8 font-normal text-clrGray ">
          This account details is only valid for this specific transaction and
          it'll expire{' '}
          <span className="font-semibold dark:font-medium dark:text-clrPlaceholder">
            {formatTime(remainingTime)}
          </span>
        </p>
        <div className="md:w-[50%] mt-4  ">
          <Button
            text="I've made this transfer "
            type="button"
            handleClick={() => setIsConfirmModal(true)}
          />
        </div>
      </div>

      {isConfirmModal && (
        <ConfirmModal
          handleModal={handleCloseModal}
          handleContinue={handleSuccess}
          orderId={data?.data?.orderId}
          handleChange={() => {}}
          errMsg={''}
          resendOtp={function (): void {
            throw new Error('Function not implemented.');
          }}
          loading={false}
        />
      )}
    </>
  );
};

export default AccountInfo;
