import { useState } from 'react';
import { ButtonAuth, ErrorMsg } from '../reusables';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-Yellow.svg';
import { useGetSupportedCountriesQuery, useGetUserProfileQuery } from 'src/features/api/authSlice';
import { Country } from 'src/features/api/authSliceTypes';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../reusables/Modal';
import SearchIcon from "../../assets/search.svg";
import { useUpdateProfileMutation } from 'src/features/api/profileSlice';
import { useAppSelector } from 'src/app/hooks';

const EditNumber = () => {
    const [search, setSearch] = useState<string>('');
    const { userId } = useAppSelector((state) => state.auth);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [selectedCountry, setSelectedCountry] = useState<Country>();
    const [isDropdown, showDropdown] = useState<boolean>(false);
    const [selectRegion, showSelectRegion] = useState<boolean>(false);
    const [updateProfile, {isLoading: savingProfile}] = useUpdateProfileMutation();
    const [errMsg, setErr] = useState<string>('');

    const { data: userProfile } = useGetUserProfileQuery(userId, {
      skip: !userId,
    });
    
    const navigate = useNavigate();
  
    const { data: countries } = useGetSupportedCountriesQuery({
      page: 1,
      pageSize: 195,
      search,
    });

    const handleCountryClick = (country: Country) => {
        setSelectedCountry(country);
        showSelectRegion(false);
    };

    const handleProfileUpdate = () => {
      setErr("")
      let bodyFormData = new FormData();
      bodyFormData.append('FirstName', userProfile?.data?.name || '');
      bodyFormData.append('LastName', userProfile?.data?.name || '');
      bodyFormData.append('PhoneNumber', phoneNumber);
      bodyFormData.append('ProfileImage', '');
      updateProfile(bodyFormData)
        .unwrap()
        .then((res) => {
          if (res.success) {
            navigate('/home/profile/info')
          }
        })
        .catch((err) => {
          setErr(err?.data?.message || 'Something went wrong');
        });
    };

  return (
    <section className="w-[96%] mx-auto mt-3 mb-6 overflow-y-auto bg-white rounded-lg">
      <div className="flex flex-col items-center w-full h-full px-4 mx-auto mt-5 mb-12 md:mt-10 md:mb-8">
        <div className="text-center">
          <h2 className="text-lg md:text-xl font-semibold text-black md:mb-[10px]">Enter contact number</h2>
          <div className="mb-9">
            <p className="text-clrGray font-normal text-xs md:text-sm lg:w-[50%] mx-auto">
                To ensure the security of your account and make communication easy,  Please enter your number below
            </p>
          </div>
        </div>
        
        <div>
          <div className="w-full mb-6">
            <label
              htmlFor="country"
              className="block text-[0.625rem] leading-3 text-clrGray md:text-xs font-medium mb-2"
            >
              Country
            </label>

            <div className="relative" onClick={() => showSelectRegion(!selectRegion)}>
              <img
                src={selectedCountry?.logoUrl}
                alt={selectedCountry?.name}
                className="absolute inset-y-0 left-0 top-[20%] h-5 w-auto"
              />
              <input
                className="w-full pb-2 pl-10 pr-4 text-xs font-medium border-b border-clrBorder5 text-clrGray focus:outline-none"
                onChange={(e) => setSearch(e.target.value)}
                value={selectedCountry?.name || search || ''}
              />
              <ArrowDown
                className="absolute cursor-pointer right-0 top-[20%]"
                width={20}
                height={20}
              />
            </div>

            {isDropdown && (
              <div>
                <div className="absolute z-30 w-40 p-3 pb-4 pl-0 overflow-auto bg-white rounded dark:bg-clrDarkBg max-h-40">
                  <ul className="space-y-6 text-xs font-normal text-clrGray dark:text-clrPlaceholder">
                    {countries?.data?.records.map((country) => {
                      return (
                        <li
                          key={country.countryId}
                          onClick={() => {
                            setSelectedCountry(country);
                            showDropdown(!isDropdown);
                          }}
                          className="flex items-center gap-4 cursor-pointer "
                        >
                          <span>
                            <img src={country.logoUrl} alt={country.name} />
                          </span>
                          {country.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <div className="w-full mb-6">
            <label
              htmlFor="country"
              className="block text-[0.625rem] leading-3 text-clrGray md:text-x11 font-medium mb-2"
            >
              Contact Number
            </label>
            <div className='flex items-center gap-1.5 border-b border-clrBorder5 pb-1.5'>
                <p className='text-[0.625rem] leading-3 text-clrGray md:text-xs font-normal'>{selectedCountry?.countryCode || "+234"}</p>
                <div className='h-[0.8rem] w-[0.025rem] bg-clrPlaceholder' />
                <input
                    className="w-full text-[0.625rem] leading-3 md:text-xs font-normal text-clrGray focus:outline-none"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={phoneNumber || ''}
                    />
            </div>
          </div>
          <ErrorMsg errMsg={errMsg} />
        </div>
        <div className="mt-6 lg:w-[20%] max-w-[10rem] mx-auto">
          <ButtonAuth
            type="submit"
            loading={savingProfile}
            handleClick={() => handleProfileUpdate()}
            text="Save"
            fontWeight='font-600'
            py='py-1.5'
          />
        </div>
      </div>
      {selectRegion && (
        <SelectRegion
          handleCloseModal={() => showSelectRegion(false)}
          handleGoBack={() => showSelectRegion(false)}
          handleSubmit={handleCountryClick}
        />
      )}
    </section>
  );
};

export default EditNumber;

 const SelectRegion: React.FC<{
    handleGoBack: () => void;
    handleCloseModal: () => void;
    handleSubmit: (val: Country) => void;
  }> = ({ handleCloseModal, handleSubmit, handleGoBack }) => {
    const [searchQuery, setSearchQuery] = useState<string>("");

    const { data: countries } = useGetSupportedCountriesQuery({
        page: 1,
        pageSize: 195,
        search: searchQuery,
      });
  
    const filteredCountries = countries?.data?.records.filter((country) =>
      country.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    return (
      <Modal
        close={true}
        goBack={true}
        title={"Select Region"}
        handleGoBack={handleGoBack}
        handleCloseModal={handleCloseModal}
      >
        <div className="p-6 bg-white rounded dark:bg-clrDarkBg">
          <div className="relative mb-3 ">
            <label htmlFor="search" className="hidden">
              Search
            </label>
            <img
              src={SearchIcon}
              alt="search"
              className="absolute top-2.5 left-5"
              width={13}
              height={13}
            />
            <input
              type="text"
              name="search"
              id=""
              placeholder="Search country"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery || ''}
              className="w-full indent-8 bg-clrBg3 border-[1px] border-solid border-clrBorder4 rounded-3xl h-8 px-4 placeholder:text-clrPlaceholder placeholder:text-x10 text-xs outline-none"
            />
          </div>
          <div className="h-40 overflow-auto">
            {searchQuery
              ? filteredCountries?.map((country) => {
                  return (
                    <button
                      key={country.countryId}
                      onClick={() => handleSubmit(country)}
                      className="py-3 w-full border-b-[0.0625rem] border-solid border-clrBorder3 dark:border-[#DEDEDE80] "
                    >
                      <p className="font-semibold text-left dark:font-medium text-clrGray dark:text-clrPlaceholder text-x9 active:text-primary focus:text-primary hover:text-primary dark:active:text-white dark:focus:text-white dark:hover:text-white">
                        {country.name}
                      </p>
                    </button>
                  );
                })
              : countries?.data?.records.map((country) => {
                  return (
                    <button
                      key={country.countryId}
                      onClick={() => handleSubmit(country)}
                      className="py-3 w-full border-b-[0.0625rem] border-solid border-clrBorder3 dark:border-[#DEDEDE80]"
                    >
                      <p className="font-semibold text-left dark:font-medium text-clrGray dark:text-clrPlaceholder text-x9 active:text-primary focus:text-primary hover:text-primary dark:active:text-white dark:focus:text-white dark:hover:text-white">
                        {country.name}
                      </p>
                    </button>
                  );
                })}
          </div>
        </div>
      </Modal>
    );
  };