import React, { useRef, useState } from 'react';
// import { useAppSelector } from "../../app/hooks";
import { Button, ErrorMsg } from '../reusables';
import ArrowDownWhite from '../../assets/icons/wallet/arrow-down-white.svg';
import ChatIcon from '../../assets/icons/wallet/chat-outline.svg';
import CallIcon from '../../assets/icons/wallet/call.svg';
import WhatsappIcon from '../../assets/icons/wallet/whatsapp.svg';
import XendBridge from '../../assets/icons/wallet/xendbridge.svg';
// import ProfileIMG from "../../assets/icons/wallet/profileIMG.png";
import SendIcon from '../../assets/icons/wallet/send.svg';
import useOutsideClick from '../../hooks/useOutsideClick';
import { Modal } from '../reusables/Modal';
// import { useGetMinimumAmountQuery } from "../../features/api/walletSlice";
// import moment from "moment";
import { AppealProps } from './types';

const Appeal = ({ modalRef, data }: AppealProps) => {
  const [isAppealMessage, setIsAppealMessage] = useState(false);
  const AppealMessageRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsAppealMessage(false);
  };
  useOutsideClick(AppealMessageRef, handleCloseModal);
  return (
    <section
      className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#00000033] dark:bg-[#00000080] flex justify-center items-center"
      ref={modalRef}
    >
      <div className="bg-white dark:bg-clrDarkBg pb-6 z-30 rounded-xl w-72 md:w-[400px]">
        <div className="p-3 appeal rounded-xl bg-clrYellow2 dark:bg-clrYellow2">
          <div className="flex items-center gap-3 mb-4">
            <img src={ChatIcon} alt="" width={20} height={20} />
            <h2 className="mr-auto text-base font-semibold text-white">
              Appeal
            </h2>
            <img src={ArrowDownWhite} alt="" width={20} height={20} />
          </div>
          <p className="text-[10px] font-normal text-white">
            You can upload proof of payment with account info and send through
            Whatsapp to help both sides verify the payment.
          </p>
        </div>
        <div className="px-4 bg-white dark:bg-clrDarkBg">
          <div className="flex items-center justify-center gap-4 py-6">
            <a
              href={`tel:${
                data?.providerContact?.whatsappNumber
                  ? data?.providerContact?.whatsappNumber
                  : data?.providerContact?.phoneNumber
              }`}
              className="flex flex-col items-center justify-center w-20 h-16 rounded-lg bg-clrLightYellow cursor-pointer"
            >
              <img src={CallIcon} alt="" width={20} height={20} />

              <p className="mt-1 font-normal text-black dark:text-clrGray text-x10">
                Call Now
              </p>
            </a>
            <div className="flex flex-col items-center justify-center w-20 h-16 rounded-lg bg-clrLightYellow cursor-pointer">
              <img src={WhatsappIcon} alt="" width={20} height={20} />
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="mt-1 font-normal text-black dark:text-clrGray text-x10"
                href={`https://wa.me/${
                  data?.providerContact?.whatsappNumber
                    ? data?.providerContact?.whatsappNumber
                    : data?.providerContact?.phoneNumber
                }`}
              >
                {' '}
                Whatsapp
              </a>
            </div>
          </div>
          <p className="text-x8 font-normal text-[#525252] text-center dark:text-clrPlaceholder">
            If you cannot reach the buyer/seller, or reach a common terms,
            please file an appeal.
          </p>
          <div className="w-[80%] my-8 mx-auto ">
            <Button
              text="Submit Appeal"
              type="button"
              handleClick={() => setIsAppealMessage(true)}
            />
          </div>
          <p className="text-[8px] font-normal text-clrGray text-center">
            Powered by MoMo
          </p>
        </div>
        {isAppealMessage && (
          <AppealMessage
            modalRef={AppealMessageRef}
            data={data}
            p2pReference={''}
            disputeReference={''}
            // p2pReference={data?.orderResponse?.orderReference}
            // disputeReference={data?.orderResponse?.disputeReference}
            handleModal={handleCloseModal}
          />
        )}
      </div>
    </section>
  );
};

export default Appeal;

export const AppealMessage: React.FC<AppealProps> = ({ handleModal, data }) => {
  const [remark, setRemark] = useState<string>('');
  const [errMsg, setErr] = useState<string>('');
  // const { email} = useAppSelector((state) => state.auth);
  // const { data: p2pReference } =
  //   useGetMinimumAmountQuery();

  const handleDispute = () => {
    if (!data?.disputeReference) {
      if (remark) {
        // let payload = {
        //   emailAddress: email,
        //   orderReference: data?.orderReference as string,
        //   remark: remark,
        //   base64Attachment: "",
        // };
        setErr('');
      } else {
        setErr('You cannot send an empty message');
      }
    } else {
      if (remark) {
        // let commentData = {
        //   p2pReference: p2pReference?.data?.publicKey,
        //   payload: {
        //     disputeReference: data?.orderReference as string,
        //     comment: remark,
        //     emailAddress: email,
        //     recipient: 1,
        //   },
        // };
        setErr('');
        //commentonDispute(commentData);
      } else {
        setErr('You cannot send an empty message');
      }
    }
  };

  return (
    <Modal
      close={false}
      title={'Submit Appeal'}
      description={''}
      handleCloseModal={handleModal}
      goBack={true}
      handleGoBack={handleModal}
    >
      <div className="dark:bg-clrDarkBg p-2">
        <div className="space-y-4">
          <div className="w-[55%]">
            <div className="flex gap-2 mb-1 ml-7">
              <h3 className="font-semibold text-black dark:text-white text-x10">
                Xend Bridge
              </h3>
              <span className="text-x8 text-clrGray">2mins ago</span>
            </div>
            <div className="flex gap-2">
              <img
                src={XendBridge}
                alt="Xend bridge logo"
                width={20}
                height={20}
                className="self-start"
              />

              <p className="text-[8px] font-normal text-[#525252] bg-clrLightYellow rounded-md rounded-l-none p-2">
                message
              </p>
            </div>
          </div>
          {/* {disputes && disputes?.data?.map((dispute: any) =>
            dispute.sentBy === "ThirdPartyClient" ? (
              <div className="flex flex-col items-end w-[55%] ml-auto" key={dispute?.id}>
                <div className="flex self-start gap-2 mb-1 ml-7">
                  <h3 className="font-semibold text-black dark:text-white text-x10">
                    {dispute?.senderName || ""}
                  </h3>
                  <span className="text-x8 text-clrGray">{moment(dispute?.receievedAtTimestamp).fromNow()}</span>
                </div>
                <div className="flex gap-2">
                  <p className="self-start w-5 h-5 capitalize bg-clrYellow2 flex justify-center items-center rounded-full text-white "> {dispute?.senderName.charAt(0)}</p>
                  <p className="text-[8px] font-normal text-[#525252] bg-[#f8f8f8] rounded-md rounded-s-none p-2">
                    {dispute.message}
                  </p>
                </div>
              </div>
            ) : (
              <div className="w-[55%]">
                <div className="flex gap-2 mb-1 ml-7">
                  <h3 className="font-semibold text-black dark:text-white text-x10">
                    Xend Bridge
                  </h3>
                  <span className="text-x8 text-clrGray">2mins ago</span>
                </div>
                <div className="flex gap-2">
                  <img
                    src={XendBridge}
                    alt="Xend bridge logo"
                    width={20}
                    height={20}
                    className="self-start"
                  />

                  <p className="text-[8px] font-normal text-[#525252] bg-clrLightYellow rounded-md rounded-l-none p-2">
                    {dispute.message}
                  </p>
                </div>
              </div>
            )
          )} */}
        </div>
        <div className="relative flex flex-col justify-center items-center rounded-md border-[1px] border-solid border-[#d8e0e7] dark:bg-clrDarkBg2 bg-[#f5f7fc] pl-3 pr-10 h-9 mt-3">
          <input
            type="text"
            placeholder="Write your content here"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            className="placeholder:text-x8 text-x8 dark:text-clrPlaceholder placeholder:text-[#b8b8b8] dark:placeholder:!text-clrPlaceholder dark:bg-inherit w-full bg-transparent outline-none"
          />
          <img
            src={SendIcon}
            alt="more options"
            width={20}
            height={20}
            className="absolute top-2 right-2 cursor-pointer"
          />
        </div>
        <ErrorMsg errMsg={errMsg} />
        <div className="w-[80%] mt-8 mx-auto ">
          <Button
            text="Submit"
            type="button"
            // loading={gettingDispute || commentingOnDispute}
            handleClick={handleDispute}
          />
        </div>
        <p className="mt-3 font-normal text-center text-x8 text-clrGray">
          Powered by MoMo
        </p>
      </div>
    </Modal>
  );
};
