import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { setUser } from '../features/store/authReducerSlice';

import ProtectedRoute from './protectedRoute';

//  Auth Pages
import Login from '../components/auth/logIn';
import SignUp from '../components/auth/signUp';
import Reset from '../components/auth/Reset';
import ResetPin from '../components/auth/ResetPin';

// Dashboard
import Home from '../components/home';
import Dashboard from '../components/home/Dashboard';

//Subscription
import Subscription from '../components/subscription';
import SelectedPlan from '../components/subscription/SelectedPlan';
import PlanList from '../components/subscription/Plan';

//Wallet
import Wallet from '../components/wallet';
import TransactionHistory from '../components/wallet/transactionHistory';
import AddBankDetails from '../components/wallet/AddBankDetails';
import WithdrawP2P from '../components/wallet/WithdrawP2P';

// Hubs
import HotspotHubs from '../components/hotspotHubs/index';
import MyHubs from '../components/hotspotHubs/MyHubs';
import LiveSession from '../components/hotspotHubs/LiveSession';
import HubsReport from '../components/hotspotHubs/HubsReport';
import DeviceTracker from '../components/hotspotHubs/DeviceTracker';
import SessionHistory from '../components/hotspotHubs/SessionHistory';

//Parental Control
import ParentalControl from '../components/parentalControl';
import ParentalControlSetup from '../components/parentalControl/Setup';

//My Cluster
import Cluster from '../components/cluster';

// Adverts
import AdvertControl from '../components/advertControl';
import AdvertControlOverview from '../components/advertControl/Overview';
import AdvertControlShare from '../components/advertControl/Share';
import AdvertControlCaption from '../components/advertControl/Caption';

// Token
import Token from '../components/token';

// Agents
import Agents from '../components/agents';
import AgentsDetails from '../components/agents/AgentsDetails';

// Voucher
import Voucher from '../components/voucher';

// Profile
import Profile from '../components/profile/index';
import ProfileInfo from 'src/components/profile/ProfileInfo';
import VerifyID from '../components/profile/VerifyID';
import Security from 'src/components/profile/Security';

//Register Business
import BusinessReg from '../components/home/BusinessReg';
import HubForm from '../components/hubForm';
import Content from '../components/hubForm/Content';
import Responses from '../components/hubForm/Responses';
import NetworkFeedbackForm from '../components/hubForm/NetworkFeedForm';
import FormContent from '../components/hubForm/FormContent';

// Analytics
import Analytics from 'src/components/analytics';
import AnalyticsDetails from 'src/components/analytics/AnalyticsDetails';
import Connections from 'src/components/analytics/Connections';
import Speed from 'src/components/analytics/Speed';
import Uptime from 'src/components/analytics/Uptime';
import DataUsage from 'src/components/analytics/DataUsage';
import TrafficAnalytics from "src/components/analytics/Traffic";

// Log Out
import LogOut from '../components/logOut';
import EnterAmount from '../components/wallet/bankTransfer/Amount';
import USSDAmount from 'src/components/wallet/ussd/Amount';
import TwoFa from 'src/components/profile/TwoFa';
import TwoFaCode from 'src/components/profile/TwoFaCode';
import ResetPassword from 'src/components/profile/ResetPassword';
import useTokenRefresh from 'src/hooks/useTokenRefresh';
import WithdrawalEnterAmount from 'src/components/wallet/withdrawalBankTransfer/Amount';
import TransferDetails from 'src/components/wallet/user/TransferDetails';
import HubSetup from "src/components/home/HubSetup";
import BlacklistedList from "src/components/parentalControl/BlacklistedList";
import { ViewTemplate } from "src/components/hubForm/ViewTemplate";
import EditNumber from 'src/components/profile/EditNumber';
import ManagedHubs from 'src/components/hotspotHubs/ManagedHubs';
import TrafficDetails from "src/components/analytics/TrafficDetails";
import ConnectionDetails from "src/components/analytics/ConnectionDetails";

const App: React.FC = () => {
  useTokenRefresh();

  const dispatch = useAppDispatch();
  const user = JSON.parse(localStorage.getItem('mtn-user') || '{}');

  useEffect(() => {
    dispatch(setUser(user));
  }, []);

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path={'/'} element={<Login />} />
        <Route path={'/signup'} element={<SignUp />} />
        <Route path={'/reset'} element={<Reset />} />
        <Route path={'/passwords/reset'} element={<ResetPin />} />
        {/* Private Routes */}
        <Route
          path={'/home'}
          element={
            <ProtectedRoute redirectPath="/">
              <Home />
            </ProtectedRoute>
          }
        >
          <Route path={'/home'} element={<Navigate replace to="dashboard" />} />
          <Route path={'dashboard'} element={<Dashboard />} />
          <Route path={'dashboard/hub-setup'} element={<HubSetup />} />
          <Route path={'dashboard/business-reg'} element={<BusinessReg />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path={'subscription-plan'} element={<SelectedPlan />} />
          <Route
            path={'subscription/:deviceId/:deviceName'}
            element={<PlanList />}
          />
          <Route
            path={'subscription/:planId/:billingType'}
            element={<SelectedPlan />}
          />
          <Route path={'analytics'} element={<Analytics />} />
          <Route path="wallet" element={<Wallet />} />
          <Route
            path="wallet/transactionHistory"
            element={<TransactionHistory />}
          />{' '}
          <Route path="wallet/bank-transfer" element={<EnterAmount />} />
          <Route path="wallet/ussd" element={<USSDAmount />} />
          <Route
            path="wallet/withdrawal-bank-transfer"
            element={<WithdrawalEnterAmount />}
          />
          <Route path="wallet/user" element={<TransferDetails />} />
          <Route path="wallet/withdraw-P2P" element={<WithdrawP2P />} />
          <Route path="wallet/add-bank-details" element={<AddBankDetails />} />
          <Route path={'hub-form'} element={<HubForm />} />
          <Route path={'hub-form/form-template'} element={<ViewTemplate />} />
          <Route
            path={'hub-form/form-content/:formName/:formId?'}
            element={<FormContent />}
          />
          <Route path={'hub-form/detail'} element={<NetworkFeedbackForm />}>
            <Route
              path={'/home/hub-form/detail'}
              element={<Navigate replace to="content" />}
            />
            <Route path={':formId'} element={<Content />} />
            <Route path={'responses/:formId'} element={<Responses />} />
          </Route>
          <Route path={'hotspot-hubs'} element={<HotspotHubs />}>
            <Route
              path={'/home/hotspot-hubs'}
              element={<Navigate replace to="my-hubs" />}
            />
            <Route path={'my-hubs'} element={<MyHubs />} />
            <Route path={'live-session'} element={<LiveSession />} />
            <Route path={'hubs-report'} element={<HubsReport />} />
            <Route path={'managed-hubs'} element={<ManagedHubs />} />
          </Route>
          <Route
            path="hotspot-hubs/session-history"
            element={<SessionHistory />}
          />
          <Route
            path="hotspot-hubs/device-tracker"
            element={<DeviceTracker />}
          />
          <Route path="agents" element={<Agents />} />
          <Route path="token" element={<Token />} />
          <Route path={'parental-control'} element={<ParentalControl />} />
          <Route
            path="parental-control/:deviceId/:deviceName"
            element={<ParentalControlSetup />}
          />
          <Route
            path="parental-control/:deviceId/:deviceName/:category/:categoryId"
            element={<BlacklistedList />}
          />
          <Route path="cluster" element={<Cluster />} />
          <Route path={'advert-control'} element={<AdvertControl />}>
            <Route path="overview" element={<AdvertControlOverview />} />
            <Route path="share" element={<AdvertControlShare />} />
            <Route path="caption" element={<AdvertControlCaption />} />
          </Route>
          <Route path={'profile'} element={<Profile />}>
            <Route path="info" element={<ProfileInfo />} />
            <Route path="security" element={<Security />} />
          </Route>
          <Route path="profile/edit-contact" element={<EditNumber />} />
          <Route path="profile/security/2fa" element={<TwoFa />} />
          <Route path="profile/security/2fa-code" element={<TwoFaCode />} />
          <Route
            path="profile/security/reset-password"
            element={<ResetPassword />}
          />
          <Route path={'vouchers'} element={<Voucher />} />
          <Route path="profile/verifyID" element={<VerifyID />} />
          <Route
            path="agents/:businessUserId/:agentName/:businessId"
            element={<AgentsDetails />}
          />
          <Route
            path={'analytics/traffic/:trafficId/:analyticName/:trafficCategory'}
            element={<TrafficDetails />}
          />
          <Route path={'analytics'} element={<AnalyticsDetails />}>
            <Route
              path={'connections/:analyticId/:analyticName'}
              element={<Connections />}
            />
            <Route
              path={'uptime/:analyticId/:analyticName'}
              element={<Uptime />}
            />
            <Route
              path={'data-usage/:analyticId/:analyticName'}
              element={<DataUsage />}
            />
            <Route
              path={'speed/:analyticId/:analyticName'}
              element={<Speed />}
            />
            <Route
              path={'traffic/:analyticId/:analyticName'}
              element={<TrafficAnalytics />}
            />
          </Route>
          <Route
            path={'analytics/connections/:analyticId/:analyticName/:connectedUserId/:connectedUser'}
            element={<ConnectionDetails />}
          />
          <Route path="logOut" element={<LogOut />} />
        </Route>
        {/* Redirect to login page for unknown routes */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default App;
