import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import {
  apiSlice,
  clusterSlice,
  apiAdvertSlice,
} from '../features/api/apiSlice';
import authReducerSlice from '../features/store/authReducerSlice';
import walletReducerSlice from '../features/store/walletReducerSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [clusterSlice.reducerPath]: clusterSlice.reducer,
    [apiAdvertSlice.reducerPath]: apiAdvertSlice.reducer,
    auth: authReducerSlice,
    wallet: walletReducerSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiSlice.middleware,
      clusterSlice.middleware,
      apiAdvertSlice.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch);
