import React, { useRef, useEffect } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useLocation } from 'react-router';
import { ReactComponent as CloseIcon } from '../../assets/icons/hotspot-hubs/close.svg';

interface TableMainProps {
  defaultData: any;
  columns: any;
  showPagingation?: boolean;
  totalPages: number; //All the pages avalilable to the filter
  pageSize: number; //number of data pulled per request
  currentPage: number; //the page that is currently on display
  setPage: (page: number) => void; //Set page to the next page to view
  setShowTransDetails?: any;
}

const TableMain = ({
  defaultData,
  columns,
  showPagingation = true,
  totalPages,
  pageSize,
  currentPage,
  setPage,
  setShowTransDetails,
}: TableMainProps) => {
  const [data, setData] = React.useState(() => [...defaultData]);
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  useOutsideClick(modalRef, handleCloseModal);

  const handleButtonClick = (row: any) => {
    setSelectedRow(row);
    setModalVisible(true);
    console.log('Button clicked!', row);
  };

  useEffect(() => {
    setData([...defaultData]);
    table.setPageSize(pageSize);
  }, [defaultData, pageSize]);

  return (
    <div>
      <table className="table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => setShowTransDetails && setShowTransDetails(row)}
            >
              {row.getVisibleCells().map((cell, columnIndex) => (
                <td
                  key={cell.id}
                  className={`${
                    columnIndex === 0 || columnIndex > 5
                      ? 'hidden md:table-cell'
                      : ''
                  }`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}

              {row.getVisibleCells().length > 5 ? (
                <td className="md:hidden">
                  <button onClick={() => handleButtonClick(row)}>
                    View more {'>>'}
                  </button>
                </td>
              ) : (
                ''
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {isModalVisible && (
        <RowDetailsModal
          row={selectedRow}
          onClose={() => setModalVisible(false)}
          modalRef={modalRef}
        />
      )}

      {showPagingation && totalPages > 0 && (
        <div className="flex items-center justify-center gap-2">
          <button
            className={`${
              currentPage <= 1
                ? 'text-[#cacaca]' // Previous button disabled
                : 'text-primary' // Previous button active
            } text-[#cacaca]`}
            onClick={() => setPage(currentPage - 1)}
            disabled={currentPage <= 1}
          >
            {'<'}
          </button>
          {Array.from({ length: totalPages }, (_, index) => {
            const startIndex = currentPage - 2;
            const endIndex = currentPage + 2;
            if (index >= startIndex && index <= endIndex) {
              return (
                <button
                  key={index}
                  className={`text-[#cacaca] text-x10 ${
                    index + 1 === currentPage
                      ? 'text-primary font-bold' // Use a different color for the current page
                      : ''
                  }`}
                  onClick={() => setPage(index + 1)}
                >
                  {index + 1}
                </button>
              );
            }

            return null; // Render nothing for other buttons
          })}
          <button
            className={`${
              currentPage >= totalPages
                ? 'text-[#cacaca]' // Next button disabled
                : 'text-primary' // Next button active
            } text-[#cacaca]`}
            onClick={() => setPage(currentPage + 1)}
            disabled={currentPage >= totalPages}
          >
            {'>'}
          </button>

          {/* <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="w-16 p-1 border rounded"
          />
        </span> */}
          {/* <select
            value={pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="hidden"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select> */}
          {/* <div>{table.getRowModel().rows.length} Rows</div> */}
        </div>
      )}
    </div>
  );
};

export default TableMain;

interface RowProps {
  row: any;
  onClose: () => void;
  modalRef: any;
}

const RowDetailsModal: React.FC<RowProps> = ({ row, onClose, modalRef }) => {
  const location = useLocation();

  const getTitleFromPathname = (pathname: string) => {
    const parts = pathname.split('/');
    const extractedTitle = parts
      ? [parts.length - 1].toString().replace(/-/g, ' ')
      : '';
    const words = extractedTitle.split(' ');
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(' ');
  };
  const title = getTitleFromPathname(location.pathname);

  return (
    <section
      className="fixed inset-0 z-20 h-screen overflow-hidden md:hidden bg-[#00000033] dark:bg-[#00000080] flex justify-center items-center"
      ref={modalRef}
    >
      <div className="bg-clrBg2 dark:bg-clrDarkBg2 px-4 py-6 z-30 rounded-xl w-[90%] md:hidden">
        <div className="flex justify-between">
          <h2 className="text-sm font-semibold text-black dark:text-white">
            {title}
          </h2>
          <button onClick={onClose}>
            <CloseIcon className="w-[13px] h-[13px] self-end dark-close" />
          </button>
        </div>
        <table className="px-1 py-4 bg-white table-modal dark:bg-clrDarkBg">
          <tbody>
            {row.getVisibleCells().map((cell: any) => (
              <tr
                key={cell.id}
                className="flex justify-between items-center border-b-[0.5px] border-solid border-[#BEBCC7]"
              >
                <th className="text-xs font-normal text-[#56516F] dark:text-clrPlaceholder">
                  {typeof cell.column.columnDef.header === 'function'
                    ? cell.column.columnDef.header()
                    : cell.column.columnDef.header}
                </th>
                <td className="text-xs font-semibold text-black dark:text-white dark:font-medium">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};
