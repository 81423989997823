import { InputBoxProps } from "./types";

export const InputBox = ({
  title,
  name,
  type,
  id,
  value,
  handleChange,
  placeholder,
}: InputBoxProps) => {
  return (
    <div>
      <label htmlFor={name} className="text-sm font-semibold text-black">
        {title}
      </label>
      <input
        type={type}
        id={id}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className="w-full border-b-2 border-solid border-primary mt-1 font-medium text-[12px] md:text-sm pb-1 focus:outline-none"
      />
    </div>
  );
};
