import React, { useState } from 'react';
import {
  InputBox,
  Card,
  ButtonAuth,
  CardTitle,
  ErrorMsg,
  StatusPage,
} from '../reusables';
import { Link, useNavigate } from 'react-router-dom';
import { validateEmail } from '../../helper';
import { useInitialiseResetMutation } from '../../features/api/authSlice';
import AuthFrame from '../reusables/AuthFrame';

const Reset = () => {
  const [isError, setError] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) {
      setError('');
    }
    setEmail(e.target.value);
  };

  const [initialiseReset, { data, isLoading }] = useInitialiseResetMutation();

  /**
   * Handles the form submission event.
   *
   * @param e - The mouse event associated with the button click.
   */
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // Validate the email address
    if (!validateEmail(email)) {
      setError('Please enter a valid email');
    } else {
      setError('');
      initialiseReset({ email: email });
    }
  };

  return (
    <div className="flex">
      <Card>
        {data?.success ? (
          <div className="px-5">
            <StatusPage
              title={
                data?.data?.is2FaEnabled
                  ? 'Two-Factor Authentication'
                  : 'Email Sent!'
              }
              user={email}
              msg={
                data?.data?.is2FaEnabled
                  ? 'Please enter your 6-digit authentication code from the Google Authenticator app on the next page to continue.'
                  : 'An email has been sent to you, enter the OTP in the email on the next page to continue'
              }
            />

            <div className="mx-auto mb-10 w-[70%]">
              <ButtonAuth
                type="submit"
                loading={isLoading}
                handleClick={() => navigate(`/passwords/reset?email=${email}`)}
                text="Continue"
              />
            </div>
          </div>
        ) : (
          <div className="flex items-center flex-col min-[426px]:w-[350px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
            <div className="text-center md:text-left mb-7">
              <CardTitle title="Reset PIN" />
              <p className="text-clrText font-semibold text-[0.625rem] md:text-sm mb-12">
                Enter your email, and we'll send you instructions on how to
                reset your PIN.
              </p>
            </div>
            <div className="w-full">
              <InputBox
                type="email"
                title="Email Address"
                name="email"
                id="email"
                value={email}
                handleChange={handleChange}
              />
              <ErrorMsg errMsg={isError ? isError : data?.message ?? ''} />
            </div>

            <div className="mt-12 w-[90%]">
              <ButtonAuth
                type="submit"
                loading={isLoading}
                handleClick={handleSubmit}
                text="Send me instructions"
              />
            </div>
            <div className="mb-10 mt-14">
              <Link to="/"> ← Back to Login </Link>
            </div>
          </div>
        )}
      </Card>
      <AuthFrame />
    </div>
  );
};

export default Reset;
