import { useState } from "react";
import { useGetDevicesQuery } from "../../features/api/deviceSlice";
import { Button, ErrorMsg, TableLoader } from "../reusables";
import { Modal } from "../reusables/Modal";
import { AssignAgentProps, DeleteModalProps, InviteStaffProps } from "./types";
import { ReactComponent as Delete } from "../../assets/delete.svg";


// interface CheckboxState {
//   [id: string]: boolean;
// }

export const AssignDevice = ({
  handleModal,
  // handleSubmit,
  isLoading,
  errMsg,
}: AssignAgentProps) => {
  // const [isCheckboxChecked, setIsCheckboxChecked] = useState<CheckboxState>({
  //   id: true,
  // });
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { data: allHubs, isLoading: gettingHubs } = useGetDevicesQuery({
    deviceName: "",
    deviceStatus: "",
    page: 1,
    pageSize: 100
  });

    // Function to handle checkbox toggle
    const handleChecked = (id: string) => {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.includes(id)
          ? prevSelectedIds.filter((selectedId) => selectedId !== id)
          : [...prevSelectedIds, id]
      );
    };

    // Function to handle "Select all" button click (and when clicked again unselect them)
    const handleSelectAll = () => {
      if (allHubs && allHubs?.data && allHubs?.data?.records) {
        const allIds = allHubs?.data?.records.map((hub) => hub.id);
        // Check if all checkboxes are already selected
        const allSelected = allIds.every((id) => selectedIds.includes(id));
        if (allSelected) {
          // If all are selected, unselect all
          setSelectedIds([]);
        } else {
          // If not all are selected, select all
          setSelectedIds(allIds);
        }
      }
    };
    

  // const handleChecked = (id: string) => {
  //   setIsCheckboxChecked((prevState) => ({
  //     ...prevState,
  //     [id]: !prevState[id],
  //   }));
  // };

  // const handleSelectAll = () => {
  //   setIsCheckboxChecked({});
  //   if (allHubs) {
  //     for (let i = 0; i < allHubs?.data?.records.length; i++) {
  //       handleChecked(allHubs?.data?.records[i].id);
  //     }
  //   }
  // };

  const handleSave = () => {
    // const selectedIds = Object.keys(isCheckboxChecked).filter(
    //   (id) => isCheckboxChecked[id]
    // );
    //handleSubmit(selectedIds);
  };

  return (
    <Modal
      close={true}
      title={"Assign new Hubs"}
      description={"Assign new hubs to this user"}
      handleCloseModal={handleModal}
    >
      <section>
        <div className="bg-white dark:bg-clrDarkBg rounded-[20px] pb-5">
          <div className="py-5 flex justify-between border-b-[0.0625rem] border-solid border-clrBorder3 ">
            <h4 className="pl-6 font-semibold text-[#212121] dark:text-white text-xs ">
              Select Hubs
            </h4>
            {allHubs?.data && allHubs?.data?.records?.length > 0 && (
              <button
                className="pr-6 text-xs font-semibold text-clrDarkYellow "
                //onClick={() => handleSelectAll()}
                onClick={handleSelectAll}
              >
                {selectedIds.length === allHubs?.data?.records?.length
                  ? "De-select all"
                  : "Select all"}
              </button>
            )}
          </div>
          <div className="px-6 pt-5 space-y-6">
            {gettingHubs ? (
              <TableLoader />
            ) : (
              allHubs?.data?.records?.map((hubs) => (
                <label
                  key={hubs?.id}
                  className="material-checkbox text-[10px] font-normal text-black"
                >
                  {hubs?.name}
                  <input
                    type="checkbox"
                    //checked={isCheckboxChecked[hubs?.id]}
                    //onChange={() => handleChecked(hubs?.id)}
                    checked={selectedIds.includes(hubs?.id)}
                    onChange={() => handleChecked(hubs?.id)}
                  />
                  <span className="checkmark"></span>
                </label>
              ))
            )}
          </div>
          <ErrorMsg errMsg={errMsg} />
        </div>
        {!isLoading && (allHubs?.data?.records?.length ?? 0) > 0 && (
          <div className="w-4/5 mx-auto mt-6 [&>*]:tracking-wider">
            <Button
              type="button"
              text="Save"
              handleClick={handleSave}
              textSize="text-x10"
              loading={isLoading}
              py="py-2"
              fontWeight="font-semibold"
            />
          </div>
        )}
      </section>
    </Modal>
  );
};

export const InviteStaff = ({
  handleModal,
  isLoading,
  errMsg,
  handleSubmit,
}: InviteStaffProps) => {
  const [agentEmail, setAgentEmail] = useState<string>("");

  return (
    <Modal
      close={true}
      handleCloseModal={handleModal}
      title={"Create Agent"}
      description={
        "Send an email invitation to a new admin to enable them access your hub"
      }
    >
      <div className="">
        <div className="p-6 bg-white rounded dark:bg-clrDarkBg">
          <div>
            <label
              htmlFor="email"
              className="block font-semibold text-black dark:text-white text-x10"
            >
              Email Address
            </label>
            <input
              type="text"
              id="email"
              onChange={(e) => setAgentEmail(e.target.value)}
              value={agentEmail}
              placeholder="example@useremail.com"
              className="w-full dark:bg-transparent font-semibold text-x10 text-clrGray43 placeholder:text-x8 dark:placeholder:!text-clrPlaceholder placeholder:font-medium placeholder:!text-[#cecece] opacity-80 border-b-[0.7px] border-solid border-clrBorder5 outline-none"
            />
            <p className="mt-1 font-medium text-[#707070] dark:text-clrPlaceholder text-x9">
              Email address of the new agent
            </p>
          </div>
          <ErrorMsg errMsg={errMsg} />
        </div>
        <div className="w-4/5 mx-auto mt-8 [&>*]:tracking-wider">
          <Button
            type="button"
            text="Send Invite"
            loading={isLoading}
            handleClick={() => handleSubmit({ email: agentEmail })}
            textSize="text-x10"
            py="py-2.5"
            fontWeight="font-semibold"
          />
        </div>
      </div>
    </Modal>
  );
};

export const DeleteModal = ({
  handleModal,
  title,
  message,
  handleSubmit,
  isLoading,
  errMsg,
}: DeleteModalProps) => {
  return (
    <Modal
      close={true}
      handleCloseModal={handleModal}
    >
      <div className="pt-6">
        {message && 
        <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg dark:bg-clrDarkBg">
          <div className="flex items-center justify-center w-[4.5rem] h-[4.5rem] mb-4 rounded-full bg-clrRed3 bg-opacity-20">
            <Delete className="w-9 h-9"/>
          </div>
          <h2 className="mb-6 text-xl font-semibold text-black">{title}</h2>
          <h3 className="mb-6 text-sm font-normal text-center text-clrGray dark:text-white w-[80%] mx-auto">
            {message}
          </h3>
        </div>}
        <div className="flex items-center justify-center">
          <ErrorMsg errMsg={errMsg} />
        </div>
        <div className="w-4/5 mx-auto mt-8 [&>*]:tracking-wider">
          <Button
            type="button"
            text={title}
            loading={isLoading}
            handleClick={handleSubmit}
            textSize="text-xs"
            py="py-2.5"
            fontWeight="font-semibold"
            bgColor="bg-clrRed3"
          />
        </div>
      </div>
    </Modal>
  );
};
