import { useRef } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/hotspot-hubs/close.svg";
import useOutsideClick from "../../hooks/useOutsideClick";

export const ImagePreview = ({ image, handleCloseModal }: { image: string, handleCloseModal: () => void }) => {

    const modalRef = useRef<HTMLElement>(null);
    useOutsideClick(modalRef, handleCloseModal);

    return (
        <section
            className="fixed inset-0 z-50 h-screen overflow-hidden bg-[#F4F4F4] md:bg-[#00000033] dark:md:bg-[#00000080] dark:bg-clrDarkBg2 flex justify-center md:items-center"
            ref={modalRef}
        >
            <div className="bg-[#1A1A1A] py-6 z-30 rounded-xl w-11/12 px-8 lg:w-[900px] mx-auto">
                <div className="mb-10">
                    <div className="flex items-center justify-between pb-10">
                        <h1 className="text-base font-medium text-white">
                            Preview Form here
                        </h1>

                        <button onClick={handleCloseModal} className="flex items-center justify-center w-10 h-10 bg-white rounded-full">
                            <CloseIcon className="w-[13px] h-[13px] dark-close" />
                        </button>
                    </div>
                    <section className="flex items-center justify-center md:w-[685px] mx-auto">
                        <div>
                            <img src={image} className="w-full h-[400px]" alt="downloaded img" />
                        </div>
                    </section>
                </div>

            </div>
        </section>
    );
};