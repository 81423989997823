import { useState } from "react";
import TimerIcon from "../../assets/timer.svg";
import { useSetRestrictionTimeMutation, useUpdateBlacklistedAddressMutation } from "src/features/api/parentalControlSlice";
import { Button, ErrorMsg } from "../reusables";
import { Modal } from "../reusables/Modal";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-Yellow.svg";
import { SuccessModalProps } from "./types";
import { useParams } from "react-router-dom";

// Add addresses to blacklist for a device
export const BlacklistAddress = ({
    deviceId,
    handleModal,
    handleSuccessModalOpen,
    handlePlatformUpdate,
}: { handleModal: () => void, deviceId: string, handleSuccessModalOpen: () => void, handlePlatformUpdate: (platformUpdate: string) => void }) => {
    const {categoryId} = useParams();
    const [websiteUrl, setWebsiteUrl] = useState<string>("");
    const [platform, setPlatform] = useState<string>("");
    const [errMsg, setErrMsg] = useState<string>("");

    const [trigger, { isLoading }] = useUpdateBlacklistedAddressMutation();

    const handleAddress = () => {
        setErrMsg("")
        let data = {
            deviceId,
            payload: {
                platform: platform,
                address: websiteUrl,
                categoryId: categoryId as string,
            }
        }

        if (websiteUrl) {
            trigger(data)
                .unwrap()
                .then((res) => {
                    if(res.success) {
                        handleModal()
                        handleSuccessModalOpen();
                        handlePlatformUpdate(platform);
                    }
                })
                .catch((err) => {
                    setErrMsg(err?.data?.message)
                    // Add error modal when provided
                })
        }
    }

    return (
        <Modal
            close={true}
            handleCloseModal={handleModal}
            title={"Add URL Address"}
        >
            <div className="">
                <div className="p-6 bg-white rounded dark:bg-clrDarkBg">
                    <div className="mb-2">
                        <label
                            htmlFor="email"
                            className="block font-semibold text-black dark:text-white text-x10"
                        >
                            Platform
                        </label>
                        <input
                            type="text"
                            id="email"
                            onChange={(e) => setPlatform(e.target.value)}
                            value={platform}
                            className="w-full dark:bg-transparent font-semibold text-x10 text-clrGray43 placeholder:text-x8 dark:placeholder:!text-clrPlaceholder placeholder:font-medium placeholder:!text-[#cecece] opacity-80 border-b-[0.7px] border-solid border-clrBorder5 outline-none"
                        />
                    </div>
                    <div className="mb-2">
                        <label
                            htmlFor="website-url"
                            className="block font-semibold text-black dark:text-white text-x10"
                        >
                            Website Url
                        </label>
                        <input
                            type="text"
                            id="website-url"
                            onChange={(e) => setWebsiteUrl(e.target.value)}
                            value={websiteUrl}
                            placeholder="http://"
                            className="w-full dark:bg-transparent font-semibold text-x10 text-clrGray43 placeholder:text-x8 dark:placeholder:!text-clrPlaceholder placeholder:font-medium placeholder:!text-[#cecece] opacity-80 border-b-[0.7px] border-solid border-clrBorder5 outline-none"
                        />
                    </div>
                    <ErrorMsg errMsg={errMsg} />
                </div>
                <div className="w-4/5 mx-auto mt-8 [&>*]:tracking-wider">
                    <Button
                        type="button"
                        text="Add"
                        loading={isLoading}
                        handleClick={handleAddress}
                        textSize="text-x10"
                        py="py-2.5"
                        fontWeight="font-semibold"
                        disabled={!websiteUrl || !platform ? true : false}
                    />
                </div>
            </div>
        </Modal>
    );
};


//Schedule timer for restricting access for this device
export const ScheduleTimer = ({
    deviceId,
    handleModal,
    handleSuccessModalOpen,
    handleStartTimeUpdate,
    handleStopTimeUpdate,
}: { handleModal: () => void, deviceId: string, handleSuccessModalOpen: () => void, handleStartTimeUpdate: (newStartTime: string) => void, handleStopTimeUpdate: (newStopTime: string) => void }) => {
    const [startTime, setStartTime] = useState<string>("");
    const [stopTime, setStopTime] = useState<string>("");
    const [start24StartTime, set24StartTime] = useState<string>("");
    const [stop24StopTime, set24StopTime] = useState<string>("");
    const [startDropdown, setStartDropdown] = useState<boolean>(false);
    const [endDropdown, setEndDropdown] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>("");

    const [trigger, { isLoading }] = useSetRestrictionTimeMutation();

    const scheduleRestrictionTime = () => {
        if (!start24StartTime || !stop24StopTime) {
            setErrMsg("Please enter a valid time")
        } else if (start24StartTime > stop24StopTime) {
            setErrMsg("Start time cannot be greater than stop time")
        } else {
            setErrMsg("");
            let data = {
                deviceId: deviceId as string,
                payload: {
                    enableRestrictionTimer: true,
                    startPeriod: start24StartTime,
                    endPeriod: stop24StopTime
                }
            }
            trigger(data)
                .unwrap()
                .then((res) => {
                    if(res.success) {
                        handleModal();
                        handleSuccessModalOpen();
                        handleStartTimeUpdate(startTime);
                        handleStopTimeUpdate(stopTime);
                    }
                })
                .catch((err) => {
                    setErrMsg(err?.data?.message)
                })
        }

    }

    const handleSetTimer = (type: string, selectedTime: string) => {
        // Parse selected time to extract hour and minute
        const [time, period] = selectedTime.split(' ');
        const [hourString, minuteString]: any = time?.split(':');
        const hour = parseInt(hourString);
        const minute = parseInt(minuteString);
    
        // Convert to 24-hour clock if it's PM and not 12 PM
        let hour24 = hour;
        if (period === 'PM' && hour !== 12) {
            hour24 += 12;
        } else if (period === 'AM' && hour === 12) {
            hour24 = 0;
        }
    
        // Pad single-digit hour with a leading zero
        const hour24String = hour24 < 10 ? `0${hour24}` : `${hour24}`;
        const minuteStringPadded = minute < 10 ? `0${minute}` : `${minute}`;
    
        // Set the selected time and 24-hour format time based on the type
        if (type === "start") {
            setStartTime(selectedTime);
            set24StartTime(`${hour24String}:${minuteStringPadded}:00`);
        } else {
            setStopTime(selectedTime);
            set24StopTime(`${hour24String}:${minuteStringPadded}:00`);
        }
    
        // Close both dropdowns
        setStartDropdown(false);
        setEndDropdown(false);
    };
    


    const renderTime = (type: string) => {
        const clock = [];
        for (let i = 0; i <= 24; i++) {
            const hour = i % 12 === 0 ? 12 : i % 12;
            const period = i < 12 ? 'AM' : 'PM';
            for (let j = 0; j < 4; j++) {
                const minute = j * 15;
                const formattedHour = `${hour < 10 ? '0' : ''}${hour}:${minute === 0 ? '00' : minute}`;
                clock.push(`${formattedHour} ${period}`);
            }
        }

        return (
            <div className="absolute z-30 w-full p-3 pb-4 overflow-auto bg-white rounded dark:bg-clrDarkBg max-h-40">
                <ul className="space-y-6 text-xs font-normal text-[#6B6B6B] dark:text-clrPlaceholder">
                    {clock.map((time, index) => (
                        <li
                            key={index}
                            className="flex items-center gap-2 cursor-pointer hover:text-clrlightGray"
                            onClick={(e) => { e.stopPropagation(); handleSetTimer(type, time) }}
                        >
                            {time}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };


    return (
        <Modal
            close={true}
            handleCloseModal={handleModal}
            title={"Schedule Timer"}
        >
            <div className="">
                <div className="p-6 bg-white rounded dark:bg-clrDarkBg">
                    <div className="flex items-center gap-2 mb-8">
                        <div className="flex justify-center items-center bg-[#E5B91033] rounded-full h-8 w-8 aspect-square">
                            <img src={TimerIcon} alt="timer" />
                        </div>
                        <p className="text-xs"> Will restrict internet access from {startTime} to {stopTime}</p>
                    </div>
                    <div className="flex justify-between gap-6 flex-end md:px-4 md:gap-10">
                        <div>
                            <div className="mb-4">
                                <p className="text-sm font-semibold text-clrGray"> Starting time</p>
                            </div>
                            <div className="flex items-center gap-4 mb-2">
                                <div className="mb-2">
                                    <div className="relative cursor-pointer" onClick={() => setStartDropdown(!startDropdown)}>
                                        <input
                                            type="text"
                                            id="start-time"
                                            value={startTime}
                                            readOnly
                                            className="w-full cursor-pointerdark:bg-transparent font-normal text-xs pb-1.5 text-black placeholder:text-x8 dark:placeholder:!text-clrPlaceholder placeholder:font-medium placeholder:!text-[#cecece] opacity-80 border-b-2 border-solid border-primary outline-none"
                                        />
                                        <ArrowDown className="absolute right-0 top-[10%] cursor-pointer" width={20} height={20} />
                                        {startDropdown && renderTime("start")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="mt-8 text-xs text-clrTextLightGray"> to</p>
                        </div>
                        <div>
                            <div className="mb-4">
                                <p className="text-sm font-semibold text-clrGray"> End time</p>
                            </div>
                            <div className="flex items-center gap-4 mb-2" >
                                <div className="mb-3">
                                    <div className="relative cursor-pointer" onClick={() => setEndDropdown(!endDropdown)}>
                                        <input
                                            type="text"
                                            id="end-time"
                                            value={stopTime}
                                            readOnly
                                            className="w-full cursor-pointer dark:bg-transparent font-normal pb-1.5 text-xs text-clrGray43 placeholder:text-x8 dark:placeholder:!text-clrPlaceholder placeholder:font-medium placeholder:!text-[#cecece] opacity-80 border-b-2 border-solid border-primary outline-none"
                                        />
                                        <ArrowDown className="absolute right-0 top-[10%] cursor-pointer" width={20} height={20} />
                                        {endDropdown && renderTime("end")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ErrorMsg errMsg={errMsg} />
                </div>
                <div className="w-4/5 mx-auto mt-8 [&>*]:tracking-wider">
                    <Button
                        type="button"
                        text="Confirm"
                        loading={isLoading}
                        handleClick={scheduleRestrictionTime}
                        textSize="text-x10"
                        py="py-2.5"
                        fontWeight="font-semibold"
                    />
                </div>
            </div>
        </Modal>
    );
};

// When the set timer is successful
export const TimerSuccessfulModal = ({
    handleModal,
    title,
    btnText,
    description,
    isLoading,
    icon
    }: SuccessModalProps) => {
    return (
        <Modal
            handleCloseModal={handleModal}
        >
            <div className="pt-6">
                <div className="flex flex-col items-center justify-center p-6 bg-white rounded-lg dark:bg-clrDarkBg">
                    <div className="flex items-center justify-center w-[4.5rem] h-[4.5rem] mb-4 rounded-full bg-primary bg-opacity-20">
                        <img src={icon ? icon : TimerIcon} alt="timer" className="w-10 h-10" />
                    </div>
                    <h2 className="mb-6 text-xl font-semibold text-black">{title}</h2>
                    <h3 className="mb-6 text-sm font-normal text-center text-clrGray dark:text-white w-[80%] mx-auto">
                        {description}
                    </h3>
                </div>

                <div className="w-4/5 mx-auto mt-8 [&>*]:tracking-wider">
                    <Button
                        type="button"
                        text={btnText}
                        handleClick={handleModal}
                        textSize="text-xs"
                        py="py-2.5"
                        fontWeight="font-semibold"
                        bgColor="bg-primary"
                        loading={isLoading}
                    />
                </div>
            </div>
        </Modal>
    );
};


