import { useState } from "react";
import { Button, EmptyCard, TableLoader } from "../reusables";
import { useNavigate } from "react-router";
import { ReactComponent as ChevronRightIcon } from "../../assets/icons/devices-chevron-right.svg";
import { useGetParentalControlDevicesQuery, useToggleParentalControlMutation} from "src/features/api/parentalControlSlice";
import { ControlDevices } from "src/features/api/parentalControlSliceType";
import NoParentalControl from "./NoParentalControl";
import { useAppSelector } from "src/app/hooks";
import { useGetActivePackageQuery } from "src/features/api/subscriptionSlice";


const Control = () => {
    const [hubName, saveHubName] = useState("");
    const { userId } = useAppSelector((state) => state.auth);
    const { data: activePackage, isLoading: gettingActivePackage } = useGetActivePackageQuery(userId);
    const { data: devices, isLoading: gettingAllHubs } = useGetParentalControlDevicesQuery({ isEnabled: null, Search: hubName, Page: 1, PageSize: 30 });

    // Calculate the length of device if available
    const deviceLength = devices?.data?.records?.length || 0;

    return (
        <section className="w-[96%] mx-auto mt-3 mb-6 h-full">
            <div className="px-4 pt-2.5 pb-20 bg-white dark:bg-clrDarkBg rounded-lg md:px-10">
                    {gettingActivePackage ? (
                        <TableLoader />
                        ) : activePackage !== undefined || activePackage !== null ? (
                            <div>
                                <div className="flex flex-wrap w-full mt-4 gap-x-3 gap-y-7 md:w-auto">
                                    <div className="flex-1 md:flex-auto ">
                                        <label
                                            htmlFor="hubname"
                                            className="hidden mb-2 font-semibold md:block text-x10 md:text-xs text-clrGray dark:text-clrPlaceholder"
                                        >
                                            Hub Name
                                        </label>
                                        <input
                                            type="text"
                                            name="hubname"
                                            id=""
                                            placeholder="Search Name"
                                            value={hubName}
                                            onChange={(e) => saveHubName(e.target.value)}
                                            className="w-full md:w-52 text-xs font-medium text-clrGray border-[1px] border-solid border-clrPlaceholder dark:border-clrGray rounded-3xl h-7 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x10 bg-inherit"
                                        />
                                    </div>
                                </div>

                                <div className="mt-9">
                                    <h3 className="text-sm font-medium text-clrGray dark:text-clrPlaceholder">
                                        Total Hubs <span>({devices?.data?.totalRecords})</span>
                                    </h3>
                                    { gettingAllHubs ? (
                                        <TableLoader />
                                        ) : deviceLength > 0 ? (
                                        <aside className="grid grid-cols-1 gap-3 pb-10 my-5 bg-white rounded-lg dark:bg-clrDarkBg md:grid-cols-3 md:gap-5">
                                            {devices?.data?.records.map((device) => {
                                                return (
                                                    <Hubs
                                                        key={device?.deviceId}
                                                        device={device}
                                                    />
                                                );
                                            })}
                                        </aside>
                                        ) : (
                                            <EmptyCard description={"No Hubs Available"} />
                                        )
                                    }
                                </div>
                            </div>
                        ) : (
                        <NoParentalControl />
                        )
                    }
            </div>
        </section>
    );
};


const Hubs = ({device}: {device: ControlDevices} ) => {
    const navigate = useNavigate();

    const [trigger, {isLoading: toggleParentalControl}] = useToggleParentalControlMutation()

    const handleParentalControl = () =>{
        let data = {
            deviceId: device.deviceId,
            payload: {
                isParentalControlEnabled: !device.isParentalControlEnabled
            }
        }
        trigger(data)
        .unwrap()
        .then((res)=>{
            console.log(res, 'i am the res')
        })
        .catch((err)=>{
            console.log(err, 'i am error message')
        })
    }
    return (
        <>
            <div className="grid grid-cols-3 gap-3 md:gap-4 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-2 md:p-4 shadow-wxl">
                <img
                    src={device.imageUrl}
                    alt={device.deviceName}
                    className="object-cover rounded "
                />
                <div className="flex flex-col justify-between flex-1 h-full col-span-2">
                    <div className="flex items-center justify-between pt-1 lg:pt-1.5">
                        <p className="font-medium md:font-semibold text-x10 md:text-xs text-clrTextGray dark:text-white">
                            {device?.deviceName.length > 15 ? `${device?.deviceName.slice(0, 15)}...` : device?.deviceName}
                        </p>
                    </div>
                    {device.isParentalControlEnabled &&
                    <button
                        onClick={() =>
                            navigate(`/home/parental-control/${device.deviceId}/${device.deviceName}`)
                        }
                        className="self-end"
                    >
                        <ChevronRightIcon />
                    </button>}
                    <div className="justify-items-end md:w-[80%] mt-3 pb-1 lg:pb-1.5">
                        {device.isParentalControlEnabled ? (
                            <Button
                                text="Deactivate Control"
                                handleClick={handleParentalControl}
                                type="button"
                                wBtn="w-fit"
                                fontWeight="font-normal"
                                py="py-1"
                                textSize="text-x8"
                                bgColor="bg-clrRed"
                                loading={toggleParentalControl}
                            />
                        ) : (
                            <Button
                                handleClick={handleParentalControl}
                                text="Activate Control"
                                type="button"
                                wBtn="w-fit"
                                fontWeight="font-normal"
                                py="py-1"
                                textSize="text-x8"
                                loading={toggleParentalControl}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};


export default Control;

