import { apiSlice } from "./apiSlice";
import { GenerateVoucherRequest, VoucherSliceRequest } from "./voucherSliceTypes";

interface SuccessResponse {
    success: boolean,
    data: [],
    errors: string[],
    message: string
}

interface ListSuccessResponse {
    success: boolean,
    data: {
        records: [],
        pageSize: number,
        currentPage: number,
        totalPages: number,
        totalRecords: number
    },
    errors: [],
    message: string
}

const voucherSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get Vouchers for a business
        getVouchers: build.query<ListSuccessResponse, VoucherSliceRequest>({
            query: ({ voucherStatus, page, pageSize, businessId, startDateTimestamp, endDateTimeStamp, voucherCode }) => `/v2/vouchers/business?voucherStatus=${voucherStatus}&page=${page}&pageSize=${pageSize}&businessId=${businessId}&startDateTimestamp=${startDateTimestamp}&endDateTimeStamp=${endDateTimeStamp}&voucherCode=${voucherCode}`,
            providesTags: ['Vouchers']
        }),

        //Generate Voucher
        generateVoucher: build.mutation<SuccessResponse, Partial<GenerateVoucherRequest>>({
            query: (payload) => ({
                url: `/v2/vouchers`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: ["Vouchers"]
        }),
    }),
    overrideExisting: true,
})

export const { useGetVouchersQuery, useGenerateVoucherMutation } = voucherSlice