import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController
);

interface UptimeData {
  timePeriod: string;
  uptime?: string;
  dataUsed?: string;
  dataUsedInBytes?: number;
  uptimeInSeconds?: number;
}

interface ChartData {
  labels: string[];
  data: UptimeData[];
}

export interface BarChartProps {
  chartData: ChartData;
}

export const BarChart: React.FC<BarChartProps> = ({ chartData }) => {
  const data = {
    labels: chartData.labels || [],
    datasets: [
      {
        label: "Uptime",
        backgroundColor: "rgba(255, 203, 5, 1)",
        borderColor: "rgba(255, 203, 5, 1)",
        borderWidth: 1,
        data: chartData.data.map((item) =>
          item.uptimeInSeconds !== undefined
            ? item.uptimeInSeconds / 3600 // Convert seconds to hours
            : item.dataUsedInBytes !== undefined
            ? item.dataUsedInBytes / (1024 ** 3) // Convert bytes to GB
            : null
        )
      },
    ]
  };

  const options: ChartOptions<'bar'> = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const dataPoint = chartData.data[context.dataIndex];
         
            if (!dataPoint) {
              return "Data not available";
            }
 
            const uptime = dataPoint.uptime ? `Uptime: ${dataPoint.uptime}` : "";
            const dataUsage = dataPoint.dataUsedInBytes !== undefined ? `Data Used: ${dataPoint.dataUsed}` : "";
            return `${uptime} ${dataUsage}`;
          }
        }
      }
    },
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 10,
        bottom: 5
      }
    },
    scales: {
      y: {
        type: 'linear' as const,
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            if (typeof value !== 'number') {
              return value;
            }
            if (chartData.data.some(item => item.uptimeInSeconds !== undefined)) {
              return `${value} hours`;
            } else if (chartData.data.some(item => item.dataUsedInBytes !== undefined)) {
              return `${value.toFixed(2)} GB`;
            }
            return value;
          },
          padding: 15,
        },
        border: { dash: [6, 6], display: true },
        grid: {
          display: true
        },
      },
      x: {
        type: 'category' as const,
        border: { display: true },
        grid: {
          display: false
        },
        ticks: {
          padding: 7
        }
      }
    },
    elements: {
      bar: {
        borderRadius: 40,
        borderWidth: 0.7
      }
    }
  };

  return (
    <div className="w-full">
      <Bar data={data} options={options} />
    </div>
  );
}
