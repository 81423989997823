import { useRef, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import TableMain from '../../reusables/ReactDataTable';
import { useGetTransferTransactionHistoryQuery } from '../../../features/api/walletSlice';
import { columnsTransfer } from '../../hotspotHubs/ReactTableDataList';
import { MobileFilterSearch } from '../../hotspotHubs/OptionsStatus';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { EmptyCard, Filter, TableLoader } from '../../reusables';

interface TransationProps {
  show: boolean;
  showFilter?: boolean;
  setShowTransDetails?: any;
}

const Transfer = ({
  show,
  showFilter,
  setShowTransDetails,
}: TransationProps) => {
  const { userId } = useAppSelector((state) => state.auth);
  const [option, toggleOption] = useState<string>('');
  const [status, setStatus] = useState<string>('All');
  const [duration, setDuration] = useState<string>('All');
  const [reference, setReference] = useState<string>('');
  const modalRef = useRef<HTMLElement>(null);
  const modalRefDuration = useRef<HTMLElement>(null);
  const [page, setPage] = useState<number>(1);

  const handleCloseModal = () => {
    toggleOption('');
  };

  useOutsideClick(modalRef, handleCloseModal);
  useOutsideClick(modalRefDuration, handleCloseModal);

  const {
    data: history,
    isLoading: gettingHistory,
    isFetching,
  } = useGetTransferTransactionHistoryQuery({
    userId,
    page,
    pageSize: 30,
    status: status === 'All' ? '' : status,
    currencyCode: '',
    reference,
    duration,
  });

  if (!show) return <></>;
  return (
    <div>
      {showFilter && (
        <div className="mb-3">
          <div className="flex w-full gap-3 md:px-4 md:w-auto">
            <MobileFilterSearch>
              <Filter
                options={[
                  'All',
                  'LastWeek',
                  'LastMonth',
                  'Last3Months',
                  'LastYear',
                ]}
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={'DURATION'}
                selectFilterOption={setDuration}
                selectedOption={duration}
                modalRef={modalRef}
                label={'Duration'}
              />
              <Filter
                options={['All', 'Pending', 'Confirmed', 'Failed']}
                toggleFilter={toggleOption}
                selectedFilterOption={option}
                currentFilterOption={'STATUS'}
                selectFilterOption={setStatus}
                selectedOption={status}
                modalRef={modalRef}
                label={'Status'}
              />
            </MobileFilterSearch>
            <div className="flex-1 md:flex-auto ">
              <label
                htmlFor=""
                className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
              >
                Reference
              </label>
              <input
                type="text"
                name=""
                id=""
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                placeholder="Enter Reference ID"
                className="w-full md:w-fit text-xs font-medium text-clrGray border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:text-clrPlaceholder placeholder:text-[10px] bg-inherit"
              />
            </div>
          </div>
        </div>
      )}
      <div>
        {gettingHistory || isFetching ? (
          <TableLoader />
        ) : (
          <TableMain
            showPagingation={showFilter}
            defaultData={history?.data.records || []}
            columns={columnsTransfer}
            totalPages={history?.data.totalPages || 0}
            pageSize={history?.data.pageSize || 0}
            currentPage={history?.data.currentPage || 0}
            setPage={setPage}
            setShowTransDetails={setShowTransDetails}
          />
        )}
        {!gettingHistory &&
          !isFetching &&
          (history?.data?.records.length ?? 0) === 0 && (
            <EmptyCard description="Make a transfer transaction to view your history" />
          )}
      </div>
    </div>
  );
};

export default Transfer;
