import { useRef, useState } from "react";
import { ReactComponent as AddIcon } from "../../assets/icons/wallet/add.svg";
import { EmptyCard, Filter, TableLoader } from "../reusables";
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import { CreateHubForm } from "./hubModals";
import { useGetDynamicFormsQuery, useGetTemplateFormsQuery } from "src/features/api/dynamicFormSlice";
import FormCard from "./FormCard";
import { useNavigate } from "react-router-dom";
import { TemplateForms } from "./ViewTemplate";
import { FormTemplates } from "src/features/api/dynamicFormSliceTypes";

const HubForm = () => {
  const [createHubForm, setCreateHubForm] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [startDateTimeStamp, setStartDateTimestamp] = useState<string>("");
  const [endDatetimeStamp, setEndDateTimeStamp] = useState<string>("");

  const [dateCreated, setDateCreated] = useState<string>("All");
  const [option, toggleOption] = useState<string>("");

  const [deviceToSearch, setDeviceSearch] = useState<string>("");
  const modalRef = useRef<HTMLElement>(null);

  const navigate = useNavigate();

  const { data: dynamicForms, isLoading, isFetching } = useGetDynamicFormsQuery({
    status: "Inactive",
    StartDate: startDateTimeStamp,
    EndDate: endDatetimeStamp,
    FormType: "Questionnaire",
    Page: 1,
    PageSize: 30,
    Search: deviceToSearch
  })

  const {data: templatedForms, isLoading: gettingTemplateForms} = useGetTemplateFormsQuery({search: ''})


  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    setStartDateTimestamp((selectedDate.getTime()).toString());
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    setEndDateTimeStamp((selectedDate.getTime()).toString());
  };

  return (
    <section className=" bg-clrBg w-[96%] mx-auto mt-3 mb-6 dark:bg-clrDarkBg2 overflow-y-auto">
      <div className="bg-white rounded-lg py-6 md:py-[2.125rem] px-4 md:pl-11 md:pr-5">
        <div className="flex flex-wrap items-center justify-between mb-5 md:mb-10">
          <div className=''>
            <h2 className='mb-1 text-base font-semibold text-black'>Create Hub Form</h2>
            <p className='text-[0.6875rem] font-normal text-[#6B6B6B]'>Customize your hub internet login form</p>
          </div>
          <div className="flex flex-col gap-4 mt-6 mb-6 md:mb-0 md:mt-3 md:flex-row md:items-center">
            <button
              onClick={()=> navigate(`/home/hub-form/form-template`)}
              className="text-xs text-primary font-semibold text-left cursor-pointer transition duration-500 ease-in-out transform hover:text-[#ffcd05d5] hover:scale-105 "> 
              View all templates
            </button>
            <button
              className="flex cursor-pointer justify-between items-center w-fit h-9 px-4 gap-3 bg-primary rounded-[28px] transition duration-500 ease-in-out transform hover:bg-[#ffcd05d5] hover:scale-[0.95]"
              onClick={() => setCreateHubForm(true)}>
              <AddIcon />
              <p className="text-xs font-semibold text-white">Create Hub Form</p>
            </button>
          </div>
        </div>
        {!gettingTemplateForms && <TemplateForms templatedForms={templatedForms?.data as FormTemplates[]} />}
      </div>

      <div className='relative bg-white rounded-lg py-6 md:py-[2.125rem] px-4 md:pl-11 md:pr-5 mt-4 min-h-[calc(100vh-270px)]'>
        <p className="mb-6 text-base font-semibold text-black">Forms</p>
        <section>
          {(dynamicForms?.data?.records?.length || 0) > 0 ? <div className="flex flex-wrap items-end gap-3 mb-8 md:flex-nowrap">
            <div className="flex flex-wrap w-full gap-3 md:w-auto">
              <MobileFilterSearch>
                <div className="relative">
                  <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                    Start Date
                  </h4>
                  <input
                    type="date"
                    className="w-full md:w-52 text-x10 text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                    value={startDate}
                    onChange={(e) => handleStartDateChange(e)}
                  />
                </div>
                <div className="relative">
                  <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                    End Date
                  </h4>
                  <input
                    type="date"
                    className="w-full md:w-52 text-x10 border-[1px] text-clrPlaceholder border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                    value={endDate}
                    onChange={(e) => handleEndDateChange(e)}
                  />
                </div>
                <Filter
                  modalRef={modalRef}
                  options={[
                    "All",
                    "Last Month",
                    "Last 3 Months",
                    "Last Year",
                    "Custom"
                  ]}
                  toggleFilter={toggleOption}
                  selectedFilterOption={option}
                  currentFilterOption={"DATE CREATED"}
                  selectFilterOption={(range: string)=>{setDateCreated(range)}}
                  selectedOption={dateCreated}
                  label={"Date Created"}
                />
              </MobileFilterSearch>
              <div className="flex-1 md:flex-auto ">
                <label
                  htmlFor=""
                  className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
                >
                  Search
                </label>
                <input
                  type="text"
                  name="Hub Name"
                  onChange={(e) => setDeviceSearch(e.target.value)}
                  value={deviceToSearch}
                  placeholder="Hub Name"
                  className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
                />
              </div>
            </div>
          </div> 
          : null}
          {!isLoading && !isFetching && (dynamicForms?.data?.records?.length || 0) > 0 &&
            <div className="flex flex-wrap gap-7">
              {dynamicForms?.data?.records?.map((form, index) => {
                return (
                  <FormCard
                    key={index}
                    formId={form.id}
                    status={form.status}
                    title={form.name}
                    dateCreated={form.createdAtTimeStamp}
                  />
                )
              })}
            </div>}
        </section>
        {isLoading && <TableLoader />}
        {!isLoading && !isFetching && (dynamicForms?.data?.records?.length || 0) === 0 &&
          <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <EmptyCard description="No hub form found" />
          </div>
        }
      </div>
      {createHubForm &&
        <CreateHubForm
          handleModal={() => setCreateHubForm(false)}
        />
      }
    </section>
  )
}

export default HubForm