import { useEffect } from 'react';
import { useTwofaSetupMutation } from '../../../features/api/authSlice';
import { Card, CardTitle, ButtonAuth } from '../../reusables';
import AuthFrame from '../../reusables/AuthFrame';
import { ReactComponent as CopyIcon } from '../../../assets/icons/copy.svg';
import { copyToClipboard, sliceText } from '../../../helper';

interface TwoFaProps {
  email: string;
  handleBack: () => void;
  handleNext: (title?: string, value?: string) => void;
}

//Component to verify that user has received authentication otp
const TwoFa = ({ email, handleBack, handleNext }: TwoFaProps) => {
  const [setup2FA, { data: twoFAdata, isLoading }] = useTwofaSetupMutation();

  useEffect(() => {
    setup2FA();
  }, []);

  return (
    <div className="flex">
      <Card>
        <div className="flex items-center flex-col mt-2 w-[300px] md:w-auto md:px-[68px] mx-auto mb-12 md:mb-8 h-full">
          <div className="text-center md:text-left">
            <CardTitle title="Two-Factor Authentication" />
            <div className="mb-12">
              <p className="text-clrText font-semibold text-[0.625rem] md:text-sm mb-2">
                Welcome: <span className="text-primary">{email}</span>
              </p>
              <p className="text-clrText font-semibold text-[0.625rem] md:text-sm">
                Link an authenticator to your account for more security
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center w-32 h-32 p-1 QRcode-bg">
            <div className="relative z-40">
              {twoFAdata?.data?.qrCodeUri && (
                <img src={twoFAdata?.data?.qrCodeUri} alt="qrCode" width={150} height={150} />
              )}
            </div>
          </div>
          <div className="my-4">
            <p className="text-clrGray">
              Download{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share"
              >
                <span className="text-primary pointer">
                  {' '}
                  Google Authenticator
                </span>
              </a>
              , add an account, copy and use the key, or scan the QR
              code to set up.
            </p>
          </div>
          <div className="flex items-center justify-between p-4 mb-4 cursor-pointer bg-clrBg">
            <p className="text-base">
              {sliceText(twoFAdata?.data?.secret || '', 20)}
            </p>
            <CopyIcon
              className="h-5"
              onClick={() => copyToClipboard(twoFAdata?.data?.secret || '')}
            />
          </div>
          <div className="mt-6 mb-8 w-[80%]">
            <ButtonAuth
              type="submit"
              loading={isLoading}
              handleClick={() => handleNext()}
              text="Set Code "
            />
          </div>

          <div className="mt-4 mb-10 cursor-pointer">
            <div onClick={handleBack}> ← Back </div>
          </div>
        </div>
      </Card>
      <AuthFrame />
    </div>
  );
};

export default TwoFa;
