import { BounceLoader, PulseLoader } from "react-spinners";
import LoaderGif from "../../assets/loader.gif";

interface LoaderProps {
  color?: string;
  loading: boolean;
  size?: number;
}

export const ButtonLoader = ({
  color = "#fefefe",
  loading,
  size = 5,
}: LoaderProps) => {
  return (
    <PulseLoader
      color={color}
      size={size}
      loading={loading}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export const BackgroundLoader = ({
  color = "#344684",
  loading,
  size,
}: LoaderProps) => {
  return (
    <BounceLoader
      color={color}
      loading={loading}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export const TableLoader = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="m-auto">
        <img src={LoaderGif} alt="GIF" />
      </div>
    </div>
  )
};
