import SecondaryNav from '../reusables/SecondaryNav';

const Profile = () => {
  return (
    <SecondaryNav
      paths={[
        { path: '/home/profile/info', title: 'My Profile' },
        { path: '/home/profile/security', title: 'Security' },
      ]}
    />
  );
};

export default Profile;
