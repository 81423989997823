import { ButtonOutlineWallet } from '../../reusables';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { handleTransactionType } from '../../../features/store/walletReducerSlice';

const TransactionOption = () => {
  const dispatch = useAppDispatch();
  const { transactionTab } = useAppSelector((state) => state.wallet);

  const handleTab = (tab: number) => {
    dispatch(handleTransactionType(tab));
  };

  return (
    <div className="flex flex-wrap gap-3 mb-6 md:mb-10">
      <ButtonOutlineWallet
        type="button"
        text="All"
        handleClick={() => handleTab(1)}
        active={transactionTab === 1}
      />
      <ButtonOutlineWallet
        type="button"
        text="Funding"
        handleClick={() => handleTab(2)}
        active={transactionTab === 2}
      />
      <ButtonOutlineWallet
        type="button"
        text="Withdrawal"
        handleClick={() => handleTab(3)}
        active={transactionTab === 3}
      />
      <ButtonOutlineWallet
        type="button"
        text="Ads Share"
        handleClick={() => handleTab(4)}
        active={transactionTab === 4}
      />
    </div>
  );
};

export default TransactionOption;
