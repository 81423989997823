import React, { useState, useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import TableMain from "../reusables/ReactDataTable";
import { EmptyCard, Filter, TableLoader } from "../reusables";
// import Whitepaper from "../../assets/icons/device/whitepaper.svg";
import { columnsVoucher } from "./data";
import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import { useGetVouchersQuery } from "../../features/api/voucherSlice";
import useOutsideClick from "../../hooks/useOutsideClick";
import { calculateDateRange } from "../../helper";


const VoucherHistory: React.FC = () => {
  const modalRef = useRef<HTMLElement>(null);
  const { businessId } = useAppSelector((state) => state.auth);
  const [option, toggleOption] = useState<string>("");
  const [status, setStatus] = useState<string>("All");
  const [duration, setDuration] = useState<string>("All");
  const [searchValue, setSearch] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const [startDateTimeStamp, setStartDateTimestamp] = useState<string>("");
  const [endDatetimeStamp, setEndDateTimeStamp] = useState<string>("");

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);

  const { data, isLoading, isFetching } = useGetVouchersQuery({
    voucherStatus: status,
    page,
    pageSize: 30,
    businessId: businessId,
    startDateTimestamp: startDateTimeStamp,
    endDateTimeStamp: endDatetimeStamp,
    voucherCode: searchValue,
  });

  const handleDateRangeChange = (range: string) => {
    setDuration(range)
    const [newStartDate, newEndDate] = calculateDateRange(range);

    setStartDateTimestamp(newStartDate?.toString() || "");
    setEndDateTimeStamp(newEndDate?.toString() || "");
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    setStartDateTimestamp((selectedDate.getTime()).toString()); 
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value)
    const selectedDate = new Date(event.target.value);
    setEndDateTimeStamp((selectedDate.getTime()).toString()); 
  };

  return (
    <div>
      <div className="flex flex-wrap w-full gap-x-3 gap-y-3 md:px-4 md:w-auto">
        <MobileFilterSearch>
          <Filter
            modalRef={modalRef}
            options={["All", "Inactive", "Active", "Exhausted"]}
            toggleFilter={toggleOption}
            selectedFilterOption={option}
            currentFilterOption={"STATUS"}
            selectFilterOption={setStatus}
            selectedOption={status}
            label={"Status"}
          />
          <Filter
            modalRef={modalRef}
            options={[
              "All",
              "Last Week",
              "Last Month",
              "Last 3 Months",
              "Last Year",
              "Custom",
            ]}
            toggleFilter={toggleOption}
            selectedFilterOption={option}
            currentFilterOption={"DURATION"}
            selectFilterOption={handleDateRangeChange}
            selectedOption={duration}
            label={"Duration"}
          />
          {duration === "Custom" &&
            <>
              <div className="relative">
                <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                  Start Date
                </h4>
                <input
                  type="date"
                  className="w-full md:w-52 text-x10 text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                  value={startDate}
                  onChange={(e) => handleStartDateChange(e)}
                />
              </div>
              <div className="relative">
                <h4 className="mb-2 md:mb-2.5 text-[10px] md:text-xs font-semibold text-clrTextLabel">
                  End Date
                </h4>
                <input
                  type="date"
                  className="w-full md:w-52 text-x10 border-[1px] text-clrPlaceholder border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
                  value={endDate}
                  onChange={(e) => handleEndDateChange(e)}
                />
              </div>
            </>
          }
        </MobileFilterSearch>
        <div className="flex-1 md:flex-auto ">
          <label
            htmlFor=""
            className="hidden md:block mb-2.5 text-xs font-semibold text-clrTextLabel dark:text-clrPlaceholder"
          >
            Search
          </label>
          <input
            type="text"
            name=""
            id=""
            onChange={(e)=> setSearch(e.target.value)}
            placeholder="Voucher code/Email"
            className="w-full md:w-fit text-xs font-medium text-clrGray dark:text-clrPlaceholder border-[1px] border-solid border-clrBorder dark:border-clrGray rounded-3xl h-8 pb-1 px-4 placeholder:!text-clrPlaceholder placeholder:text-[10px] bg-inherit dark:bg-transparent"
            />
        </div>
        {/* <ButtonYellow text="Export Report" btnIcon={Whitepaper} to={""} /> */}
      </div>
      {isLoading || isFetching ? (
        <TableLoader />
      ) : (
        <TableMain
          defaultData={data?.data.records || []}
          columns={columnsVoucher}
          totalPages={data?.data.totalPages || 0}
          pageSize={data?.data.pageSize || 0}
          currentPage = {data?.data.currentPage || 0}
          setPage={setPage}
          
        />
      )}
      {!isLoading && !isFetching && data?.data.records.length === 0 && (
        <EmptyCard description="No voucher to display!" />
      )}
    </div>
  );
};

export default VoucherHistory;
