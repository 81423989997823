export const stakeDuration = [
  { id: 1, value: 2, text: '3 Months' },
  { id: 2, value: 3, text: '6 Months' },
  { id: 3, value: 4, text: '9 Months' },
  { id: 4, value: 5, text: '12 Months' },
];

export const fundingMethods = [
  {
    title: 'MoMo Bank Transfer',
    desc: 'Transfer to the bank details provided to fund',
    link: '/home/wallet/bank-transfer',
  },
  {
    title: 'MoMo USSD',
    desc: 'Fund using a given USSD Code',
    link: '/home/wallet/ussd',
  },
];

export const withdrawalMethods = [
  {
    title: 'MoMo Bank Transfer',
    desc: 'Withdraw available funds to a bank account',
    link: '/home/wallet/withdrawal-bank-transfer',
  },
  {
    title: 'Transfer to another user',
    desc: 'Send funds to a MTN user',
    link: '/home/wallet/user',
  },
];
