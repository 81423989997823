import { ReactComponent as Dashboard } from '../../assets/icons/navbar/home.svg';
import { ReactComponent as Analytics } from '../../assets/icons/navbar/dashboard.svg';
// import { ReactComponent as Wallet } from '../../assets/icons/navbar/wallet.svg';
import { ReactComponent as HotspotHubs } from '../../assets/icons/navbar/hotspot-hubs.svg';
// import { ReactComponent as Agents } from '../../assets/icons/navbar/agents.svg';
import { ReactComponent as Business } from '../../assets/icons/navbar/business.svg';
import { ReactComponent as Profile } from '../../assets/icons/profile-icon.svg';
import { ReactComponent as Token } from '../../assets/icons/navbar/token.svg';
import { ReactComponent as LogOut } from '../../assets/icons/logout.svg';
import { ReactComponent as ParentalControl } from '../../assets/icons/navbar/parentalControl.svg';
import { ReactComponent as AdvertControl } from '../../assets/icons/navbar/advertControl.svg';
import { ReactComponent as Subscription } from '../../assets/icons/navbar/subscription.svg';
import { ReactComponent as HubForm } from '../../assets/icons/navbar/hubForm.svg';

export const NavBarData = [
  {
    id: 1,
    icon: <Dashboard />,
    title: 'Home',
    desc: 'Track your apps across devices',
    to: '/home/dashboard',
    path: 'dashboard',
  },
  {
    id: 13,
    icon: <Analytics />,
    title: 'Analytics',
    desc: 'Hub insights at a glance',
    to: '/home/analytics',
    path: 'analytics',
  },
  // {
  //   id: 2,
  //   icon: <Wallet />,
  //   title: 'Balance',
  //   desc: 'Track your wallet operations',
  //   to: '/home/wallet',
  //   path: 'wallet',
  // },
  {
    id: 3,
    icon: <HotspotHubs />,
    title: 'Hotspot Hubs',
    desc: 'Explore the world of decentralized Internet Sharing',
    to: '/home/hotspot-hubs',
    path: 'hotspot-hubs',
  },
  {
    id: 4,
    icon: <Token />,
    title: 'Token',
    desc: 'View your Token statistics ',
    to: '/home/token',
    path: 'token',
  },
  {
    id: 6,
    icon: <HubForm />,
    title: 'Hub Form',
    desc: 'Track your apps across devices',
    to: '/home/hub-form',
    path: 'hub-form',
  },
  {
    id: 10,
    icon: <ParentalControl />,
    title: 'Parental Control',
    desc: 'Restrict and mange hub access',
    to: '/home/parental-control',
    path: 'parental-control',
  },
  {
    id: 5,
    icon: <Subscription />,
    title: 'Subscription',
    desc: 'View and pay for a perfect plan',
    to: '/home/subscription',
    path: 'subscription',
  },
  {
    id: 9,
    icon: <Profile />,
    title: 'Profile',
    desc: 'View your profile information',
    to: '/home/profile/info',
    path: 'profile',
  },
  // {
  //   id: 7,
  //   icon: <Agents />,
  //   title: 'My Agents',
  //   desc: 'View your Agents’ activities across your app',
  //   to: '/home/agents',
  //   path: 'agents',
  // },
  {
    id: 8,
    icon: <Business />,
    title: 'My Cluster',
    desc: 'Assign hubs to a group',
    to: '/home/cluster',
    path: 'cluster',
  },
  {
    id: 11,
    icon: <AdvertControl />,
    title: 'Advert Control',
    desc: 'View details of running ads',
    to: '/home/advert-control/overview',
    path: 'advert-control',
  },
  {
    id: 12,
    icon: <LogOut />,
    title: 'Logout',
    desc: 'Are you sure you want to log out?',
    to: '/home/logOut',
  },
];
