import { apiSlice } from "./apiSlice";
import 
{ 
    DynamicFormDetailResponse, 
    DynamicFormListRequest, 
    DynamicFormListResponse, 
    DynamicFormRequest, 
    DynamicFormResponses,
    FormTemplatesRequest,
    FormTemplatesResponse,
    ResponseDetail
} from "./dynamicFormSliceTypes";
import { SuccessResponse } from "./homeSliceTypes";

const dynamicFormSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Create Form 
        createForm: build.mutation<SuccessResponse, FormData>({
            query: (payload) => ({
                url: `/v4/dynamic-forms`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ["HubForms"]
        }),

        //Get Dynamic Forms 
        getDynamicForms: build.query<DynamicFormListResponse, DynamicFormListRequest>({
            query: ({StartDate, EndDate, FormType, Page, PageSize, Search}) => `/v4/dynamic-forms?StartDate=${StartDate}&EndDate=${EndDate}&Page=${Page}&PageSize=${PageSize}&FormType=${FormType}&Search=${Search}`,
            providesTags: ["HubForms"]
        }),

         //Get Dynamic Form Detail
         getFormDetail: build.query<DynamicFormDetailResponse, string>({
            query: (formId) => `/v4/dynamic-forms/${formId}`,
        }),
       
         //Get Form Responses
         getFormResponses: build.query<DynamicFormResponses, DynamicFormRequest>({
            query: ({formId, StartDate, EndDate, Page, PageSize, Search}) => `/v4/dynamic-forms/${formId}/responses?StartDate=${StartDate}&EndDate=${EndDate}&Page=${Page}&PageSize=${PageSize}&Search=${Search}`,
            providesTags: ["HubForms"]
        }),

        //Get Response Detail
        getResponseDetail: build.query<ResponseDetail, string>({
            query: (responseId) => `/v4/dynamic-forms/responses/${responseId}`,
            providesTags: ["HubForms"]
        }),
       
         //Delete Form 
        deleteForm: build.mutation<SuccessResponse, string>({
            query: (formId) => ({
                url: `/v4/dynamic-forms/${formId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["HubForms"]
        }),

        //Deactivate Form 
        deactivateForm: build.mutation<SuccessResponse, string>({
            query: (formId) => ({
                url: `/v4/dynamic-forms/${formId}/deactivate`,
                method: 'PUT',
            }),
            invalidatesTags: ["HubForms"]
        }),

         //Get Template Forms
         getTemplateForms: build.query<FormTemplatesResponse, FormTemplatesRequest>({
            query: ({search}) => `/v4/dynamic-forms/templates?Search=${search}`,
        }),
        
    }),
    overrideExisting: true,
})

export const { 
    useCreateFormMutation, 
    useGetDynamicFormsQuery, 
    useGetFormDetailQuery, 
    useGetFormResponsesQuery,
    useGetResponseDetailQuery,
    useDeleteFormMutation,
    useGetTemplateFormsQuery,
    useDeactivateFormMutation
} = dynamicFormSlice