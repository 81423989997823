import { useState, DragEvent, useEffect } from 'react';
import { ReactComponent as UploadIcon } from "../../assets/hub/upload.svg";
// import { ReactComponent as PDFIcon } from "../../assets/hub/pdf.svg";
import { ReactComponent as SuccessIcon } from "../../assets/hub/check-circle.svg";
// import { ReactComponent as CloseIcon } from "../../assets/icons/hotspot-hubs/close.svg";
import { ReactComponent as PreviewIcon } from "../../assets/hub/preview.svg";
// import { ReactComponent as RotateIcon } from "../../assets/hub/rotate-right.svg";
// import { ReactComponent as InfoIcon } from "../../assets/hub/info-circle.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { Button, ErrorMsg } from "../reusables";
import { filesize } from 'filesize';
import { sliceText } from 'src/helper';
import moment from 'moment';
import { ImagePreview } from './ImagePreview';

type IImageSliders = {
  handleNavigate: (val: number) => void,
  handleSubmit: (val: File[]) => void,
  handleImageUrls: (val: string[]) => void;
  imageUrls: string[];
  imageSliders: File[]
}


const ImageSliders = ({handleNavigate, handleSubmit, handleImageUrls, imageSliders, imageUrls}: IImageSliders) => {
  const [showImagePreview, setShowImagePreview] = useState<boolean>(false);
  const [sliderImages, addImageSlider] = useState<File[]>([])
  const [imageUrl, addImageUrl] = useState<string[]>([])
  const [imageToPreview, addImageToPreview] = useState<string>("")
  const [errMsg, setErrMsg] = useState<string>("")

  const handleCloseModal = () => {
    setShowImagePreview(false)
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrMsg("");
    const selectedFile = e.target.files?.[0];
  
    if (selectedFile) {
      const fileSizeInKB = selectedFile.size / 1024; // Convert bytes to KB
  
      if (fileSizeInKB > 500) {
        setErrMsg('Please select an image smaller than 500KB.');
        return; // Stop further execution
      }
  
      let blobUrl = URL.createObjectURL(selectedFile);
      addImageSlider([...sliderImages, selectedFile]);
      addImageUrl([...imageUrl, blobUrl]);
    }
  };

  useEffect(()=>{
    addImageSlider(imageSliders);
    addImageUrl(imageUrls);
  }, [])
  

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    let blobUrl = URL.createObjectURL(file as File);
    addImageSlider([...sliderImages as File[], file as File]);
    addImageUrl([...imageUrl, blobUrl])
  };

  const handleDelete = (pos: number) => {
    // Check if the position is valid
    if (pos >= 0 && pos < sliderImages.length) {

        const newSliderImages = [...sliderImages];
        newSliderImages.splice(pos, 1);
        addImageSlider(newSliderImages);

        const newImageUrl = [...imageUrl];
        newImageUrl.splice(pos, 1);
        addImageUrl(newImageUrl);
    }
  };

  const handleSave = () =>{
    handleNavigate(3)
    handleSubmit(sliderImages);
    handleImageUrls(imageUrl)
  }


  return (
    <section className="py-3 bg-clrBg w-[96%] mx-auto pb-6 mt-3 dark:bg-clrDarkBg2 overflow-y-auto">
      <aside className="relative flex flex-col bg-white rounded-lg pt-[2.125rem] px-11 mt-4 pb-14">
        <div>
          <h3 className="text-base font-semibold text-clrGray">Upload Image</h3>
          {imageUrl.length < 4 &&
          <div
            onDragOver={(e) => handleDragOver(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
            onDrop={(e) => handleDrop(e)}
          >
            <label htmlFor="file" className="cursor-pointer mt-16 flex flex-col justify-center items-center gap-3 border border-dashed border-[#D0D5DD] px-4 py-6 rounded-md md:w-80 mx-auto">
              <UploadIcon />
              <input
                type="file"
                name="file"
                id="file"
                onChange={handleChange}
                accept=".png, .jpg, .jpeg"
                className="absolute overflow-hidden opacity-0 z-index--1"
              />
              <div className="text-center">
                <p className="text-[0.625rem] font-semibold text-primary">Click to upload<span className="text-clrGray"> or drag and drop</span></p>
                <p className="text-[0.625rem] font-normal text-[#98A2B3]">PNG, JPG or GIF (max. 500KB)</p>
              </div>
            </label>
          </div>}

          {imageUrl.length === 4 && 
          <button onClick={()=> handleDelete(imageUrl.length - 1)} className="mt-16 flex flex-col justify-center items-center gap-3 border border-dashed border-[#D0D5DD] px-4 py-6 rounded-md md:w-80 mx-auto">
            <DeleteIcon className="w-10 h-10" />
            <div className="text-center">
              <p className="text-[0.625rem] font-semibold text-clrRed3">Delete a Photo</p>
              <p className="text-[0.625rem] font-normal text-[#98A2B3]">Max image limit, clear an image to upload more</p>
            </div>
          </button>}
        </div>

        {imageUrl.length > 0 &&
          <section className="grid grid-cols-1 gap-4 mt-16 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-4">
            {/* <div className="flex items-center gap-4 border border-dashed border-[#D0D5DD] px-3 py-4 rounded-[10px]">
              <PDFIcon className="w-5 h-5" />
              <div className="flex flex-col justify-between flex-1 gap-1">
                <p className="text-sm font-medium text-black">Uploading Document</p>
                <div className="progress-loader">
                  <div className="progress"></div>
                </div>
                <p className="text-[0.5rem] font-normal text-[#475367]"><span className="text-clrPlaceholder tracking-[-0.5%]">File Title.PNG</span> | 45% Completed</p>
              </div>
              <CloseIcon className="w-3 h-3 dark-close" />
            </div> */}
            {sliderImages.map((image, index) => (
                <div className="flex items-center gap-3 border border-dashed border-[#D0D5DD] px-3 py-4 rounded-[10px]">
                  <div className="flex items-center justify-center w-8 h-8 rounded-full bg-[#E7F6EC]">
                    <SuccessIcon />
                  </div>
                  <div>
                    <p className="text-[0.625rem] font-medium text-[#1D2739]">Document</p>
                    <p className="text-[0.5rem] font-normal text-clrPlaceholder"><span className="text-[#475367] tracking-[-0.5%]">
                      {sliceText(image?.name, 20)} </span> | {filesize(image?.size)}. {moment(image?.lastModified).format('DD-MM-YYYY')} </p>
                  </div>
                  <button onClick={() => {addImageToPreview(imageUrl[index] as string); setShowImagePreview(true)}} className="flex gap-2 ml-auto">
                    <PreviewIcon className="w-4 h-4" />
                    <p className="text-[0.625rem] font-normal text-primary">Preview</p>
                  </button>
                  <button className="flex items-center gap-2" onClick={()=> handleDelete(index)}>
                    <DeleteIcon className="w-3 h-3" />
                    <p className="text-[0.625rem] font-normal text-[#98A2B3]">Clear</p>
                  </button>
                </div>
              ))}
            
            
            {/* <div className="flex items-center gap-3 border border-dashed border-[#D0D5DD] px-3 py-4 rounded-[10px]">
              <div className="flex items-center justify-center w-8 h-8 rounded-full bg-[#FEF6E7]">
                <InfoIcon />
              </div>
              <div>
                <p className="text-sm font-medium text-black">File Title.pdf</p>
                <p className="text-[0.625rem] font-normal text-[#D42620]">The file is too large to upload. </p>
              </div>
              <button className="flex items-center gap-2 ml-auto">
                <RotateIcon className="w-4 h-4" />
                <p className="text-[0.625rem] font-normal text-primary">Reupload</p>
              </button>
            </div> */}
          </section>}
          <ErrorMsg errMsg={errMsg} />
       {imageUrl.length >= 1 && <div className="flex flex-col justify-end gap-3 mt-auto ml-auto md:w-fit">
          <Button
            type="button"
            text={"Save"}
            handleClick={handleSave}
            fontWeight="font-normal"
            wBtn="md:w-32"
          />
        </div>}
      </aside>
      {showImagePreview && <ImagePreview image={imageToPreview} handleCloseModal={handleCloseModal} />}
    </section>
  )
}

export default ImageSliders
