import { ReactNode } from "react";

import Logo from "../../assets/auth/logo.svg";
import SuccessIcon from "../../assets/icons/wallet/success.svg";
import { Button } from ".";
import { SuccessProps } from "./types";

interface CardProps {
  children: ReactNode;
}

export const Card = ({ children }: CardProps) => {
  return (
    <section className="relative basis-full lg:basis-[44.3%]">
      <div className="relative min-h-screen md:flex justify-start items-center flex-col mx-auto md:w-[539px]">
        <div className="relative z-50 w-full h-full p-4 mx-auto overflow-hidden bg-white rounded-xl md:p-9 md:mt-5 flex justify-center flex-col">
          <div className="mb-8 md:mb-12">
            <img
              src={Logo}
              alt="Logo"
              className="w-[85px] md:w-[105.5px] md:h-[62.8px]"
            />
          </div>
          <div className="border border-solid border-clrYellow2/10 bg-clrYellow2/[.02] rounded-2xl pt-5 px-[14px] md:px-0">{children}</div>
        </div>
      </div>
      <div className="absolute bottom-2 lg:left-8 text-[#C4C4C4] z-[51] px-6 lg:px-0">
        <p className="mb-2 text-xs">
          Copyright &copy; 2018-{new Date().getFullYear()} Wicrypt Limited.
          All rights reserved.
        </p>
        <div className="block text-xs lg:hidden">
          <ul className="flex flex-wrap lg:justify-between gap-x-3">
            <li>
              <a href="https://wicrypt.com/help/"> Help Center</a>
            </li>
            <li>
              <a href="https://wicrypt.com/terms-condition.html">
                Terms & Conditions
              </a>
            </li>
            <li>
              <a href="https://wicrypt.com/privacy-policy.html">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

//Success Card
export const Success = ({
  modalRef,
  handleButton,
  buttonTitle,
  title,
  description,
}: SuccessProps) => {
  return (
    <section
      className="fixed inset-0 z-20 h-screen overflow-hidden bg-[#F4F4F4] md:bg-[#00000033] dark:md:bg-[#00000080] dark:bg-clrDarkBg2 flex justify-center md:items-center"
      ref={modalRef}
    >
      <div className="bg-clrBg2 dark:bg-clrDarkBg2 py-6 z-30 rounded-xl w-11/12 md:px-4 md:w-[450px]">
        <div className="flex flex-col items-center justify-center gap-4 px-3 py-8 mb-8 bg-white rounded dark:bg-clrDarkBg">
          <img
            src={SuccessIcon}
            alt="Success"
            width={100}
            height={100}
            className="w-20 h-20 md:w-24 md:h-24"
          />
          <div className="mb-4 text-center">
            <h2 className="mb-3 text-sm font-semibold text-black md:text-base dark:text-white">
              {title}
            </h2>
            <p className="text-x9 md:text-x10 font-medium text-clrGray66 w-[90%] md:w-[60%] mx-auto dark:text-clrPlaceholder">
              {description}{" "}
            </p>
          </div>
        </div>

        <div className="md:w-[80%] mt-6 mx-auto ">
          <Button
            type="button"
            text={buttonTitle || ""}
            handleClick={handleButton}
          />
        </div>
      </div>
    </section>
  );
};
