import { apiSlice } from "./apiSlice";
import { SuccessResponse } from "./homeSliceTypes";
import { AllBlacklistedCategoriesResponse, BlacklistedCategoriesRequest, BlacklistedCategoriesResponse, BlacklistedAddressesRequest, ControlDeviceDetail,
     DeleteAddressRequest, ParentalControlRequest, 
     ParentalControlResponse, RestrictionTimeRequest, 
     ToggleParentalControlRequest, UpdateAddressRequest, 
     } from "./parentalControlSliceType";


const parentalControlSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get Parental Conrtol Devices 
        getParentalControlDevices: build.query<ParentalControlResponse, ParentalControlRequest>({
            query: ({ isEnabled, Search, Page, PageSize }) => `/v4/parental-controls/devices?isEnabled=${isEnabled}&Search=${Search}&Page=${Page}&PageSize=${PageSize}`,
            providesTags: ['ParentalControl']
        }),

        //Get Parental Conrtol for a device 
        getParentalControlDevice: build.query<ControlDeviceDetail, string>({
            query: (deviceId) => `/v4/parental-controls/devices/${deviceId}`,
            providesTags: ['ParentalControl']
        }),

        //Get Blacklisted addresses for a device
        getBlacklistedAddresses: build.query<ParentalControlResponse, BlacklistedAddressesRequest>({
            query: ({deviceId, Search, Page, PageSize, AddressTypeFilter, CategoryId}) => `/v4/parental-controls/devices/${deviceId}/blacklisted-addresses?Search=${Search}&Page=${Page}&PageSize=${PageSize}&AddressTypeFilter=${AddressTypeFilter}&CategoryId=${CategoryId}`,
            providesTags: ['ParentalControl']
        }),

        //Add to blacklisted address
        updateBlacklistedAddress: build.mutation<SuccessResponse, UpdateAddressRequest>({
            query: ({deviceId, payload}) => ({
                url: `/v4/parental-controls/devices/${deviceId}/blacklisted-address`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ["ParentalControl"]
        }),

         //Remove address from the blacklisted list
        removeBlacklistedAddress: build.mutation<SuccessResponse, DeleteAddressRequest>({
            query: ({deviceId, blacklistAddressId}) => ({
                url: `/v4/parental-controls/devices/${deviceId}/blacklisted-address/${blacklistAddressId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["ParentalControl"]
        }),

         //Toggle the parental control feature
        toggleParentalControl: build.mutation<SuccessResponse, ToggleParentalControlRequest>({
            query: ({deviceId, payload}) => ({
                url: `/v4/parental-controls/devices/${deviceId}`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ["ParentalControl"]
        }),

         //Set the restriction time duration for each device
         setRestrictionTime: build.mutation<SuccessResponse, RestrictionTimeRequest>({
            query: ({deviceId, payload}) => ({
                url: `/v4/parental-controls/devices/${deviceId}`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ["ParentalControl"]
        }),

        //Get the categories of blacklisted addresses 
        getBlacklistedCategories: build.query<BlacklistedCategoriesResponse, BlacklistedCategoriesRequest>({
            query: ({deviceId, Search}) => `/v4/parental-controls/devices/${deviceId}/address-categories?Search=${Search}`,
            providesTags: ['ParentalControl']
        }),

        //Get all blacklisted address categories
        getAllBlacklistedCategories: build.query<AllBlacklistedCategoriesResponse, string>({
            query: (Search) => `/v4/parental-controls/address-categories?Search=${Search}`,
            providesTags: ['ParentalControl']
        }),

    }),
    overrideExisting: true,
})

export const { 
    useGetParentalControlDevicesQuery,
    useGetParentalControlDeviceQuery, 
    useToggleParentalControlMutation,
    useGetBlacklistedAddressesQuery,
    useUpdateBlacklistedAddressMutation,
    useSetRestrictionTimeMutation,
    useRemoveBlacklistedAddressMutation,
    useGetBlacklistedCategoriesQuery,
    useGetAllBlacklistedCategoriesQuery,
} = parentalControlSlice