import { useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Options, OptionsDisconnect } from "./OptionsStatus";
import { ReactComponent as VerticalEllipsisIcon } from "../../assets/icons/hotspot-hubs/vertical-ellipsis.svg";

export const CellRenderer = ({ name, id }: { name?: string; id?: string }) => {
  const [isOption, setIsOption] = useState(false);

  const modalRefOptions = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsOption(false);
  };

  useOutsideClick(modalRefOptions, handleCloseModal);

  return (
    <>
      <button
        onClick={() => {
          setIsOption(!isOption);
        }}
        className="px-2 py-1 hover:bg-[#e0dcdc] hover:rounded transition duration-500 ease-in-out"
      >
        <VerticalEllipsisIcon />
      </button>
      {isOption && (
        <Options
          closeFilter={handleCloseModal}
          modalRef={modalRefOptions}
          name={name}
          id={id}
        />
      )}
    </>
  );
};

export const CellRendererSession = () => {
  const [isOptionDisconnect, setIsOptionDisconnect] = useState(false);

  const modalRefOptionsDisconnect = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsOptionDisconnect(false);
  };

  useOutsideClick(modalRefOptionsDisconnect, handleCloseModal);

  return (
    <>
      <button className="px-2 py-1 hover:bg-[#e0dcdc] hover:rounded transition duration-500 ease-in-out">
        <VerticalEllipsisIcon
          onClick={() => {
            setIsOptionDisconnect(!isOptionDisconnect);
          }}
        />
      </button>
      {isOptionDisconnect && (
        <OptionsDisconnect modalRef={modalRefOptionsDisconnect} />
      )}
    </>
  );
};
