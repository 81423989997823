import { createSlice} from '@reduxjs/toolkit'
import { RootState } from '../../app/store';
import { WalletSliceTypes } from '../api/walletSliceTypes'

const initialState : WalletSliceTypes = {
  selectedCurrency: {
    id: 0,
    code: "",
    name: "",
    iconPng: "",
    iconSvg: ""
  },
  outboundCurrency: {
    id: 0,
    code: "",
    name: "",
    iconPng: "",
    iconSvg: ""
  },
  transactionTab: 1

}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    selectCurrency: (state, action) => {
      state.selectedCurrency = action.payload
    },
    setOutBoundCurrency: (state, action) => {
      state.outboundCurrency = action.payload
    },
    handleTransactionType: (state, action) =>{
      state.transactionTab = action.payload
    }
  }
})

export const userWallet = (state: RootState) => state.wallet;

export const { selectCurrency, setOutBoundCurrency, handleTransactionType} = walletSlice.actions

// Export the slice reducer as the default export
export default walletSlice.reducer