import { Button } from "../reusables";
import { IntroducingHubFormProps } from "./types";
import {ReactComponent as IntroIcon } from "../../assets/hub/introPic.svg";
import { ReactComponent as CloseIcon } from '../../assets/icons/hotspot-hubs/close.svg';

export const IntroducingHubForm = ({handleGetStarted, handleCloseModal}: IntroducingHubFormProps) => {
  return (
    <section className="fixed inset-0 z-50 h-screen overflow-hidden dark:bg-#00000080 bg-[#00000033] flex justify-center items-center">
      <div className="bg-white dark:bg-clrDarkBg2 rounded-[1.25rem] px-5 py-10 w-4/5 md:w-[30rem]">
        <div className="flex justify-end px-3">
          <button onClick={handleCloseModal}>
              <CloseIcon className="w-[13px] h-[13px] self-end dark-close" />
          </button>
        </div>
        <div className="flex flex-col items-center justify-center px-3 pb-8 bg-white rounded dark:bg-clrDarkBg max-w-[255px] mx-auto">
          <h2 className="mb-1 text-sm font-bold text-black md:text-base"> What’s New?</h2>
          <IntroIcon className="w-full" />
          <div className="mb-2 text-center">
            <h3 className="mb-2 text-sm font-bold text-black md:text-base">Introducing “Hub Form”</h3>
            <p className="font-medium text-x9 md:text-x10 text-clrGray dark:text-clrPlaceholder">
              Create forms, collect real-time data, displayed on the hub splash screen.
            </p>
          </div>
        </div>

        <div className="md:w-[80%] mx-auto flex flex-col gap-3 ">
          <Button
            type="button"
            text={"Start Tour"}
            handleClick={()=>{handleGetStarted();}}
            fontWeight="font-normal"
          />
        </div>
      </div>
    </section>
  );
};