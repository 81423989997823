import { RefObject } from "react";
import FAQ from "./Faq";
import { ButtonGold } from "../reusables";

interface StatusProps {
  modalRef: RefObject<HTMLElement>;
}

const CustomerSupport = ({ modalRef }: StatusProps) => {
  return (
    <>
      <div className="absolute p-4 bg-white dark:bg-clrDarkBg rounded-md shadow bottom-[2rem] top-auto md:top-[2rem] md:bottom-auto w-60 -left-[1rem] md:left-auto md:right-[0.2rem] z-30">
        <h1 className="mb-4 text-xs font-semibold text-black dark:text-white">
          Customer Support
        </h1>
        <FAQ />
        <div className="flex items-center justify-between p-2">
          <p className="font-normal text-x10 text-clrGray dark:text-clrPlaceholder">
            Email Support
          </p>
          <a
            href="mailto:hello@wicrypt.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ButtonGold
              text="Send Email"
              type="button"
              handleClick={() => {}}
              textSize="text-x10"
              py="py-1"
            />
          </a>
        </div>
      </div>
      <section
        className="fixed inset-0 z-20 h-screen overflow-hidden bg-transparent"
        ref={modalRef}
      ></section>
    </>
  );
};

export default CustomerSupport;
