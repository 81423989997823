import {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useLogInWithGoogleMutation } from "../../../features/api/authSlice";
import {
  GoogleOAuthProvider,
  GoogleLogin,
} from "@react-oauth/google";
import { useAppDispatch } from "../../../app/hooks";
import { setUser } from "../../../features/store/authReducerSlice";

interface GoogleAuthProps {
  title: "signin_with" | "signup_with"; //determines which type of Google button to render
  setError: (err: string)=> void; //Function to handle error on authenticating with Google 
  setAuthenticating: (authenticating: boolean) => void //Function to handle the loading button
}

//Handles the authentication 
const GoogleAuth = ({ title, setError, setAuthenticating }: GoogleAuthProps) => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [googleLogIn, {isLoading}] = useLogInWithGoogleMutation();

  const responseGoogle = (credResponse: any) => {
    if (credResponse) {
      let data = {
        email: "",
        idToken: credResponse.credential,
        macAddress: "00:00",
      };
      googleLogIn(data)
      .unwrap()
      .then((res) => {
        if(res.success){
          dispatch(setUser(res?.data));
          navigate("/home");
        }else{
          setError(res.message);
        }
      })
      .catch((error) => {
        if (error?.data?.message) {
          setError(error.data.message);
        } else {
          setError("An error occurred.");
        }
      });
    }
  };

  useEffect(()=>{
    setAuthenticating(isLoading)
  }, [isLoading])

  return (
    <div>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
      >
        <div className="flex items-center justify-center gap-2 w-full btn-google">
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              responseGoogle(credentialResponse);
            }}
            onError={() => {
              setError("Login Failed");
            }}
            type={"standard"}
            size={"large"}
            text={title}
            shape="circle"
            logo_alignment="left"
            width="100%"
          />
        </div>
      </GoogleOAuthProvider>
    </div>
  );
};
export default GoogleAuth;
